import { array, Infer, object, optional, string } from "superstruct";
import { ProjectQuestionStruct } from "../../types/ProjectQuestion";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateProjectQuestionsRequestStruct = object({
  projectId: string(),
  questions: array(ProjectQuestionStruct),
  /**
   * Apply call flagging config to existing calls from this date on.
   */
  checkExistingCallsFromDate: optional(string()),
});

export type UpdateProjectQuestionsRequest = Infer<
  typeof UpdateProjectQuestionsRequestStruct
>;

export const updateProjectQuestionsDefinition = getFirebaseFunctionDefinition({
  path: "/projects/questions/update",
  reqType: UpdateProjectQuestionsRequestStruct,
});
