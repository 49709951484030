import { Infer, object, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteTenderRequestStruct = object({
  tenderId: string(),
});

export type DeleteTenderRequest = Infer<typeof DeleteTenderRequestStruct>;

const DeleteTenderResponseStruct = type({
  id: string(),
});

export const deleteTenderDefinition = getFirebaseFunctionDefinition({
  path: "/tenders/delete",
  reqType: DeleteTenderRequestStruct,
  resType: DeleteTenderResponseStruct,
});
