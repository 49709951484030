import { boolean, Infer, string, type } from "superstruct";
import { CallTranscriptSourceStruct } from "../../types/CallTranscriptSource";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateCallIsTranscriptHelpfulRequestStruct = type({
  callId: string(),
  isTranscriptHelpful: boolean(),
  transcriptSource: CallTranscriptSourceStruct,
});

export type UpdateCallIsTranscriptHelpfulRequest = Infer<
  typeof UpdateCallIsTranscriptHelpfulRequestStruct
>;

export const updateCallIsTranscriptHelpfulDefinition =
  getFirebaseFunctionDefinition({
    path: "/call/is-transcript-helpful/update",
    reqType: UpdateCallIsTranscriptHelpfulRequestStruct,
  });
