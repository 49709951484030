import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const BUDGET_TYPES = ["FIXED", "FLEXIBLE"] as const;

export const BudgetTypeStruct = enums(BUDGET_TYPES);

export type BudgetType = Infer<typeof BudgetTypeStruct>;

export function isBudgetType(obj: unknown): obj is BudgetType {
  return isStruct(obj, BudgetTypeStruct, "BudgetType");
}
