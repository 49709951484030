import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const SERVICE_CATEGORIES = [
  "INBOUND",
  "OUTBOUND",
  "BACK_OFFICE_SUPPORT",
] as const;

export const ServiceCategoryStruct = enums(SERVICE_CATEGORIES);

export type ServiceCategory = Infer<typeof ServiceCategoryStruct>;

export function isServiceCategory(obj: unknown): obj is ServiceCategory {
  return isStruct(obj, ServiceCategoryStruct, "ServiceCategory");
}
