import { enums, Infer } from "superstruct";

export const ORGANIZATION_REVENUE_VALUES = [
  0, 1_000_000, 10_000_000, 50_000_000, 100_000_000, 200_000_000, 500_000_000,
  1_000_000_000, 1_000_000_001,
] as const;

export const OrganizationRevenueValueStruct = enums(
  ORGANIZATION_REVENUE_VALUES,
);

export type OrganizationRevenueValue = Infer<
  typeof OrganizationRevenueValueStruct
>;

export function isOrganizationRevenueValue(
  value: unknown,
): value is OrganizationRevenueValue {
  return OrganizationRevenueValueStruct.is(value);
}
