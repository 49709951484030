import { Infer, number, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const WeekdayPeriodStruct = type({
  /**
   * 0 = Sunday, 1 = Monday
   */
  weekday: number(),
  from: string(),
  to: string(),
});

/**
 * @interface
 * @category Project
 */
export type WeekdayPeriod = Infer<typeof WeekdayPeriodStruct>;

export function isWeekdayPeriod(obj: unknown): obj is WeekdayPeriod {
  return isStruct(obj, WeekdayPeriodStruct, "WeekdayPeriod");
}
