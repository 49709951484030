import { AppError, isAppError } from "@snubes/snubes-types";

export function getAppError(error: Error): AppError | undefined {
  if (isAppError(error)) {
    return error;
  }
  if ("details" in error && isAppError(error.details)) {
    return error.details;
  }
  if (
    error.cause &&
    typeof error.cause === "object" &&
    "details" in error.cause &&
    isAppError(error.cause.details)
  ) {
    return error.cause.details;
  }
}
