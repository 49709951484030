import { define } from "superstruct";
import { isStruct } from "../utils/isStruct";

const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

export const EmailStruct = define<string>(
  "Email",
  (value: unknown) => typeof value === "string" && EMAIL_REGEX.test(value),
);

export function isEmail(obj: unknown): obj is string {
  return isStruct(obj, EmailStruct, "Email");
}
