import { Infer, object, string } from "superstruct";
import { TenderStruct } from "../../types/Tender";

export const CreateTenderRequestStruct = object({
  organizationId: string(),
  tender: object({
    name: TenderStruct.schema.name,
    description: TenderStruct.schema.description,
    budget: TenderStruct.schema.budget,
    budgetType: TenderStruct.schema.budgetType,
    budgetDurationType: TenderStruct.schema.budgetDurationType,
    languages: TenderStruct.schema.languages,
    isOperatingAroundTheClock: TenderStruct.schema.isOperatingAroundTheClock,
    operatingHours: TenderStruct.schema.operatingHours,
    trainingHours: TenderStruct.schema.trainingHours,
    contact: TenderStruct.schema.contact,
    countries: TenderStruct.schema.countries,
    industries: TenderStruct.schema.industries,
    system: TenderStruct.schema.system,
    systemDescription: TenderStruct.schema.systemDescription,
    services: TenderStruct.schema.services,
    timetable: TenderStruct.schema.timetable,
  }),
});

export type CreateTenderRequest = Infer<typeof CreateTenderRequestStruct>;
