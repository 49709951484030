import {
  array,
  boolean,
  Infer,
  integer,
  max,
  min,
  number,
  optional,
  size,
  string,
  type,
} from "superstruct";
import { MAX_PROJECT_QUESTIONS_COUNT } from "../consts/MAX_PROJECT_QUESTIONS_COUNT";
import { isStruct } from "../utils/isStruct";
import { CallFlaggingConfigStruct } from "./CallFlaggingConfig";
import { LanguageStruct } from "./Language";
import { ProjectCallCategoryStruct } from "./ProjectCallCategory";
import { ProjectIssueCategoryStruct } from "./ProjectIssueCategory";
import { ProjectQuestionStruct } from "./ProjectQuestion";
import { ProjectReportFrameByDayStruct } from "./ProjectReportFrameByDay";
import { ProjectTierStruct } from "./ProjectTier";
import { TimestampStruct } from "./Timestamp";

export const ProjectStruct = type({
  id: string(),
  name: string(),
  providerOrganizationId: string(),
  providerOrganizationName: string(),
  clientOrganizationId: optional(string()),
  clientOrganizationName: optional(string()),
  organizationIds: array(string()),
  projectManagerUserId: optional(string()),
  projectManagerName: optional(string()),
  connectorConfigId: optional(string()),
  tier: optional(ProjectTierStruct), // TODO: should be required, needs Firestore migrations
  reportFramesByDay: optional(array(ProjectReportFrameByDayStruct)),
  isDeleted: boolean(),
  questions: optional(
    size(array(ProjectQuestionStruct), 0, MAX_PROJECT_QUESTIONS_COUNT),
  ),
  callCategories: optional(array(ProjectCallCategoryStruct)),
  issueCategories: optional(array(ProjectIssueCategoryStruct)),
  /**
   * Number of calls currently being processed by the updateCallsWorker.
   */
  flaggingConfigsCallsProcessingCount: optional(number()),
  callFlaggingConfigs: optional(array(CallFlaggingConfigStruct)),
  scorecardQuestions: optional(array(string())),
  isLookerAnalyticsEnabled: optional(boolean()),
  voicefileLanguage: optional(LanguageStruct),
  responseLanguage: optional(LanguageStruct),
  callSamplingRate: optional(max(min(integer(), 0), 100)),
  deleteAt: optional(TimestampStruct),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
  isArchived: boolean(),
});

/**
 * @interface
 * @category Project
 */
export type Project = Infer<typeof ProjectStruct>;

export function isProject(obj: unknown): obj is Project {
  return isStruct(obj, ProjectStruct, "Project");
}
