import { Infer, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { CallQuestionAnswerTypeStruct } from "./CallQuestionAnswerType";

export const ProjectQuestionStruct = type({
  id: string(),
  /**
   * Stable referential ID for call flagging configurations.
   */
  ref: string(),
  text: string(),
  type: CallQuestionAnswerTypeStruct,
});

/**
 * @interface
 * @category Project
 */
export type ProjectQuestion = Infer<typeof ProjectQuestionStruct>;

export function isProjectQuestion(obj: unknown): obj is ProjectQuestion {
  return isStruct(obj, ProjectQuestionStruct, "ProjectQuestion");
}
