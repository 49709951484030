import { boolean, Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateCallIsQuestionAnswerHelpfulRequestStruct = type({
  callId: string(),
  questionAnswerId: string(),
  isHelpful: boolean(),
});

export type UpdateCallIsQuestionAnswerHelpfulRequest = Infer<
  typeof UpdateCallIsQuestionAnswerHelpfulRequestStruct
>;

export const updateCallIsQuestionAnswerHelpfulDefinition =
  getFirebaseFunctionDefinition({
    path: "/call/is-question-answer-helpful/update",
    reqType: UpdateCallIsQuestionAnswerHelpfulRequestStruct,
  });
