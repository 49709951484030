import { define, is, Struct } from "superstruct";

/**
 * Create a struct that returns a custom error message.
 *
 * @see https://github.com/ianstormtaylor/superstruct/issues/568#issuecomment-808872095
 */
export function message<T, S>(
  struct: Struct<T, S>,
  message: string,
): Struct<T, null> {
  return define("message", (value) => (is(value, struct) ? true : message));
}
