import { Infer, is, literal, string, type } from "superstruct";
import { ErrorCode } from "./ErrorCode";

export const OrganizationExistsErrorStruct = type({
  name: literal("OrganizationExistsError"),
  message: string(),
  code: literal("organization/exists" satisfies ErrorCode),
  domain: string(),
});

export type OrganizationExistsError = Infer<
  typeof OrganizationExistsErrorStruct
>;

interface OrganizationExistsErrorImplArgs {
  domain: string;
}

export class OrganizationExistsErrorImpl
  extends Error
  implements OrganizationExistsError
{
  public readonly code = "organization/exists";
  public readonly name = "OrganizationExistsError";
  public readonly domain: string;

  constructor(args: OrganizationExistsErrorImplArgs) {
    super(`Organization ${args.domain} already exists`);
    this.domain = args.domain;
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      code: this.code,
      domain: this.domain,
    } satisfies OrganizationExistsError;
  }
}

export function isOrganizationExistsError(
  obj: unknown,
): obj is OrganizationExistsError {
  return is(obj, OrganizationExistsErrorStruct);
}
