import { assign, Infer, pick, type, union } from "superstruct";
import {
  ConnectorConfigBaseStruct,
  DialfireConnectorConfigParamsStruct,
  GenericWebhookConnectorConfigParamsStruct,
  InoplaConnectorConfigParamsStruct,
  SftpConnectorConfigParamsStruct,
  VccLiveConnectorConfigParamsStruct,
} from "../types/ConnectorConfig";

const ConnectorConfigEventConnectorConfigBaseStruct = type({
  id: ConnectorConfigBaseStruct.schema.id,
  name: ConnectorConfigBaseStruct.schema.name,
  organizationId: ConnectorConfigBaseStruct.schema.organizationId,
  projectMappings: ConnectorConfigBaseStruct.schema.projectMappings,
});

export const ConnectorConfigEventConnectorConfigStruct = union([
  assign(
    ConnectorConfigEventConnectorConfigBaseStruct,
    DialfireConnectorConfigParamsStruct,
  ),
  assign(
    ConnectorConfigEventConnectorConfigBaseStruct,
    InoplaConnectorConfigParamsStruct,
  ),
  assign(
    ConnectorConfigEventConnectorConfigBaseStruct,
    VccLiveConnectorConfigParamsStruct,
  ),
  assign(
    ConnectorConfigEventConnectorConfigBaseStruct,
    SftpConnectorConfigParamsStruct,
  ),
  assign(
    ConnectorConfigEventConnectorConfigBaseStruct,
    GenericWebhookConnectorConfigParamsStruct,
  ),
]);

/**
 * ConnectorConfig object for create or update events.
 * @eventProperty
 * @interface
 * @category ConnectorConfig
 */
export type ConnectorConfigEventConnectorConfig = Infer<
  typeof ConnectorConfigEventConnectorConfigStruct
>;

export const ConnectorConfigDeletedEventConnectorConfigStruct = pick(
  ConnectorConfigBaseStruct,
  ["id"],
);

/**
 * ConnectorConfig object for delete events.
 * @eventProperty
 * @interface
 * @category ConnectorConfig
 */
export type ConnectorConfigDeletedEventConnectorConfig = Infer<
  typeof ConnectorConfigDeletedEventConnectorConfigStruct
>;
