import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const SYSTEMS = ["CLIENT_SYSTEM", "PROVIDER_SYSTEM"] as const;

export const SystemStruct = enums(SYSTEMS);

export type System = Infer<typeof SystemStruct>;

export function isSystem(obj: unknown): obj is System {
  return isStruct(obj, SystemStruct, "System");
}
