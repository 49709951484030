import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

/**
 * ISO-639-1 language codes.
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 * @ignore
 */
export const LANGUAGES = ["de", "en", "es", "fr", "it", "nl", "sv"] as const;

export const LanguageStruct = enums(LANGUAGES);

export type Language = Infer<typeof LanguageStruct>;

export function isLanguage(obj: unknown): obj is Language {
  return isStruct(obj, LanguageStruct, "Language");
}
