import { enums, Infer } from "superstruct";
import { TENDER_APPLICATION_CLIENT_REJECTION_REASONS } from "../consts/TENDER_APPLICATION_CLIENT_REJECTION_REASONS";
import { TENDER_APPLICATION_PROVIDER_REJECTION_REASONS } from "../consts/TENDER_APPLICATION_PROVIDER_REJECTION_REASONS";
import { TENDER_APPLICATION_SYSTEM_REJECTION_REASONS } from "../consts/TENDER_APPLICATION_SYSTEM_REJECTION_REASONS";
import { isStruct } from "../utils/isStruct";

export const TENDER_APPLICATION_REJECTION_REASONS = [
  ...new Set([
    ...TENDER_APPLICATION_CLIENT_REJECTION_REASONS,
    ...TENDER_APPLICATION_PROVIDER_REJECTION_REASONS,
    ...TENDER_APPLICATION_SYSTEM_REJECTION_REASONS,
  ]),
] as const;

export const TenderApplicationRejectionReasonStruct = enums(
  TENDER_APPLICATION_REJECTION_REASONS,
);

export type TenderApplicationRejectionReason = Infer<
  typeof TenderApplicationRejectionReasonStruct
>;

export function isTenderApplicationRejectionReason(
  obj: unknown,
): obj is TenderApplicationRejectionReason {
  return isStruct(
    obj,
    TenderApplicationRejectionReasonStruct,
    "TenderApplicationRejectionReason",
  );
}
