import { Infer, number, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { CurrencyStruct } from "./Currency";

export const MoneyStruct = type({
  amount: number(),
  currency: CurrencyStruct,
});

/**
 * @interface
 * @category Project
 */
export type Money = Infer<typeof MoneyStruct>;

export function isMoney(obj: unknown): obj is Money {
  return isStruct(obj, MoneyStruct, "Money");
}
