import { enums, Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const replyInvitationRequestStruct = type({
  invitationId: string(),
  reply: enums(["accept", "decline"]),
});

export type replyInvitationRequest = Infer<typeof replyInvitationRequestStruct>;

const replyInvitationResponseStruct = type({
  invitationId: string(),
});

export const replyInvitationDefinition = getFirebaseFunctionDefinition({
  path: "/invitations/reply-invitation",
  reqType: replyInvitationRequestStruct,
  resType: replyInvitationResponseStruct,
});
