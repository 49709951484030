import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const PublishTenderRequestStruct = type({
  tenderId: string(),
});

export type PublishTenderRequest = Infer<typeof PublishTenderRequestStruct>;

export const publishTenderDefinition = getFirebaseFunctionDefinition({
  path: "/tenders/publish",
  reqType: PublishTenderRequestStruct,
});
