import { Infer, omit, pick } from "superstruct";
import { ProjectStruct } from "../types/Project";
import { deepReplaceTimestampStructWithStringStruct } from "../utils/deepReplaceTimestampStructWithStringStruct";

export const ProjectEventProjectStruct =
  deepReplaceTimestampStructWithStringStruct(
    omit(ProjectStruct, ["reportFramesByDay"]),
  );

/**
 * Project object for create & update events.
 * @eventProperty
 * @interface
 * @category Project
 */
export type ProjectEventProject = Infer<typeof ProjectEventProjectStruct>;

export const ProjectDeletedEventProjectStruct = pick(ProjectStruct, ["id"]);

/**
 * Project object for delete events.
 * @eventProperty
 * @interface
 * @category Project
 */
export type ProjectDeletedEventProject = Infer<
  typeof ProjectDeletedEventProjectStruct
>;
