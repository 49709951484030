import {
  Infer,
  object,
  optional,
  partial,
  string,
  Struct,
  type,
} from "superstruct";
import { MemberRole } from "../../types/MemberRole";
import { MemberTasksStruct } from "../../types/MemberTask";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateMemberRequestStruct = type({
  organizationId: string(),
  memberId: string(),
  member: object({
    role: optional(string() as Struct<MemberRole>),
    tasks: optional(partial(MemberTasksStruct)),
  }),
});

export type UpdateMemberRequest = Infer<typeof UpdateMemberRequestStruct>;

export const updateMemberDefinition = getFirebaseFunctionDefinition({
  path: "/members/update",
  reqType: UpdateMemberRequestStruct,
});
