import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteNotificationRequestStruct = type({
  notificationId: string(),
});

export type DeleteNotificationRequest = Infer<
  typeof DeleteNotificationRequestStruct
>;

export const deleteNotificationDefinition = getFirebaseFunctionDefinition({
  path: "/notification/delete",
  reqType: DeleteNotificationRequestStruct,
});
