import { isStruct } from "@snubes/snubes-types";
import { Infer, string, type, union } from "superstruct";

export const TenderApplicationMetadataStruct = type({
  userId: string(),
  providerId: string(),
  tenderId: string(),
});

export type TenderApplicationMetadata = Infer<
  typeof TenderApplicationMetadataStruct
>;

export function isTenderApplicationMetadata(
  obj: unknown,
): obj is TenderApplicationMetadata {
  return isStruct(
    obj,
    TenderApplicationMetadataStruct,
    "TenderApplicationMetadata",
  );
}

export const TenderSupportDocumentMetadataStruct = type({
  userId: string(),
  tenderId: string(),
});

export type TenderSupportDocumentMetadata = Infer<
  typeof TenderSupportDocumentMetadataStruct
>;

export function isTenderSupportDocumentMetadata(
  obj: unknown,
): obj is TenderSupportDocumentMetadata {
  return isStruct(
    obj,
    TenderSupportDocumentMetadataStruct,
    "TenderSupportDocumentMetadata",
  );
}

export const OrganizationLogoMetadataStruct = type({
  userId: string(),
  organizationId: string(),
});

export type OrganizationLogoMetadata = Infer<
  typeof OrganizationLogoMetadataStruct
>;

export function isOrganizationLogoMetadata(
  obj: unknown,
): obj is OrganizationLogoMetadata {
  return isStruct(
    obj,
    OrganizationLogoMetadataStruct,
    "OrganizationLogoMetadata",
  );
}

export const StorageFileMetadataStruct = union([
  TenderApplicationMetadataStruct,
  TenderSupportDocumentMetadataStruct,
  OrganizationLogoMetadataStruct,
]);

export type StorageFileMetadata = Infer<typeof StorageFileMetadataStruct>;

export function isStorageFileMetadata(
  obj: unknown,
): obj is StorageFileMetadata {
  return isStruct(obj, StorageFileMetadataStruct, "StorageFileMetadata");
}
