import { Infer, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const ProjectIssueCategoryStruct = type({
  id: string(),
  name: string(),
});

/**
 * @interface
 * @category Project
 */
export type ProjectIssueCategory = Infer<typeof ProjectIssueCategoryStruct>;

export function isProjectIssueCategory(
  obj: unknown,
): obj is ProjectIssueCategory {
  return isStruct(obj, ProjectIssueCategoryStruct, "ProjectIssueCategory");
}
