import { Infer, string, type } from "superstruct";
import { TenderStatusStruct } from "../../types/TenderStatus";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateTenderStatusRequestStruct = type({
  tenderId: string(),
  status: TenderStatusStruct,
});

export type UpdateTenderStatusRequest = Infer<
  typeof UpdateTenderStatusRequestStruct
>;

export const updateTenderStatusDefinition = getFirebaseFunctionDefinition({
  path: "/tender/status/update",
  reqType: UpdateTenderStatusRequestStruct,
});
