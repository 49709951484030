import { Infer, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const ExternalProjectStruct = type({
  id: string(),
  name: string(),
});

/**
 * @interface
 * @category Project
 */
export type ExternalProject = Infer<typeof ExternalProjectStruct>;

export function isExternalProject(obj: unknown): obj is ExternalProject {
  return isStruct(obj, ExternalProjectStruct, "ExternalProject");
}
