import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const UserEventTypeStruct = enums([
  "USER_CREATED",
  "USER_UPDATED",
  "USER_DELETED",
]);

export type UserEventType = Infer<typeof UserEventTypeStruct>;

export function isUserEventType(obj: unknown): obj is UserEventType {
  return isStruct(obj, UserEventTypeStruct, "UserEventType");
}
