import {
  array,
  assign,
  Infer,
  literal,
  number,
  optional,
  string,
  type,
  union,
} from "superstruct";
import { isStruct } from "../utils/isStruct";
import { ConnectorConfigStatusStruct } from "./ConnectorConfigStatus";
import { ConnectorPlatform } from "./ConnectorPlatform";
import { TimestampStruct } from "./Timestamp";

export const ConnectorConfigProjectMapping = type({
  projectId: string(),
  externalProjectId: string(),
});

export const ConnectorConfigBaseStruct = type({
  id: string(),
  name: string(),
  organizationId: string(),
  organizationName: optional(string()), // TODO: make organizationName required
  projectMappings: array(ConnectorConfigProjectMapping),
  backfillDays: optional(number()),
  status: ConnectorConfigStatusStruct,
  statusErrorMessage: optional(string()),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
  webhookUrl: optional(string()),
});

export const VccLiveConnectorConfigParamsStruct = type({
  platform: literal("VCC_LIVE" satisfies ConnectorPlatform),
  userId: string(),
  password: string(),
  customerId: string(),
});

export const SftpConnectorConfigParamsStruct = type({
  platform: literal("SFTP" satisfies ConnectorPlatform),
  host: string(),
  user: string(),
  password: string(),
  csvPath: string(),
  port: optional(number()),
  backfillDays: optional(number()),
});

export const DialfireConnectorConfigParamsStruct = type({
  platform: literal("DIALFIRE" satisfies ConnectorPlatform),
  campaignId: string(),
  campaignToken: string(),
});

export const InoplaConnectorConfigParamsStruct = type({
  platform: literal("INOPLA" satisfies ConnectorPlatform),
  authId: string(),
  authKey: string(),
  backfillDays: optional(number()),
});

export const GenericWebhookConnectorConfigParamsStruct = type({
  platform: literal("GENERIC_WEBHOOK" satisfies ConnectorPlatform),
});

export const ConnectorConfigStruct = union([
  assign(ConnectorConfigBaseStruct, DialfireConnectorConfigParamsStruct),
  assign(ConnectorConfigBaseStruct, InoplaConnectorConfigParamsStruct),
  assign(ConnectorConfigBaseStruct, VccLiveConnectorConfigParamsStruct),
  assign(ConnectorConfigBaseStruct, SftpConnectorConfigParamsStruct),
  assign(ConnectorConfigBaseStruct, GenericWebhookConnectorConfigParamsStruct),
]);

export function isConnectorConfig(obj: unknown): obj is ConnectorConfig {
  return isStruct(obj, ConnectorConfigStruct, "ConnectorConfig");
}

/**
 * @interface
 * @category Project
 */
export type InoplaConnectorConfigParams = Infer<
  typeof InoplaConnectorConfigParamsStruct
>;

/**
 * @interface
 * @category Project
 */
export type VccLiveConnectorConfigParams = Infer<
  typeof VccLiveConnectorConfigParamsStruct
>;

/**
 * @interface
 * @category Project
 */
export type SftpConnectorConfigParamsStruct = Infer<
  typeof SftpConnectorConfigParamsStruct
>;

/**
 * @interface
 * @category Project
 */
export type GenericWebhookConnectorConfigParamsStruct = Infer<
  typeof GenericWebhookConnectorConfigParamsStruct
>;

/**
 * @category Project
 */
export type ConnectorConfig = Infer<typeof ConnectorConfigStruct>;
