import {
  array,
  boolean,
  Infer,
  number,
  optional,
  record,
  string,
  type,
} from "superstruct";
import { deprecatedWithLog } from "../utils/deprecatedWithLog";
import { isStruct } from "../utils/isStruct";
import { BudgetDurationTypeStruct } from "./BudgetDurationType";
import { BudgetTypeStruct } from "./BudgetType";
import { ChannelStruct } from "./Channel";
import { EmailStruct } from "./Email";
import { IndustryStruct } from "./Industry";
import { MoneyStruct } from "./Money";
import { ServiceTypeStruct } from "./ServiceType";
import { StorageFileStruct } from "./StorageFile";
import { SystemStruct } from "./System";
import { TenderAgentTrainingModelStruct } from "./TenderAgentTrainingModel";
import { TenderApplicationStruct } from "./TenderApplication";
import { TenderStatusStruct } from "./TenderStatus";
import { TenderSupportDocumentsTypeStruct } from "./TenderSupportDocumentsType";
import { TimestampStruct } from "./Timestamp";
import { TimetableTypeStruct } from "./TimetableType";
import { WeekdayPeriodStruct } from "./WeekdayPeriod";

export const TenderStruct = type({
  id: string(),
  hubspotDealId: optional(string()),
  name: optional(string()),
  description: optional(string()),
  organizationId: string(),
  organizationName: string(),
  status: TenderStatusStruct,
  budget: optional(MoneyStruct),
  budgetType: optional(BudgetTypeStruct),
  budgetDurationType: optional(BudgetDurationTypeStruct),
  languages: optional(array(string())),
  isOperatingAroundTheClock: optional(boolean()),
  operatingHours: optional(array(WeekdayPeriodStruct)),
  operatingTimeZone: optional(string()),
  /**
   * @deprecated use agentTrainingModels instead
   */
  trainingHours: deprecatedWithLog(
    optional(number()),
    "trainingHours is deprecated",
  ),
  agentTrainingBudget: optional(MoneyStruct),
  agentTrainingBudgetType: optional(BudgetTypeStruct),
  agentTrainingModels: optional(array(TenderAgentTrainingModelStruct)),
  contact: optional(
    type({
      firstName: optional(string()),
      lastName: optional(string()),
      email: optional(EmailStruct),
      phone: optional(string()),
    }),
  ),
  industries: optional(array(IndustryStruct)),
  countries: optional(array(string())),
  system: optional(SystemStruct),
  systemDescription: optional(string()),
  services: optional(
    array(
      type({
        type: ServiceTypeStruct,
        channels: optional(array(ChannelStruct)),
      }),
    ),
  ),
  timetable: optional(
    type({
      /**
       * Confirmation deadline is only necessary for long matches.
       */
      confirmationDeadlineAt: optional(string()),
      applicationDeadlineAt: optional(string()),
      estimatedDecisionAt: optional(string()),
      estimatedProjectStartAt: optional(string()),
      timetableType: optional(TimetableTypeStruct),
      estimatedProjectEndAt: optional(string()),
    }),
  ),
  isReviewed: optional(
    type({
      basicDetails: optional(boolean()),
      services: optional(boolean()),
      operationalDetails: optional(boolean()),
      uploadDocuments: optional(boolean()),
      providers: optional(boolean()),
    }),
  ),
  /**
   * @deprecated Use providerApplications instead.
   */
  matchedProviderIds: deprecatedWithLog(
    optional(array(string())),
    "matchedProviderIds are deprecated. Use providerApplications instead.",
  ),
  providerApplications: optional(record(string(), TenderApplicationStruct)),
  supportDocumentsType: optional(TenderSupportDocumentsTypeStruct),
  supportDocuments: optional(record(string(), StorageFileStruct)),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
  submittedAt: optional(TimestampStruct),
  publishedAt: optional(TimestampStruct),
  endedAt: optional(TimestampStruct),
  isDeleted: boolean(),
  deleteAt: optional(TimestampStruct),
});

/**
 * @interface
 * @category Tender
 */
export type Tender = Infer<typeof TenderStruct>;

export function isTender(obj: unknown): obj is Tender {
  return isStruct(obj, TenderStruct, "Tender");
}
