import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CALL_PARTICIPANT_ROLES = [
  "CUSTOMER",
  "AGENT",
  "SPEAKER_0",
  "SPEAKER_1",
  "UNKNOWN",
] as const;

export const CallParticipantRoleStruct = enums(CALL_PARTICIPANT_ROLES);

export type CallParticipantRole = Infer<typeof CallParticipantRoleStruct>;

export function isCallParticipantRole(
  obj: unknown,
): obj is CallParticipantRole {
  return isStruct(obj, CallParticipantRoleStruct, "CallParticipantRole");
}
