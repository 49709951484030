import { Describe, string, tuple, unknown } from "superstruct";
import { Path } from "../utils/Path";
import {
  FirebaseWhereFilterOp,
  FirebaseWhereFilterOpStruct,
} from "./FirebaseWhereFilterOp";

export type FirebaseFilter<T> = [
  field: Path<T>,
  operator: FirebaseWhereFilterOp,
  value: unknown,
];

export function firebaseFilter<T>(): Describe<FirebaseFilter<T>> {
  return tuple([
    string() as unknown as Describe<Path<T>>,
    FirebaseWhereFilterOpStruct,
    unknown(),
  ]);
}
