import { Infer, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const PubSubRequestBodyStruct = type({
  message: type({
    data: string(),
  }),
});

/**
 * The body of a PubSub request.
 *
 * @see https://cloud.google.com/pubsub/docs/push#receive_push
 * @ignore
 */
export type PubSubRequestBody = Infer<typeof PubSubRequestBodyStruct>;

export function isPubSubRequestBody(obj: unknown): obj is PubSubRequestBody {
  return isStruct(obj, PubSubRequestBodyStruct, "PubSubRequestBody");
}
