import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TENDER_STATUSES = [
  "DRAFT",
  "WAITING_FOR_REVIEW",
  "PUBLISHED",
  "ENDED",
] as const;

export const TenderStatusStruct = enums(TENDER_STATUSES);

export type TenderStatus = Infer<typeof TenderStatusStruct>;

export function isTenderStatus(obj: unknown): obj is TenderStatus {
  return isStruct(obj, TenderStatusStruct, "TenderStatus");
}
