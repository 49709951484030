import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteCallIssueRequestStruct = type({
  callId: string(),
  callIssueId: string(),
});

export type DeleteCallIssueRequest = Infer<typeof DeleteCallIssueRequestStruct>;

export const deleteCallIssueDefinition = getFirebaseFunctionDefinition({
  path: "/calls/issues/delete",
  reqType: DeleteCallIssueRequestStruct,
});
