import { assign, Infer, literal, object, union } from "superstruct";
import { CloudEventStruct } from "../types/CloudEvent";
import { isStruct } from "../utils/isStruct";
import {
  TenderDeletedEventTenderStruct,
  TenderEventTenderStruct,
} from "./TenderEventTender";
import { TenderEventType } from "./TenderEventType";

export const TENDER_EVENT_VERSION = 1;

export const TenderCreatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("TENDER_CREATED" satisfies TenderEventType),
    tender: TenderEventTenderStruct,
    version: literal(TENDER_EVENT_VERSION),
  }),
);

/**
 * Tender created event.
 * @event
 * @interface
 * @category Tender
 */
export type TenderCreatedEvent = Infer<typeof TenderCreatedEventStruct>;

export const TenderUpdatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("TENDER_UPDATED" satisfies TenderEventType),
    tender: TenderEventTenderStruct,
    version: literal(TENDER_EVENT_VERSION),
  }),
);

/**
 * Tender updated event.
 * @event
 * @interface
 * @category Tender
 */
export type TenderUpdatedEvent = Infer<typeof TenderUpdatedEventStruct>;

export const TenderDeletedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("TENDER_DELETED" satisfies TenderEventType),
    tender: TenderDeletedEventTenderStruct,
    version: literal(TENDER_EVENT_VERSION),
  }),
);

/**
 * Tender deleted event.
 * @event
 * @interface
 * @category Tender
 */
export type TenderDeletedEvent = Infer<typeof TenderDeletedEventStruct>;

export const TenderEventStruct = union([
  TenderCreatedEventStruct,
  TenderUpdatedEventStruct,
  TenderDeletedEventStruct,
]);

/**
 * Common tender event. Union type of all tender events.
 * @event
 * @category Tender
 */
export type TenderEvent = Infer<typeof TenderEventStruct>;

export function isTenderEvent(obj: unknown): obj is TenderEvent {
  return isStruct(obj, TenderEventStruct, "TenderEvent");
}
