import { boolean, Infer, number, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { CallParticipantRoleStruct } from "./CallParticipantRole";
import { CallTranscriptSourceStruct } from "./CallTranscriptSource";

export const CallTranscriptStruct = type({
  id: string(),
  startOffsetMilliseconds: number(),
  endOffsetMilliseconds: number(),
  content: string(),
  isFlagged: optional(boolean()),
  sentiment: number(),
  participantRole: CallParticipantRoleStruct,
  transcriptSource: optional(CallTranscriptSourceStruct),
});

/**
 * @interface
 * @category Call
 */
export type CallTranscript = Infer<typeof CallTranscriptStruct>;

export function isCallTranscript(obj: unknown): obj is CallTranscript {
  return isStruct(obj, CallTranscriptStruct, "CallTranscript");
}
