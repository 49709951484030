import { Infer, object, string } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UnarchiveProjectRequestStruct = object({
  projectId: string(),
});

export type UnarchiveProjectRequest = Infer<
  typeof UnarchiveProjectRequestStruct
>;

export const unarchiveProjectDefinition = getFirebaseFunctionDefinition({
  path: "/projects/unarchive",
  reqType: UnarchiveProjectRequestStruct,
});
