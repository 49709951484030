import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const ProjectEventTypeStruct = enums([
  "PROJECT_CREATED",
  "PROJECT_UPDATED",
  "PROJECT_DELETED",
]);

export type ProjectEventType = Infer<typeof ProjectEventTypeStruct>;

export function isProjectEventType(obj: unknown): obj is ProjectEventType {
  return isStruct(obj, ProjectEventTypeStruct, "ProjectEventType");
}
