import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const SignUpRequestStruct = type({
  email: string(),
  password: string(),
  firstName: string(),
  lastName: string(),
});

const SignUpResponseStruct = type({
  token: string(),
  userId: string(),
});

export type SignUpRequest = Infer<typeof SignUpRequestStruct>;

export const signUpDefinition = getFirebaseFunctionDefinition({
  path: "/auth/sign-up",
  reqType: SignUpRequestStruct,
  resType: SignUpResponseStruct,
});
