import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

/**
 * ISO-639-1 language codes.
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 * @ignore
 */
export const LOCALES = ["de", "en"] as const;

export const LocaleStruct = enums(LOCALES);

export type Locale = Infer<typeof LocaleStruct>;

export function isLocale(obj: unknown): obj is Locale {
  return isStruct(obj, LocaleStruct, "Locale");
}
