import { string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";
import { CreateOrganizationRequestStruct } from "../types/CreateOrganizationRequest";

const CreateOrganizationResponseStruct = type({
  organizationId: string(),
});

export const createOrganizationDefinition = getFirebaseFunctionDefinition({
  path: "/organizations/create",
  reqType: CreateOrganizationRequestStruct,
  resType: CreateOrganizationResponseStruct,
});
