import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const GetVoicefileRequestStruct = type({
  callId: string(),
});

const GetVoicefileResponseStruct = type({
  url: string(),
});

export type GetVoicefileRequest = Infer<typeof GetVoicefileRequestStruct>;

export const getVoicefileDefinition = getFirebaseFunctionDefinition({
  path: "/calls/get-voicefile",
  reqType: GetVoicefileRequestStruct,
  resType: GetVoicefileResponseStruct,
});
