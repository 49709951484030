import { string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteTenderSupportDocumentRequestStruct = type({
  tenderId: string(),
  fileHash: string(),
});

export const deleteTenderSupportDocumentDefinition =
  getFirebaseFunctionDefinition({
    path: "/tenders/delete-tender-support-document",
    reqType: DeleteTenderSupportDocumentRequestStruct,
  });
