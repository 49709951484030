import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const ConnectorConfigEventTypeStruct = enums([
  "CONNECTOR_CONFIG_CREATED",
  "CONNECTOR_CONFIG_DELETED",
  "CONNECTOR_CONFIG_UPDATED",
]);

export type ConnectorConfigEventType = Infer<
  typeof ConnectorConfigEventTypeStruct
>;

export function isConnectorConfigType(
  obj: unknown,
): obj is ConnectorConfigEventType {
  return isStruct(
    obj,
    ConnectorConfigEventTypeStruct,
    "ConnectorConfigEventType",
  );
}
