import { boolean, string, type } from "superstruct";
import { TenderApplicationRejectionReasonStruct } from "../../types/TenderApplicationRejectionReason";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const RejectTenderApplicationRequestStruct = type({
  tenderId: string(),
  providerId: string(),
  isRejectForResubmission: boolean(),
  rejectionReason: TenderApplicationRejectionReasonStruct,
});

export const rejectTenderApplicationDefinition = getFirebaseFunctionDefinition({
  path: "/tenders/reject-application",
  reqType: RejectTenderApplicationRequestStruct,
});
