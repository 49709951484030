import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TARGET_GROUP_AUDIENCES = [
  "MANAGEMENT",
  "MARKETING",
  "SALES",
  "IT",
  "HR",
  "FINANCE",
  "PROCUREMENT",
  "LEGAL",
  "OPERATIONS",
  "OTHER",
] as const;

export const TargetGroupAudienceStruct = enums(TARGET_GROUP_AUDIENCES);

export type TargetGroupAudience = Infer<typeof TargetGroupAudienceStruct>;

export function isTargetGroupAudience(
  obj: unknown,
): obj is TargetGroupAudience {
  return isStruct(obj, TargetGroupAudienceStruct, "TargetGroupAudience");
}
