import { Infer, string, type } from "superstruct";
import { MemberRoleStruct } from "../../types/MemberRole";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const CreateInvitationRequestStruct = type({
  organizationId: string(),
  organizationName: string(),
  email: string(),
  memberRole: MemberRoleStruct,
});

export type CreateInvitationRequest = Infer<
  typeof CreateInvitationRequestStruct
>;

const CreateInvitationResponseStruct = type({
  invitationId: string(),
});

export const createInvitationDefinition = getFirebaseFunctionDefinition({
  path: "/invitations/create-invitation",
  reqType: CreateInvitationRequestStruct,
  resType: CreateInvitationResponseStruct,
});
