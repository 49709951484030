import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const PROVIDER_BUSINESS_TYPES = ["CALL_CENTER", "BPO"] as const;

export const ProviderBusinessTypeStruct = enums(PROVIDER_BUSINESS_TYPES);

export type ProviderBusinessType = Infer<typeof ProviderBusinessTypeStruct>;

export function isProviderBusinessType(
  obj: unknown,
): obj is ProviderBusinessType {
  return isStruct(obj, ProviderBusinessTypeStruct, "ProviderBusinessType");
}
