import { Infer, object, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteProjectRequestStruct = object({
  projectId: string(),
});

export type DeleteProjectRequest = Infer<typeof DeleteProjectRequestStruct>;

const DeleteProjectResponseStruct = type({
  id: string(),
});

export const deleteProjectDefinition = getFirebaseFunctionDefinition({
  path: "/projects/delete",
  reqType: DeleteProjectRequestStruct,
  resType: DeleteProjectResponseStruct,
});
