import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CHANNEL_TYPES = [
  "PHONE",
  "EMAIL",
  "MAIL",
  "WEB_CHAT",
  "FAX",
] as const;

export const ChannelTypeStruct = enums(CHANNEL_TYPES);

export type ChannelType = Infer<typeof ChannelTypeStruct>;

export function isChannelType(obj: unknown): obj is ChannelType {
  return isStruct(obj, ChannelTypeStruct, "ChannelType");
}
