import { enums, Infer, is } from "superstruct";

const PERMISSIONS = [
  "CAN_SEE_ADMIN_PANEL",
  "CAN_READ_LOGS",
  "CAN_WRITE_AUTH_USER",
  "CAN_WRITE_USER",
  "CAN_WRITE_USER_ROLE",
  "CAN_ASSUME_ORGANIZATION",
  "CAN_WRITE_ORGANIZATION",
  "CAN_DELETE_ORGANIZATION",
  "CAN_WRITE_ORGANIZATION_CONNECTOR_CONFIG",
  "CAN_WRITE_ORGANIZATION_MEMBER",
  "CAN_CREATE_PROJECT",
  "CAN_UPDATE_PROJECT_ALL",
  "CAN_UPDATE_PROJECT_LIMITED", // TODO @deprecated, use granular permissions instead
  "CAN_UPDATE_PROJECT_ISSUE_CATEGORIES",
  "CAN_UPDATE_PROJECT_CALL_CATEGORIES",
  "CAN_UPDATE_PROJECT_SCORECARD_QUESTIONS",
  "CAN_ARCHIVE_PROJECT",
  "CAN_DELETE_PROJECT",
  "CAN_RESTORE_PROJECT",
  "CAN_WRITE_PROJECT_CALL_FLAGGING_CONFIGS",
  "CAN_UPDATE_NOTIFICATION_HAS_BEEN_READ",
  "CAN_WRITE_PUBLISHED_TENDER",
  "CAN_WRITE_TENDER",
  "CAN_PUBLISH_TENDER",
  "CAN_DELETE_TENDER",
  "CAN_END_TENDER",
  "CAN_RESTORE_TENDER",
  "CAN_UPDATE_TENDER_STATUS",
  "CAN_UPDATE_TENDER_PROVIDERS",
  "CAN_ASSIGN_CALL",
  "CAN_COMPLETE_CALL_REVIEW",
  "CAN_REQUEST_PROCESS_CALL",
  "CAN_REQUEST_PROCESS_ALL_CALLS",
  "CAN_READ_CALL",
  "CAN_WRITE_CALL",
  "CAN_DELETE_CALL_ISSUE",
  "CAN_WRITE_INVITATION",
  "CAN_WRITE_HUBSPOT",
  "CAN_READ_INVITATIONS",
  "CAN_WRITE_PROVIDER",
  "CAN_DELETE_NOTIFICATION",
  "CAN_COPY_SFTP_CONNECTION",
] as const;

export const PermissionStruct = enums(PERMISSIONS);

export type Permission = Infer<typeof PermissionStruct>;

export function isPermission(obj: unknown): obj is Permission {
  return is(obj, PermissionStruct);
}
