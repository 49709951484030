import {
  any,
  array,
  assign,
  boolean,
  Infer,
  literal,
  number,
  optional,
  string,
  type,
  union,
} from "superstruct";
import { isStruct } from "../utils/isStruct";
import { CallFlaggingConfigParameterOperatorStruct } from "./CallFlaggingConfigParameterOperator";
import {
  CallFlaggingConfigCommonParameterTypeStruct,
  CallFlaggingConfigParameterType,
} from "./CallFlaggingConfigParameterType";

export const CallFlaggingConfigCommonErrorStruct = type({
  type: CallFlaggingConfigCommonParameterTypeStruct,
  operator: CallFlaggingConfigParameterOperatorStruct,
  expected: union([number(), boolean(), string()]),
  actual: any(), // using unknown leads to type errors in conjunction with WithFieldValue
  issueCategoryId: optional(string()),
});

export type CallFlaggingConfigCommonError = Infer<
  typeof CallFlaggingConfigErrorStruct
>;

export const CallFlaggingConfigTranscriptErrorStruct = assign(
  CallFlaggingConfigCommonErrorStruct,
  type({
    type: literal("TRANSCRIPT" satisfies CallFlaggingConfigParameterType),
    transcriptIds: array(string()),
  }),
);

export type CallFlaggingConfigTranscriptError = Infer<
  typeof CallFlaggingConfigTranscriptErrorStruct
>;

export const CallFlaggingConfigQuestionAnswerErrorStruct = assign(
  CallFlaggingConfigCommonErrorStruct,
  type({
    type: literal("QUESTION_ANSWER" satisfies CallFlaggingConfigParameterType),
    questionId: string(),
    questionText: optional(string()),
  }),
);

export type CallFlaggingConfigQuestionAnswerError = Infer<
  typeof CallFlaggingConfigQuestionAnswerErrorStruct
>;

export const CallFlaggingConfigErrorStruct = union([
  CallFlaggingConfigCommonErrorStruct,
  CallFlaggingConfigTranscriptErrorStruct,
  CallFlaggingConfigQuestionAnswerErrorStruct,
]);

/**
 * @interface
 * @category Project
 */
export type CallFlaggingConfigError = Infer<
  typeof CallFlaggingConfigErrorStruct
>;

export function isCallFlaggingConfigError(
  obj: unknown,
): obj is CallFlaggingConfigError {
  return isStruct(
    obj,
    CallFlaggingConfigErrorStruct,
    "CallFlaggingConfigError",
  );
}
