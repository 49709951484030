import {
  array,
  boolean,
  Infer,
  number,
  optional,
  string,
  type,
} from "superstruct";
import { deprecatedWithLog } from "../utils/deprecatedWithLog";
import { isStruct } from "../utils/isStruct";
import { EmailStruct } from "./Email";
import { IndustryStruct } from "./Industry";
import { MoneyStruct } from "./Money";
import { OrganizationClientFocusStruct } from "./OrganizationClientFocus";
import { OrganizationLayoutStruct } from "./OrganizationLayout";
import * as OrganizationType from "./OrganizationType";
import { TimestampStruct } from "./Timestamp";

export const OrganizationStruct = type({
  id: string(),
  hubspotCompanyId: optional(string()),
  isHubspotEnabled: optional(boolean()),
  name: string(),
  // TODO: make Organization.domain required
  domain: optional(string()),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
  layout: optional(OrganizationLayoutStruct),
  /**
   * Must be kept in sync with the number of members in the sub-collection `members`.
   */
  organizationMembersCount: optional(number()),
  types: optional(array(OrganizationType.OrganizationTypeStruct)),
  email: optional(EmailStruct),
  phone: optional(string()),
  street: optional(string()),
  city: optional(string()),
  zipCode: optional(string()),
  country: optional(string()),
  description: optional(string()),
  numberOfEmployees: optional(number()),
  foundedInYear: optional(number()),
  /**
   * @deprecated Use `revenuePerYear` instead.
   */
  annualRevenue: deprecatedWithLog(
    optional(number()),
    "Organization.annualRevenue is deprecated",
  ),
  revenuePerYear: optional(MoneyStruct),
  industry: optional(IndustryStruct),
  clientFocus: optional(array(OrganizationClientFocusStruct)),
  website: optional(string()),
  socialMediaFacebook: optional(string()),
  socialMediaTwitter: optional(string()),
  socialMediaLinkedIn: optional(string()),
  socialMediaInstagram: optional(string()),
  socialMediaYoutube: optional(string()),
  isDeleted: boolean(),
});

/**
 * @interface
 * @category Organization
 */
export type Organization = Infer<typeof OrganizationStruct>;

export function isOrganization(obj: unknown): obj is Organization {
  return isStruct(obj, OrganizationStruct, "Organization");
}
