import { deprecated, Infer, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { CallFlaggingConfigErrorStruct } from "./CallFlaggingConfigError";
import { CallIssueStatusStruct } from "./CallIssueStatus";
import { TimestampStruct } from "./Timestamp";

export const CallIssueStruct = type({
  id: string(),
  projectId: string(),
  callId: string(),
  /**
   * Name (reason) of the issue.
   */
  name: string(),
  /**
   * Full name of the Snubes user who reported the issue (missing if the issue was automatically created).
   */
  reporterName: optional(string()),
  reporterUserId: optional(string()),
  /**
   * @deprecated Individual issues don't get reviewed, only tasks/calls.
   */
  reviewerName: deprecated(optional(string()), () =>
    console.warn("CallIssue.reviewerName is deprecated"),
  ),
  /**
   * @deprecated Individual issues don't get reviewed, only tasks/calls.
   */
  reviewerUserId: deprecated(optional(string()), () =>
    console.warn("CallIssue.reviewerUserId is deprecated"),
  ),
  /**
   * @deprecated Issues can only be deleted.
   */
  status: deprecated(
    optional(CallIssueStatusStruct),
    () => undefined,
    // console.warn("CallIssue.status is deprecated")
  ),
  /**
   * If the issue was automatically created, the ID of the corresponding CallFlaggingConfig.
   */
  callFlaggingConfigId: optional(string()),
  callFlaggingConfigError: optional(CallFlaggingConfigErrorStruct),
  issueCategoryId: optional(string()),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
});

/**
 * @interface
 * @category Call
 */
export type CallIssue = Infer<typeof CallIssueStruct>;

export function isCallIssue(obj: unknown): obj is CallIssue {
  return isStruct(obj, CallIssueStruct, "CallIssue");
}
