import { enums, Infer } from "superstruct";

export const TENDER_NOTIFICATION_TYPES = [
  "TENDER_SUBMITTED",
  "TENDER_PUBLISHED",
  "TENDER_MATCHED",
  "TENDER_ENDED",
  "TENDER_ENDED_ADMIN",
  "TENDER_APPLICATION_RECEIVED",
  "TENDER_APPLICATION_REJECTED",
  "TENDER_APPLICATION_ACCEPTED",
  "TENDER_APPLICATIONS_COMPLETED_ADMIN",
] as const;

export const TenderNotificationTypeStruct = enums(TENDER_NOTIFICATION_TYPES);

export type TenderNotificationType = Infer<typeof TenderNotificationTypeStruct>;

export const TASK_TYPES = ["CALL_REVIEW"] as const;

const NotificationTypeStruct = enums([
  ...TENDER_NOTIFICATION_TYPES,
  ...TASK_TYPES,
]);

export type NotificationType = Infer<typeof NotificationTypeStruct>;
