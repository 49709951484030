import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CALL_PROCESS_REQUEST_KINDS = [
  "VOICEFILE",
  "TRANSCRIPTS",
  "SUMMARY",
  "CATEGORY",
  "QUESTION_ANSWERS",
  "FLAGGING",
] as const;

export const CallProcessRequestKindStruct = enums(CALL_PROCESS_REQUEST_KINDS);

export type CallProcessRequestKind = Infer<typeof CallProcessRequestKindStruct>;

export function isCallProcessRequestKind(
  obj: unknown,
): obj is CallProcessRequestKind {
  return isStruct(obj, CallProcessRequestKindStruct, "CallProcessRequestKind");
}
