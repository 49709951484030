import { Infer, number, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { TenderAgentTrainingPriceModelStruct } from "./TenderAgentTrainingPriceModel";
import { TenderAgentTypeStruct } from "./TenderAgentType";

export const TenderAgentTrainingModelStruct = type({
  agentType: TenderAgentTypeStruct,
  initialHoursPerAgent: number(),
  agentTrainingPriceModel: TenderAgentTrainingPriceModelStruct,
});

export type TenderAgentTrainingModel = Infer<
  typeof TenderAgentTrainingModelStruct
>;

export function isTenderAgentTrainingModel(
  obj: unknown,
): obj is TenderAgentTrainingModel {
  return isStruct(
    obj,
    TenderAgentTrainingModelStruct,
    "TenderAgentTrainingModel",
  );
}
