import { Infer, optional, string, type } from "superstruct";
import { CallProcessRequestKindStruct } from "../../types/CallProcessRequestKind";
import { LanguageStruct } from "../../types/Language";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const RequestProcessCallRequestStruct = type({
  callId: string(),
  kind: CallProcessRequestKindStruct,
  language: optional(LanguageStruct),
});

export type RequestProcessCallRequest = Infer<
  typeof RequestProcessCallRequestStruct
>;

export const requestProcessCallDefinition = getFirebaseFunctionDefinition({
  path: "/calls/request-process",
  reqType: RequestProcessCallRequestStruct,
});
