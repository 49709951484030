import { omit, partial, string, type } from "superstruct";
import { TenderApplicationStruct } from "../../types/TenderApplication";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";
import { nullableObject } from "../../utils/nullableObject";

const CreateOrUpdateTenderApplicationRequestStruct = type({
  tenderId: string(),
  providerId: string(),
  tenderApplication: partial(
    nullableObject(
      omit(TenderApplicationStruct, [
        "providerId",
        "createdAt",
        "updatedAt",
        "publishedAt",
        "acceptedAt",
        "rejectedAt",
      ]),
    ),
  ),
});

export const createOrUpdateTenderApplicationDefinition =
  getFirebaseFunctionDefinition({
    path: "/tenders/create-or-update-application",
    reqType: CreateOrUpdateTenderApplicationRequestStruct,
  });
