import {
  array,
  boolean,
  Infer,
  number,
  optional,
  partial,
  string,
  type,
} from "superstruct";
import { deprecatedWithLog } from "../utils/deprecatedWithLog";
import { isStruct } from "../utils/isStruct";
import { recordOf } from "../utils/recordOf";
import { CurrencyStruct } from "./Currency";
import { EmailStruct } from "./Email";
import { IndustryStruct } from "./Industry";
import { MoneyStruct } from "./Money";
import { ProviderBusinessTypeStruct } from "./ProviderBusinessType";
import { ProviderClientFocusStruct } from "./ProviderClientFocus";
import { ProviderClientTypeStruct } from "./ProviderClientType";
import { ProviderOperationCenterStruct } from "./ProviderOperationCenter";
import { ProviderPricingModelStruct } from "./ProviderPricingModel";
import { ProviderProfessionalExperienceStruct } from "./ProviderProfessionalExperience";
import { ProviderStatusStruct } from "./ProviderStatus";
import { ProviderTechnologyStruct } from "./ProviderTechnology";
import { ServiceCategoryStruct } from "./ServiceCategory";
import { ServiceTypeStruct } from "./ServiceType";
import { TeamModelStruct } from "./TeamModel";
import { TimestampStruct } from "./Timestamp";
import { WeekdayPeriodStruct } from "./WeekdayPeriod";

export const ProviderStruct = type({
  id: string(),
  status: ProviderStatusStruct,
  organizationId: string(),
  organizationName: string(),
  /**
   * Must be kept in sync with the number of members in the organization sub-collection `members`.
   */
  organizationMembersCount: optional(number()),
  /**
   * Must be kept in sync with the organization members from sub-collection `members`.
   */
  hasOrganizationMembers: boolean(),
  numberOfEmployees: optional(number()),
  organizationFoundedInYear: optional(number()),
  organizationIndustry: optional(IndustryStruct),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
  email: optional(EmailStruct),
  phone: optional(string()),
  website: optional(string()),
  street: optional(string()),
  city: optional(string()),
  zipCode: optional(string()),
  country: optional(string()),
  socialMediaFacebook: optional(string()),
  socialMediaTwitter: optional(string()),
  socialMediaLinkedIn: optional(string()),
  socialMediaInstagram: optional(string()),
  socialMediaYoutube: optional(string()),
  /**
   * @deprecated
   */
  businessTypes: deprecatedWithLog(
    optional(array(ProviderBusinessTypeStruct)),
    "Provider.businessTypes is deprecated",
  ),
  description: optional(string()),
  services: optional(array(ServiceTypeStruct)),
  clientTypes: optional(array(ProviderClientTypeStruct)),
  clientFocus: optional(array(ProviderClientFocusStruct)),
  industries: optional(array(IndustryStruct)),
  /**
   * @deprecated
   */
  professionalExperiences: deprecatedWithLog(
    optional(array(ProviderProfessionalExperienceStruct)),
    "Provider.professionalExperiences is deprecated",
  ),
  /**
   * @deprecated
   */
  revenueRangeYearly: deprecatedWithLog(
    optional(array(number())),
    "Provider.revenueRangeYearly is deprecated",
  ),
  /**
   * @deprecated
   */
  revenueRangeCurrency: deprecatedWithLog(
    optional(CurrencyStruct),
    "Provider.revenueRangeCurrency is deprecated",
  ),
  /**
   * @deprecated
   */
  minimumBudget: deprecatedWithLog(
    optional(MoneyStruct),
    "Provider.minimumBudget is deprecated",
  ),
  /**
   * @deprecated Use pricingModelsByServiceCategory instead.
   */
  pricingModels: deprecatedWithLog(
    optional(array(ProviderPricingModelStruct)),
    "Provider.pricingModels is deprecated",
  ),
  revenuePerYear: optional(MoneyStruct),
  pricingModelsByServiceCategory: optional(
    partial(recordOf(ServiceCategoryStruct, array(ProviderPricingModelStruct))),
  ),
  supportedLanguages: optional(array(string())),
  toolsAndTechnologies: optional(array(ProviderTechnologyStruct)),
  isWorkingAroundTheClock: optional(boolean()),
  workingHours: optional(array(WeekdayPeriodStruct)),
  /**
   * @deprecated Use teamModels instead.
   */
  teamModel: deprecatedWithLog(
    optional(TeamModelStruct),
    "Provider.teamModel is deprecated",
  ),
  teamModels: optional(array(TeamModelStruct)),
  operationCenters: optional(array(ProviderOperationCenterStruct)),
  isReviewed: optional(
    type({
      basicDetails: optional(boolean()),
      businessDetails: optional(boolean()),
      serviceDetails: optional(boolean()),
      operationalDetails: optional(boolean()),
    }),
  ),
  isDeleted: boolean(),
});

/**
 * @interface
 * @category Provider
 */
export type Provider = Infer<typeof ProviderStruct>;

export function isProvider(obj: unknown): obj is Provider {
  return isStruct(obj, ProviderStruct, "Provider");
}
