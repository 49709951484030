import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const PROJECT_TIERS = ["PLATFORM", "PLATFORM_PLUS"] as const;

export const ProjectTierStruct = enums(PROJECT_TIERS);

export type ProjectTier = Infer<typeof ProjectTierStruct>;

export function isProjectTier(obj: unknown): obj is ProjectTier {
  return isStruct(obj, ProjectTierStruct, "ProjectTier");
}
