import { Infer, string, type, union } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteNotificationAllWithOrganizationIdRequestStruct = type({
  organizationId: string(),
});

const DeleteNotificationAllWithUserIdRequestStruct = type({
  userId: string(),
});

const DeleteNotificationAllRequestStruct = union([
  DeleteNotificationAllWithOrganizationIdRequestStruct,
  DeleteNotificationAllWithUserIdRequestStruct,
]);

export type DeleteNotificationAllRequest = Infer<
  typeof DeleteNotificationAllRequestStruct
>;

export const deleteNotificationAllDefinition = getFirebaseFunctionDefinition({
  path: "/notification/delete-all",
  reqType: DeleteNotificationAllRequestStruct,
});
