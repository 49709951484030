import {
  array,
  assign,
  Infer,
  number,
  object,
  optional,
  string,
  type,
  union,
} from "superstruct";
import { ConnectorConfigBaseStruct } from "../../types/ConnectorConfig";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const ConnectorConfigBase = type({
  name: optional(ConnectorConfigBaseStruct.schema.name),
  projectMappings: optional(
    array(
      type({
        projectId: string(),
        projectName: optional(string()), // This is only to create a new project.
        externalProjectId: string(),
      }),
    ),
  ),
  backfillDays: optional(number()),
});

const VccLiveConnectorConfigParamsStruct = type({
  password: optional(string()),
});

const DialfireConnectorConfigParamsStruct = type({
  campaignToken: string(),
});

const InoplaConnectorConfigParamsStruct = type({
  authKey: string(),
});

const SftpConnectorConfigParamsStruct = type({
  host: string(),
  user: string(),
  password: string(),
  csvPath: string(),
  port: optional(number()),
});

const GenericWebhookConnectorConfigParamsStruct = type({});

export const UpdateConnectorConfigRequestStruct = object({
  connectorConfigId: string(),
  organizationId: string(),
  connectorConfig: union([
    assign(ConnectorConfigBase, InoplaConnectorConfigParamsStruct),
    assign(ConnectorConfigBase, VccLiveConnectorConfigParamsStruct),
    assign(ConnectorConfigBase, DialfireConnectorConfigParamsStruct),
    assign(ConnectorConfigBase, SftpConnectorConfigParamsStruct),
    assign(ConnectorConfigBase, GenericWebhookConnectorConfigParamsStruct),
  ]),
});

export type UpdateConnectorConfigRequest = Infer<
  typeof UpdateConnectorConfigRequestStruct
>;

const UpdateConnectorConfigResponseStruct = type({
  connectorConfigId: string(),
});

export const updateConnectorConfigDefinition = getFirebaseFunctionDefinition({
  path: "/connector-configs/update",
  reqType: UpdateConnectorConfigRequestStruct,
  resType: UpdateConnectorConfigResponseStruct,
});
