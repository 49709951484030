import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TEAM_MODELS = ["DEDICATED", "SHARED"] as const;

export const TeamModelStruct = enums(TEAM_MODELS);

export type TeamModel = Infer<typeof TeamModelStruct>;

export function isTeamModel(obj: unknown): obj is TeamModel {
  return isStruct(obj, TeamModelStruct, "TeamModel");
}
