import { Infer, nullable, omit, partial, record, string } from "superstruct";
import { TenderApplicationStruct } from "../../types/TenderApplication";

export const UpdateTenderProviderApplicationsRequestStruct = record(
  string(),
  nullable(
    partial(
      // We need to strip out all Timestamp fields because they cannot be serialized and deserialized.
      // TODO: use superjson to handle serialization and deserialization of Timestamps.
      omit(TenderApplicationStruct, [
        "documents",
        "createdAt",
        "updatedAt",
        "publishedAt",
        "acceptedAt",
        "rejectedAt",
      ]),
    ),
  ),
);

export type UpdateTenderProviderApplicationsRequest = Infer<
  typeof UpdateTenderProviderApplicationsRequestStruct
>;
