import { Infer, integer, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CloudEventStruct = type({
  /**
   * Concrete event type.
   */
  type: string(),
  /**
   * ISO 8601 date time string.
   */
  time: string(),
  /**
   * Semantic major version of the event.
   */
  version: integer(),
});

/**
 * Common PubSub event type. Loosely based on the CloudEvent spec.
 *
 * @see https://github.com/cloudevents/spec/blob/main/cloudevents/formats/json-format.md
 * @event
 * @interface
 */
export type CloudEvent = Infer<typeof CloudEventStruct>;

export function isCloudEvent(obj: unknown): obj is CloudEvent {
  return isStruct(obj, CloudEventStruct, "CloudEvent");
}
