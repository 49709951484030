import { Describe, string, tuple } from "superstruct";
import { Path } from "../utils/Path";
import {
  FirebaseOrderByDirection,
  FirebaseOrderByDirectionStruct,
} from "./FirebaseOrderByDirection";

export type FirebaseOrderBy<T> = [
  field: Path<T>,
  direction: FirebaseOrderByDirection,
];

export function firebaseOrderBy<T>(): Describe<FirebaseOrderBy<T>> {
  return tuple([
    string() as unknown as Describe<Path<T>>,
    FirebaseOrderByDirectionStruct,
  ]);
}
