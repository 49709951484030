import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TENDER_SUPPORT_DOCUMENTS_TYPE = [
  "WITH_DOCUMENTS",
  "NO_DOCUMENTS",
] as const;

export const TenderSupportDocumentsTypeStruct = enums(
  TENDER_SUPPORT_DOCUMENTS_TYPE,
);

export type TenderSupportDocumentsType = Infer<
  typeof TenderSupportDocumentsTypeStruct
>;

export function isTenderSupportDocumentsType(
  obj: unknown,
): obj is TenderSupportDocumentsType {
  return isStruct(
    obj,
    TenderSupportDocumentsTypeStruct,
    "TenderSupportDocumentsType",
  );
}
