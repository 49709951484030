import { StructError } from "superstruct";

interface Args {
  name: string;
  value: unknown;
  error: StructError;
}

export class TypeError extends Error {
  constructor(args: Args) {
    const valueJson = JSON.stringify(args.value, null, 2);
    super(`Invalid ${args.name}: ${args.error.message}\n${valueJson}`, {
      cause: args.error,
    });
  }
}
