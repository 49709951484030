export const TENDER_APPLICATION_CLIENT_REJECTION_REASONS = [
  "OTHER",
  "PRICE",
  "COMPANY_SIZE",
  "INDUSTRY_KNOWLEDGE",
  "TRANSPARENCY",
  "LOCATION",
  "QUALITY",
  "CAPACITY",
  "FINANCIAL_SITUATION",
  "QUALITY_OF_PROPOSAL",
  "FITNESS_OF_REQUIREMENT",
  "PRICE_PERFORMANCE_RATIO",
  "RESPONSIVENESS",
  "DEADLINE_EXPIRED",
  "NON_COMPETITION_CLAUSE",
] as const;
