import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const PROVIDER_TECHNOLOGY_CATEGORIES = [
  "TECHNOLOGIES",
  "SELF_SERVICES",
  "ADDITIONAL_SERVICES",
] as const;

export const ProviderTechnologyCategoryStruct = enums(
  PROVIDER_TECHNOLOGY_CATEGORIES,
);

export type ProviderTechnologyCategory = Infer<
  typeof ProviderTechnologyCategoryStruct
>;

export function isProviderTechnologyCategory(
  obj: unknown,
): obj is ProviderTechnologyCategory {
  return isStruct(
    obj,
    ProviderTechnologyCategoryStruct,
    "ProviderTechnologyCategory",
  );
}
