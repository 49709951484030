import { Infer, nullable, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const AssignCallRequestStruct = type({
  callId: string(),
  userId: nullable(string()),
});

export type AssignCallRequest = Infer<typeof AssignCallRequestStruct>;

export const assignCallDefinition = getFirebaseFunctionDefinition({
  path: "/calls/assign",
  reqType: AssignCallRequestStruct,
});
