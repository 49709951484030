import { Infer, nullable, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateCallCategoryIdRequestStruct = type({
  callId: string(),
  callCategoryId: nullable(string()),
});

export type UpdateCallCategoryIdRequest = Infer<
  typeof UpdateCallCategoryIdRequestStruct
>;

export const UpdateCallCategoryIdDefinition = getFirebaseFunctionDefinition({
  path: "/call/category-id/update",
  reqType: UpdateCallCategoryIdRequestStruct,
});
