import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const GetProjectLookerUrlRequestStruct = type({
  projectId: string(),
});

export type GetProjectLookerUrlRequest = Infer<
  typeof GetProjectLookerUrlRequestStruct
>;

const GetProjectLookerUrlResponseStruct = type({
  lookerUrl: string(),
});

export const getProjectLookerUrlDefinition = getFirebaseFunctionDefinition({
  path: "/projects/get-looker-url",
  reqType: GetProjectLookerUrlRequestStruct,
  resType: GetProjectLookerUrlResponseStruct,
});
