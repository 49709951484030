import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const PRICE_MODELS = [
  "TRANSACTION",
  "LEAD",
  "CALL",
  "MINUTE",
  "HOUR",
  "MONTH",
] as const;

export const PriceModelStruct = enums(PRICE_MODELS);

export type PriceModel = Infer<typeof PriceModelStruct>;

export function isPriceModel(obj: unknown): obj is PriceModel {
  return isStruct(obj, PriceModelStruct, "PriceModel");
}
