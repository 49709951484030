import { assign, Infer, literal, object, union } from "superstruct";
import { CloudEventStruct } from "../types/CloudEvent";
import { isStruct } from "../utils/isStruct";
import {
  MemberDeletedEventMemberStruct,
  MemberEventMemberStruct,
} from "./MemberEventMember";
import { MemberEventType } from "./MemberEventType";

export const MEMBER_EVENT_VERSION = 1;

export const MemberCreatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("MEMBER_CREATED" satisfies MemberEventType),
    member: MemberEventMemberStruct,
    version: literal(MEMBER_EVENT_VERSION),
  }),
);

/**
 * Member created event.
 * @event
 * @interface
 * @category Member
 */
export type MemberCreatedEvent = Infer<typeof MemberCreatedEventStruct>;

export const MemberUpdatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("MEMBER_UPDATED" satisfies MemberEventType),
    member: MemberEventMemberStruct,
    version: literal(MEMBER_EVENT_VERSION),
  }),
);

/**
 * Member updated event.
 * @event
 * @interface
 * @category Member
 */
export type MemberUpdatedEvent = Infer<typeof MemberUpdatedEventStruct>;

export const MemberDeletedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("MEMBER_DELETED" satisfies MemberEventType),
    member: MemberDeletedEventMemberStruct,
    version: literal(MEMBER_EVENT_VERSION),
  }),
);

/**
 * Member deleted event.
 * @event
 * @interface
 * @category Member
 */
export type MemberDeletedEvent = Infer<typeof MemberDeletedEventStruct>;

export const MemberEventStruct = union([
  MemberCreatedEventStruct,
  MemberUpdatedEventStruct,
  MemberDeletedEventStruct,
]);

/**
 * Common member event. Union type of all member events.
 * @event
 * @category Member
 */
export type MemberEvent = Infer<typeof MemberEventStruct>;

export function isMemberEvent(obj: unknown): obj is MemberEvent {
  return isStruct(obj, MemberEventStruct, "MemberEvent");
}
