import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CALL_TRANSCRIPT_SOURCES = [
  "AWS_TRANSCRIBE",
  "WHISPER",
  "WHISPERX",
] as const;

export const CallTranscriptSourceStruct = enums(CALL_TRANSCRIPT_SOURCES);

/**
 * @category Call
 */
export type CallTranscriptSource = Infer<typeof CallTranscriptSourceStruct>;

export function isCallTranscriptSource(
  obj: unknown,
): obj is CallTranscriptSource {
  return isStruct(obj, CallTranscriptSourceStruct, "CallTranscriptSource");
}
