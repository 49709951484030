import {
  assign,
  Infer,
  nullable,
  omit,
  optional,
  string,
  type,
} from "superstruct";
import { TenderStruct } from "../../types/Tender";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";
import { nullableObject } from "../../utils/nullableObject";
import { UpdateTenderProviderApplicationsRequestStruct } from "../types/UpdateTenderProviderApplicationsRequest";

const UpdatePublishedTenderRequestStruct = type({
  tenderId: string(),
  tender: assign(
    nullableObject(
      omit(TenderStruct, [
        "id",
        "organizationId",
        "organizationName",
        "status",
        "supportDocuments",
        "createdAt",
        "updatedAt",
        "submittedAt",
        "publishedAt",
        "endedAt",
        "isDeleted",
        "deleteAt",
      ]),
    ),
    type({
      providerApplications: optional(
        nullable(UpdateTenderProviderApplicationsRequestStruct),
      ),
    }),
  ),
});

export type UpdatePublishedTenderRequest = Infer<
  typeof UpdatePublishedTenderRequestStruct
>;

export const updatePublishedTenderDefinition = getFirebaseFunctionDefinition({
  path: "/tenders/update-published",
  reqType: UpdatePublishedTenderRequestStruct,
});
