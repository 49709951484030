import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const OPERATING_HOURS_TYPE = [
  "DEFAULT",
  "AROUND_THE_CLOCK",
  "CUSTOM",
] as const;

export const OperatingHoursTypeStruct = enums(OPERATING_HOURS_TYPE);

export type OperatingHoursType = Infer<typeof OperatingHoursTypeStruct>;

export function isOperatingHoursType(obj: unknown): obj is OperatingHoursType {
  return isStruct(obj, OperatingHoursTypeStruct, "OperatingHoursType");
}
