import {
  assign,
  enums,
  Infer,
  literal,
  number,
  object,
  optional,
  string,
  type,
  union,
} from "superstruct";
import { CallTranscriptSourceStruct } from "../types/CallTranscriptSource";
import { CloudEventStruct } from "../types/CloudEvent";
import { isStruct } from "../utils/isStruct";
import { ObservabilityEventType } from "./ObservabilityEventType";

export const OBSERVABILITY_EVENT_VERSION = 1;

const CallEventDataBaseStruct = type({
  /**
   * ID of the user who updated the call.
   */
  userId: string(),
  /**
   * Name of the user who updated the call.
   */
  userName: string(),
  /**
   * ID of the call that was updated.
   */
  callId: string(),
  /**
   * ID of the project that the call belongs to.
   */
  projectId: string(),
});

export const CallTranscriptIsHelpfulEventStruct = assign(
  CloudEventStruct,
  object({
    type: enums([
      "CALL_TRANSCRIPT_IS_HELPFUL_TRUE",
      "CALL_TRANSCRIPT_IS_HELPFUL_FALSE",
    ] satisfies ObservabilityEventType[]),
    data: assign(
      CallEventDataBaseStruct,
      type({
        /**
         * Content of the transcript.
         */
        output: string(),
        transcriptSource: CallTranscriptSourceStruct,
      }),
    ),
    version: literal(OBSERVABILITY_EVENT_VERSION),
  }),
);

/**
 * Call transcript is helpful event.
 * @event
 * @interface
 * @category Observability
 */
export type CallTranscriptIsHelpfulEvent = Infer<
  typeof CallTranscriptIsHelpfulEventStruct
>;

export const CallSummaryIsHelpfulEventStruct = assign(
  CloudEventStruct,
  object({
    type: enums([
      "CALL_SUMMARY_IS_HELPFUL_TRUE",
      "CALL_SUMMARY_IS_HELPFUL_FALSE",
    ] satisfies ObservabilityEventType[]),
    data: assign(
      CallEventDataBaseStruct,
      type({
        /**
         * Content of the call transcript.
         */
        input: string(),
        /**
         * Content of the call summary.
         */
        output: string(),
      }),
    ),
    version: literal(OBSERVABILITY_EVENT_VERSION),
  }),
);

/**
 * Call summary is helpful event.
 * @event
 * @interface
 * @category Observability
 */
export type CallSummaryIsHelpfulEvent = Infer<
  typeof CallSummaryIsHelpfulEventStruct
>;

export const CallQuestionAnswerIsHelpfulEventStruct = assign(
  CloudEventStruct,
  object({
    type: enums([
      "CALL_QUESTION_ANSWER_IS_HELPFUL_TRUE",
      "CALL_QUESTION_ANSWER_IS_HELPFUL_FALSE",
    ] satisfies ObservabilityEventType[]),
    data: assign(
      CallEventDataBaseStruct,
      type({
        /**
         * Text of the call question.
         */
        input: string(),
        /**
         * Text of the call question answer.
         */
        output: string(),
        /**
         * ID of the call question.
         */
        questionId: optional(string()),
        /**
         * ID of the call question answer.
         */
        questionAnswerId: string(),
      }),
    ),
    version: literal(OBSERVABILITY_EVENT_VERSION),
  }),
);

/**
 * Call question answer is helpful event.
 * @event
 * @interface
 * @category Observability
 */
export type CallQuestionAnswerIsHelpfulEvent = Infer<
  typeof CallQuestionAnswerIsHelpfulEventStruct
>;

export const CallScorecardSubmittedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("CALL_SCORECARD_SUBMITTED" satisfies ObservabilityEventType),
    data: assign(
      CallEventDataBaseStruct,
      type({
        /**
         * Total score of the call scorecard.
         */
        totalScore: number(),
      }),
    ),
    version: literal(OBSERVABILITY_EVENT_VERSION),
  }),
);

/**
 * Call scorecard submitted event.
 * @event
 * @interface
 * @category Observability
 */
export type CallScorecardSubmittedEvent = Infer<
  typeof CallScorecardSubmittedEventStruct
>;

export const CallReviewCompletedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("CALL_REVIEW_COMPLETED" satisfies ObservabilityEventType),
    data: CallEventDataBaseStruct,
    version: literal(OBSERVABILITY_EVENT_VERSION),
  }),
);

/**
 * Call review completed event.
 * @event
 * @interface
 * @category Observability
 */
export type CallReviewCompletedEvent = Infer<
  typeof CallReviewCompletedEventStruct
>;

export const ObservabilityEventStruct = union([
  CallTranscriptIsHelpfulEventStruct,
  CallSummaryIsHelpfulEventStruct,
  CallQuestionAnswerIsHelpfulEventStruct,
  CallScorecardSubmittedEventStruct,
  CallReviewCompletedEventStruct,
]);

/**
 * Common event for observability events. Union type of all observability events.
 * @event
 * @category Observability
 */
export type ObservabilityEvent = Infer<typeof ObservabilityEventStruct>;

export function isObservabilityEvent(obj: unknown): obj is ObservabilityEvent {
  return isStruct(obj, ObservabilityEventStruct, "ObservabilityEvent");
}
