import {
  assign,
  Infer,
  omit,
  optional,
  record,
  string,
  type,
} from "superstruct";
import { CallStruct } from "../types/Call";
import { CallIssueStruct } from "../types/CallIssue";
import { CallScorecardStruct } from "../types/CallScorecard";
import { deepReplaceTimestampStructWithStringStruct } from "../utils/deepReplaceTimestampStructWithStringStruct";

export const CallEventCallStruct = deepReplaceTimestampStructWithStringStruct(
  assign(
    omit(CallStruct, [
      "questionAnswers",
      "summary",
      "transcripts",
      "voicefileProcess",
      "transcriptsProcess",
      "summaryProcess",
      "questionAnswersProcess",
    ]),
    // records do not work with deepReplace so we need to reconstruct them manually
    type({
      scorecards: optional(
        record(
          string(),
          deepReplaceTimestampStructWithStringStruct(CallScorecardStruct),
        ),
      ),
      issues: optional(
        record(
          string(),
          deepReplaceTimestampStructWithStringStruct(CallIssueStruct),
        ),
      ),
    }),
  ),
);

/**
 * Call object for update events.
 * @eventProperty
 * @interface
 * @category Call
 * @see {@link Call}
 */
export type CallEventCall = Infer<typeof CallEventCallStruct>;
