import { Infer, nullable, object, Struct } from "superstruct";
import { ObjectSchema, ObjectType } from "superstruct/dist/utils";

type NullableObjectSchema<S extends ObjectSchema> = {
  [K in keyof S]: Struct<Infer<S[K]> | null>;
};

/**
 * Create a new struct based on an existing object struct, but with all of its
 * properties allowed to be `null`.
 */
export function nullableObject<S extends ObjectSchema>(
  struct: Struct<ObjectType<S>, S> | S,
): Struct<ObjectType<NullableObjectSchema<S>>, NullableObjectSchema<S>> {
  const schema: NullableObjectSchema<S> =
    struct instanceof Struct ? { ...struct.schema } : { ...struct };

  for (const key in schema) {
    schema[key] = nullable(schema[key]);
  }

  return object(schema);
}
