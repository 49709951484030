import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const PROVIDER_STATUSES = ["DRAFT", "PUBLISHED"] as const;

export const ProviderStatusStruct = enums(PROVIDER_STATUSES);

export type ProviderStatus = Infer<typeof ProviderStatusStruct>;

export function isProviderStatus(obj: unknown): obj is ProviderStatus {
  return isStruct(obj, ProviderStatusStruct, "ProviderStatus");
}
