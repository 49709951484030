import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TARGET_GROUPS = ["BUSINESS", "PRIVATE"] as const;

export const TargetGroupStruct = enums(TARGET_GROUPS);

export type TargetGroup = Infer<typeof TargetGroupStruct>;

export function isTargetGroup(obj: unknown): obj is TargetGroup {
  return isStruct(obj, TargetGroupStruct, "TargetGroup");
}
