import { Struct, validate } from "superstruct";
import { OctopusTypesConfig } from "../consts/OctopusTypesConfig";
import { TypeError } from "./TypeError";

export function isStruct<T>(
  value: unknown,
  struct: Struct<T>,
  name: string,
): value is T {
  if (value === undefined) return false;
  const [error] = validate(value, struct);

  if (!error) return true;

  OctopusTypesConfig.logError(new TypeError({ name, value, error }));

  return false;
}
