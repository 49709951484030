import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const PROVIDER_CLIENT_FOCUS = ["B2B", "B2C"] as const;

export const ProviderClientFocusStruct = enums(PROVIDER_CLIENT_FOCUS);

export type ProviderClientFocus = Infer<typeof ProviderClientFocusStruct>;

export function isProviderClientFocus(
  obj: unknown,
): obj is ProviderClientFocus {
  return isStruct(obj, ProviderClientFocusStruct, "ProviderClientFocus");
}
