import { Infer, nullable, object, optional, string, type } from "superstruct";
import { ProjectStruct } from "../../types/Project";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateProjectCallFlaggingConfigsRequestStruct = object({
  projectId: string(),
  /**
   * Apply call flagging config to existing calls from this date on.
   */
  checkExistingCallsFromDate: optional(string()),
  project: type({
    callFlaggingConfigs: nullable(ProjectStruct.schema.callFlaggingConfigs),
  }),
});

export type UpdateProjectCallFlaggingConfigsRequest = Infer<
  typeof UpdateProjectCallFlaggingConfigsRequestStruct
>;

export const updateProjectCallFlaggingConfigsDefinition =
  getFirebaseFunctionDefinition({
    path: "/projects/call-flagging-configs/update",
    reqType: UpdateProjectCallFlaggingConfigsRequestStruct,
  });
