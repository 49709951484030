import { array, enums, Infer, number, type, union, unknown } from "superstruct";

const FieldValueDeleteStruct = type({
  kind: enums(["FIELD_VALUE"]),
  type: enums(["DELETE"]),
});

const FieldValueIncrementStruct = type({
  kind: enums(["FIELD_VALUE"]),
  type: enums(["INCREMENT"]),
  value: number(),
});

const FieldValueArrayUnionStruct = type({
  kind: enums(["FIELD_VALUE"]),
  type: enums(["ARRAY_UNION"]),
  values: array(unknown()),
});

const FieldValueArrayRemoveStruct = type({
  kind: enums(["FIELD_VALUE"]),
  type: enums(["ARRAY_REMOVE"]),
  values: array(unknown()),
});

export const FieldValueStruct = union([
  FieldValueDeleteStruct,
  FieldValueIncrementStruct,
  FieldValueArrayUnionStruct,
  FieldValueArrayRemoveStruct,
]);

export type FieldValueDelete = Infer<typeof FieldValueDeleteStruct>;
export type FieldValueIncrement = Infer<typeof FieldValueIncrementStruct>;
export type FieldValueArrayUnion = Infer<typeof FieldValueArrayUnionStruct>;
export type FieldValueArrayRemove = Infer<typeof FieldValueStruct>;
export type FieldValue = Infer<typeof FieldValueStruct>;
