import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const OrganizationEventTypeStruct = enums([
  "ORGANIZATION_CREATED",
  "ORGANIZATION_UPDATED",
  "ORGANIZATION_DELETED",
]);

export type OrganizationEventType = Infer<typeof OrganizationEventTypeStruct>;

export function isOrganizationEventType(
  obj: unknown,
): obj is OrganizationEventType {
  return isStruct(obj, OrganizationEventTypeStruct, "OrganizationEventType");
}
