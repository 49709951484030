import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TASK_STATUSES = ["OPEN", "DONE"] as const;

export const TaskStatusStruct = enums(TASK_STATUSES);

export type TaskStatus = Infer<typeof TaskStatusStruct>;

export function isTaskStatus(obj: unknown): obj is TaskStatus {
  return isStruct(obj, TaskStatusStruct, "TaskStatus");
}
