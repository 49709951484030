import { array, boolean, Infer, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { EmailStruct } from "./Email";
import { LocaleStruct } from "./Locale";
import { TimestampStruct } from "./Timestamp";
import { UserRoleStruct } from "./UserRole";
import { UserTypeStruct } from "./UserType";

export const UserStruct = type({
  id: string(),
  hubspotContactId: optional(string()),
  isHubspotEnabled: optional(boolean()),
  firstName: string(),
  lastName: string(),
  email: optional(EmailStruct),
  userType: optional(UserTypeStruct),
  selectedOrganizationId: optional(string()),
  selectedLocale: optional(LocaleStruct),
  enabledEmails: optional(
    type({
      taskAssigned: optional(boolean()),
      tenderNotification: optional(boolean()),
    }),
  ),
  organizationIds: array(string()),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
  role: UserRoleStruct,
  isDeleted: boolean(),
  deleteAt: optional(TimestampStruct),
});

/**
 * @interface
 * @category User
 */
export type User = Infer<typeof UserStruct>;

export function isUser(obj: unknown): obj is User {
  return isStruct(obj, UserStruct, "User");
}
