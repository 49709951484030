import { Infer, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";

const AppConfigStruct = type({
  minVersion: string(),
});

/**
 * Application configuration.
 * @interface
 * @category App
 */
export type AppConfig = Infer<typeof AppConfigStruct>;

export function isAppConfig(obj: unknown): obj is AppConfig {
  return isStruct(obj, AppConfigStruct, "AppConfig");
}
