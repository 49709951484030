import { enums, Infer } from "superstruct";

export const NotificationEmailSentStatusStruct = enums([
  "PENDING",
  "SENT",
  "DISABLED",
]);

export type NotificationEmailSentStatus = Infer<
  typeof NotificationEmailSentStatusStruct
>;
