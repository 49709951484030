import { Struct, type } from "superstruct";
import { ObjectType } from "superstruct/dist/utils";

/**
 * Helper struct constructor for creating a struct for a record of a given key and value struct.
 * Replacement for `record(key, value)` which does not work with `partial`.
 */
export function recordOf<K extends string, V>(
  key: Struct<K, Record<K, K>>,
  value: Struct<V>,
): Struct<ObjectType<Record<K, Struct<V>>>, Record<K, Struct<V>>> {
  const keys = Object.keys(key.schema) as K[];
  const schema: Record<K, Struct<V>> = keys.reduce(
    (result, key) => ({
      ...result,
      [key]: value,
    }),
    {} as Record<K, Struct<V>>,
  );
  return type(schema);
}

export declare function enums<U extends string, T extends readonly U[]>(
  values: T,
): Struct<
  T[number],
  {
    [K in T[number]]: K;
  }
>;
