import {
  array,
  boolean,
  enums,
  Infer,
  number,
  optional,
  record,
  string,
  type,
} from "superstruct";
import { deprecatedWithLog } from "../utils/deprecatedWithLog";
import { isStruct } from "../utils/isStruct";
import { CallIssueStruct } from "./CallIssue";
import { CallParticipantRoleStruct } from "./CallParticipantRole";
import { CallQuestionAnswerStruct } from "./CallQuestionAnswer";
import { CallScorecardStruct } from "./CallScorecard";
import { CallTranscriptStruct } from "./CallTranscript";
import { CallTranscriptSourceStruct } from "./CallTranscriptSource";
import { ProcessStruct } from "./Process";
import { TimestampStruct } from "./Timestamp";
import { VotesStruct } from "./Votes";

export const CallStruct = type({
  id: string(),
  date: TimestampStruct,
  projectId: string(),
  sourcePhoneNumber: optional(string()),
  destinationPhoneNumber: optional(string()),
  externalCallId: optional(string()),
  startTime: TimestampStruct,
  durationInSeconds: number(),
  /**
   * "queuetime" in Phone Service Hub
   */
  waitingTimeInSeconds: number(),
  holdTimeInSeconds: number(),
  // TODO: make `preWorkTimeInSeconds` a required field
  preWorkTimeInSeconds: optional(number()),
  // TODO: make `postWorkTimeInSeconds` a required field
  postWorkTimeInSeconds: optional(number()),
  // TODO: make `totalTimeInSeconds` a required field
  totalTimeInSeconds: optional(number()),
  // TODO: make `direction` a required field
  direction: optional(enums(["INBOUND", "OUTBOUND"])),
  /**
   * Full username of the Phone Service agent.
   */
  agentName: optional(string()),
  /**
   * Full name of the Snubes reviewer user.
   */
  reviewerName: optional(string()),
  reviewerUserId: optional(string()),
  assigneeName: optional(string()),
  assigneeUserId: optional(string()),
  /**
   * Indicates whether the voicefile is processable.
   */
  hasVoicefile: optional(boolean()),
  /**
   * Indicates whether the voicefile has been processed.
   */
  isVoicefileAvailable: optional(boolean()),
  transcripts: optional(array(CallTranscriptStruct)),
  // TODO: make `transcriptSource` a required field
  /**
   * @deprecated Use `transcripts[number].transcriptSource` instead.
   */
  transcriptSource: deprecatedWithLog(
    optional(CallTranscriptSourceStruct),
    "Use transcripts[number].transcriptSource instead.",
  ),
  transcriptLanguageCode: optional(string()),
  sentiment: optional(record(CallParticipantRoleStruct, optional(number()))),
  scorecards: optional(record(string(), CallScorecardStruct)),
  issues: optional(record(string(), CallIssueStruct)),
  hasIssues: optional(boolean()),
  summary: optional(string()),
  categoryId: optional(string()),
  questionAnswers: optional(array(CallQuestionAnswerStruct)),
  /**
   * Record<UserId, boolean>>
   */
  isSummaryHelpful: optional(VotesStruct),
  /**
   * Record<UserId, boolean>>
   */
  isTranscriptHelpful: optional(VotesStruct),
  /**
   * Record<QuestionAnswerId, Record<UserId, boolean>>
   */
  isQuestionAnswerHelpful: optional(record(string(), VotesStruct)),
  transcriptUpdatedAt: optional(TimestampStruct),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
  reviewCompletedAt: optional(TimestampStruct),
  voicefileProcess: optional(ProcessStruct),
  transcriptsProcess: optional(ProcessStruct),
  summaryProcess: optional(ProcessStruct),
  categoryProcess: optional(ProcessStruct),
  questionAnswersProcess: optional(ProcessStruct),
});

/**
 * Call object.
 * @interface
 * @category Call
 */
export type Call = Infer<typeof CallStruct>;

export function isCall(obj: unknown): obj is Call {
  return isStruct(obj, CallStruct, "Call");
}
