import { enums, Infer, is } from "superstruct";

export const ERROR_CODES = [
  "auth/permission-denied",
  "auth/invalid-email",
  "organization/exists",
] as const;

export const ErrorCodeStruct = enums(ERROR_CODES);

export type ErrorCode = Infer<typeof ErrorCodeStruct>;

export function isErrorCode(obj: unknown): obj is ErrorCode {
  return is(obj, ErrorCodeStruct);
}
