import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const PROCESS_STATUSES = [
  "ON_HOLD",
  "PROCESSING",
  "FAILED",
  "SUCCEEDED",
  "REQUESTED",
] as const;

export const ProcessStatusStruct = enums(PROCESS_STATUSES);

export type ProcessStatus = Infer<typeof ProcessStatusStruct>;

export function isProcessStatus(obj: unknown): obj is ProcessStatus {
  return isStruct(obj, ProcessStatusStruct, "ProcessStatus");
}
