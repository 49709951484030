import { Infer, object, string } from "superstruct";
import { OrganizationStruct } from "../../types/Organization";

export const CreateOrganizationRequestStruct = object({
  organization: object({
    name: OrganizationStruct.schema.name,
    domain: string(),
    email: OrganizationStruct.schema.email,
    phone: OrganizationStruct.schema.phone,
    street: OrganizationStruct.schema.street,
    city: OrganizationStruct.schema.city,
    zipCode: OrganizationStruct.schema.zipCode,
    description: OrganizationStruct.schema.description,
    numberOfEmployees: OrganizationStruct.schema.numberOfEmployees,
    foundedInYear: OrganizationStruct.schema.foundedInYear,
    annualRevenue: OrganizationStruct.schema.annualRevenue,
    industry: OrganizationStruct.schema.industry,
    clientFocus: OrganizationStruct.schema.clientFocus,
    website: OrganizationStruct.schema.website,
    socialMediaFacebook: OrganizationStruct.schema.socialMediaFacebook,
    socialMediaTwitter: OrganizationStruct.schema.socialMediaTwitter,
    socialMediaLinkedIn: OrganizationStruct.schema.socialMediaLinkedIn,
    socialMediaInstagram: OrganizationStruct.schema.socialMediaInstagram,
    socialMediaYoutube: OrganizationStruct.schema.socialMediaYoutube,
    isHubspotEnabled: OrganizationStruct.schema.isHubspotEnabled,
    hubspotCompanyId: OrganizationStruct.schema.hubspotCompanyId,
  }),
});

export type CreateOrganizationRequest = Infer<
  typeof CreateOrganizationRequestStruct
>;
