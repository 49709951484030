import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const RemoveMemberRequestStruct = type({
  organizationId: string(),
  memberId: string(),
});

export type RemoveMemberRequest = Infer<typeof RemoveMemberRequestStruct>;

const RemoveMemberResponseStruct = type({
  organizationId: string(),
  memberId: string(),
});

export const removeMemberDefinition = getFirebaseFunctionDefinition({
  path: "/members/remove",
  reqType: RemoveMemberRequestStruct,
  resType: RemoveMemberResponseStruct,
});
