import { Infer, object, string } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteOrganizationLogoRequestStruct = object({
  organizationId: string(),
  fileHash: string(),
});

export type DeleteOrganizationLogoRequest = Infer<
  typeof DeleteOrganizationLogoRequestStruct
>;

export const deleteOrganizationLogoDefinition = getFirebaseFunctionDefinition({
  path: "/organizations/delete-logo",
  reqType: DeleteOrganizationLogoRequestStruct,
});
