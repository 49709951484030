import {
  array,
  assign,
  deprecated,
  Infer,
  literal,
  object,
  optional,
  string,
  union,
} from "superstruct";
import { CallProcessRequestKindStruct } from "../types/CallProcessRequestKind";
import { CloudEventStruct } from "../types/CloudEvent";
import { isStruct } from "../utils/isStruct";
import { CallEventCallStruct } from "./CallEventCall";
import { CallEventType } from "./CallEventType";

export const CALL_EVENT_VERSION = 1;

export const CallUpdatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("CALL_UPDATED" satisfies CallEventType),
    /**
     * @see {@link CallEventCall}
     */
    call: CallEventCallStruct,
    version: literal(CALL_EVENT_VERSION),
  }),
);

/**
 * Call updated event.
 * @event
 * @interface
 * @category Call
 */
export type CallUpdatedEvent = Infer<typeof CallUpdatedEventStruct>;

export const CallProcessRequestedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("CALL_PROCESS_REQUESTED" satisfies CallEventType),
    /**
     * @deprecated Use `kind` instead.
     */
    kinds: deprecated(array(CallProcessRequestKindStruct), () =>
      console.warn("CallProcessRequestedEvent.kinds is deprecated"),
    ),
    kind: CallProcessRequestKindStruct,
    /**
     * For `kind == "TRANSCRIPTS"` only.
     */
    language: optional(string()),
    callId: string(),
    projectId: string(),
    version: literal(CALL_EVENT_VERSION),
  }),
);

/**
 * Call process requested event.
 * @event
 * @interface
 * @category Call
 */
export type CallProcessRequestedEvent = Infer<
  typeof CallProcessRequestedEventStruct
>;

export const CallEventStruct = union([
  CallUpdatedEventStruct,
  CallProcessRequestedEventStruct,
]);

/**
 * Common call event. Union type of all call events.
 * @event
 * @category Call
 */
export type CallEvent = Infer<typeof CallEventStruct>;

export function isCallEvent(obj: unknown): obj is CallEvent {
  return isStruct(obj, CallEventStruct, "CallEvent");
}
