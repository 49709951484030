import { optional, string, type } from "superstruct";
import { TenderApplicationRejectionReasonStruct } from "../../types/TenderApplicationRejectionReason";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const EndTenderRequestStruct = type({
  tenderId: string(),
  rejectionReason: optional(TenderApplicationRejectionReasonStruct),
});

export const endTenderDefinition = getFirebaseFunctionDefinition({
  path: "/tenders/end",
  reqType: EndTenderRequestStruct,
});
