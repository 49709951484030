import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const CompleteCallReviewRequestStruct = type({
  callId: string(),
});

export type CompleteCallReviewRequest = Infer<
  typeof CompleteCallReviewRequestStruct
>;

export const completeCallReviewDefinition = getFirebaseFunctionDefinition({
  path: "/calls/complete-review",
  reqType: CompleteCallReviewRequestStruct,
});
