import { boolean, Infer, record, string } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const VotesStruct = record(string(), boolean());

export type Votes = Infer<typeof VotesStruct>;

export function isVotes(obj: unknown): obj is Votes {
  return isStruct(obj, VotesStruct, "Votes");
}
