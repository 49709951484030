import { array, Infer, number, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { ChannelTypeStruct } from "./ChannelType";
import { OperatingHoursTypeStruct } from "./OperatingHoursType";
import { PriceModelStruct } from "./PriceModel";
import { TargetGroupStruct } from "./TargetGroup";
import { TargetGroupAudienceStruct } from "./TargetGroupAudience";
import { TeamModelStruct } from "./TeamModel";
import { WeekdayPeriodStruct } from "./WeekdayPeriod";

export const ChannelStruct = type({
  type: ChannelTypeStruct,
  teamModel: optional(TeamModelStruct),
  priceModels: optional(array(PriceModelStruct)),
  volume: optional(number()),
  languages: optional(array(string())),
  avgHandlingTimeInSeconds: optional(number()),
  targetGroups: optional(array(TargetGroupStruct)),
  targetGroupAudiences: optional(array(TargetGroupAudienceStruct)),
  operatingHoursType: optional(OperatingHoursTypeStruct),
  operatingHours: optional(array(WeekdayPeriodStruct)),
  operatingTimeZone: optional(string()),
});

/**
 * @interface
 * @category Tender
 */
export type Channel = Infer<typeof ChannelStruct>;

export function isChannel(obj: unknown): obj is Channel {
  return isStruct(obj, ChannelStruct, "Channel");
}
