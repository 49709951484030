import { Infer, pick } from "superstruct";
import { OrganizationStruct } from "../types/Organization";
import { deepReplaceTimestampStructWithStringStruct } from "../utils/deepReplaceTimestampStructWithStringStruct";

export const OrganizationEventOrganizationStruct =
  deepReplaceTimestampStructWithStringStruct(OrganizationStruct);

/**
 * Organization object for create & update events.
 * @eventProperty
 * @interface
 * @category Organization
 */
export type OrganizationEventOrganization = Infer<
  typeof OrganizationEventOrganizationStruct
>;

export const OrganizationDeletedEventOrganizationStruct = pick(
  OrganizationStruct,
  ["id", "hubspotCompanyId", "isHubspotEnabled"],
);

/**
 * Organization object for delete events.
 * @eventProperty
 * @interface
 * @category Organization
 */
export type OrganizationDeletedEventOrganization = Infer<
  typeof OrganizationDeletedEventOrganizationStruct
>;
