import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteUserRequestStruct = type({
  userId: string(),
});

export type DeleteUserRequest = Infer<typeof DeleteUserRequestStruct>;

export const deleteUserDefinition = getFirebaseFunctionDefinition({
  path: "/users/delete",
  reqType: DeleteUserRequestStruct,
});
