import { enums, Infer } from "superstruct";

export const CONNECTOR_STATUS_LOG_STATUS = [
  "INFO",
  "SUCCESS",
  "ERROR",
] as const;

export const ConnectorStatusLogStatusStruct = enums(
  CONNECTOR_STATUS_LOG_STATUS,
);

export type ConnectorStatusLogStatus = Infer<
  typeof ConnectorStatusLogStatusStruct
>;
