import { Infer, nullable, object, string } from "superstruct";
import { ProjectStruct } from "../../types/Project";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateProjectIssueCategoriesRequestStruct = object({
  projectId: string(),
  issueCategories: nullable(ProjectStruct.schema.issueCategories),
});

export type UpdateProjectIssueCategoriesRequest = Infer<
  typeof UpdateProjectIssueCategoriesRequestStruct
>;

export const updateProjectIssueCategoriesDefinition =
  getFirebaseFunctionDefinition({
    path: "/projects/issue-categories/update",
    reqType: UpdateProjectIssueCategoriesRequestStruct,
  });
