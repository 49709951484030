import { Infer, string, type } from "superstruct";
import { ProjectStruct } from "../../types/Project";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const CreateProjectRequestStruct = type({
  name: ProjectStruct.schema.name,
  providerOrganizationId: ProjectStruct.schema.providerOrganizationId,
  clientOrganizationId: ProjectStruct.schema.clientOrganizationId,
  projectManagerUserId: ProjectStruct.schema.projectManagerUserId,
  tier: ProjectStruct.schema.tier,
  isLookerAnalyticsEnabled: ProjectStruct.schema.isLookerAnalyticsEnabled,
  voicefileLanguage: ProjectStruct.schema.voicefileLanguage,
  responseLanguage: ProjectStruct.schema.responseLanguage,
});

export type CreateProjectRequest = Infer<typeof CreateProjectRequestStruct>;

const CreateProjectResponseStruct = type({
  id: string(),
});

export const createProjectDefinition = getFirebaseFunctionDefinition({
  path: "/projects/create",
  reqType: CreateProjectRequestStruct,
  resType: CreateProjectResponseStruct,
});
