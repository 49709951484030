import { enums, Infer } from "superstruct";

export const CONNECTOR_CONFIG_STATUSES = [
  "INITIAL",
  "RUNNING",
  "INTERRUPTED",
] as const;

export const ConnectorConfigStatusStruct = enums(CONNECTOR_CONFIG_STATUSES);

export type ConnectorConfigStatus = Infer<typeof ConnectorConfigStatusStruct>;
