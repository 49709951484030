import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const MemberEventTypeStruct = enums([
  "MEMBER_CREATED",
  "MEMBER_UPDATED",
  "MEMBER_DELETED",
]);

export type MemberEventType = Infer<typeof MemberEventTypeStruct>;

export function isMemberEventType(obj: unknown): obj is MemberEventType {
  return isStruct(obj, MemberEventTypeStruct, "MemberEventType");
}
