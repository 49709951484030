import { Infer, optional, string, type } from "superstruct";
import { CallIssueStruct } from "../../types/CallIssue";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const CreateCallIssueRequestStruct = type({
  callId: string(),
  callIssue: type({
    name: CallIssueStruct.schema.name,
    issueCategoryId: optional(string()),
  }),
});

export type CreateCallIssueRequest = Infer<typeof CreateCallIssueRequestStruct>;

export const createCallIssueDefinition = getFirebaseFunctionDefinition({
  path: "/calls/issues/create",
  reqType: CreateCallIssueRequestStruct,
});
