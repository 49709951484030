import { Infer, nullable, optional, record, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { StorageFileStruct } from "./StorageFile";
import { TenderApplicationRejectionReasonStruct } from "./TenderApplicationRejectionReason";
import { TenderApplicationStatusStruct } from "./TenderApplicationStatus";
import { TimestampStruct } from "./Timestamp";
import { UserTypeStruct } from "./UserType";

export const TenderApplicationStruct = type({
  providerId: string(),
  status: TenderApplicationStatusStruct,
  description: optional(string()),
  documents: optional(record(string(), StorageFileStruct)),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
  publishedAt: optional(TimestampStruct),
  acceptedAt: optional(TimestampStruct),
  rejectedAt: optional(TimestampStruct),
  rejectedByUserId: optional(string()),
  rejectedByUserName: optional(string()),
  rejectedByUserType: optional(nullable(UserTypeStruct)),
  rejectionReason: optional(TenderApplicationRejectionReasonStruct),
});

/**
 * @interface
 * @category Tender
 */
export type TenderApplication = Infer<typeof TenderApplicationStruct>;

export function isTenderApplication(obj: unknown): obj is TenderApplication {
  return isStruct(obj, TenderApplicationStruct, "TenderApplication");
}
