import { array, assign, Infer, object, optional, type } from "superstruct";
import {
  ConnectorConfigBaseStruct,
  SftpConnectorConfigParamsStruct,
} from "../../types/ConnectorConfig";
import { ExternalProjectStruct } from "../../types/ExternalProject";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const TestConnectorConfigSftpRequestStruct = assign(
  object({
    organizationId: ConnectorConfigBaseStruct.schema.organizationId,
    connectorConfigId: optional(ConnectorConfigBaseStruct.schema.id),
  }),
  SftpConnectorConfigParamsStruct,
);

const TestConnectorConfigSftpResponseStruct = type({
  externalProjects: array(ExternalProjectStruct),
});

export type TestConnectorConfigSftpRequest = Infer<
  typeof TestConnectorConfigSftpRequestStruct
>;

export type TestConnectorConfigSftpResponse = Infer<
  typeof TestConnectorConfigSftpResponseStruct
>;

export const testConnectorConfigSftpDefinition = getFirebaseFunctionDefinition({
  path: "/connector-configs/test-sftp",
  reqType: TestConnectorConfigSftpRequestStruct,
  resType: TestConnectorConfigSftpResponseStruct,
});
