import { array, Infer, nullable, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { CallFlaggingConfigConditionStruct } from "./CallFlaggingConfigCondition";
import { CallFlaggingConfigParameterStruct } from "./CallFlaggingConfigParameter";

export const CallFlaggingConfigStruct = type({
  id: string(),
  condition: CallFlaggingConfigConditionStruct,
  parameters: array(CallFlaggingConfigParameterStruct),
  reviewerUserId: optional(nullable(string())),
  issueCategoryId: optional(nullable(string())),
});

/**
 * @interface
 * @category Project
 */
export type CallFlaggingConfig = Infer<typeof CallFlaggingConfigStruct>;

export function isCallFlaggingConfig(obj: unknown): obj is CallFlaggingConfig {
  return isStruct(obj, CallFlaggingConfigStruct, "CallFlaggingConfig");
}
