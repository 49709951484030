import { assign, Infer, literal, object, union } from "superstruct";
import { CloudEventStruct } from "../types/CloudEvent";
import { isStruct } from "../utils/isStruct";
import { UserEventType } from "./UserEventType";
import {
  UserDeletedEventUserStruct,
  UserEventUserStruct,
} from "./UserEventUser";

export const USER_EVENT_VERSION = 1;

export const UserCreatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("USER_CREATED" satisfies UserEventType),
    user: UserEventUserStruct,
    version: literal(USER_EVENT_VERSION),
  }),
);

/**
 * User created event.
 * @event
 * @interface
 * @category User
 */
export type UserCreatedEvent = Infer<typeof UserCreatedEventStruct>;

export const UserUpdatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("USER_UPDATED" satisfies UserEventType),
    user: UserEventUserStruct,
    version: literal(USER_EVENT_VERSION),
  }),
);

/**
 * User updated event.
 * @event
 * @interface
 * @category User
 */
export type UserUpdatedEvent = Infer<typeof UserUpdatedEventStruct>;

export const UserDeletedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("USER_DELETED" satisfies UserEventType),
    user: UserDeletedEventUserStruct,
    version: literal(USER_EVENT_VERSION),
  }),
);

/**
 * User deleted event.
 * @event
 * @interface
 * @category User
 */
export type UserDeletedEvent = Infer<typeof UserDeletedEventStruct>;

export const UserEventStruct = union([
  UserCreatedEventStruct,
  UserUpdatedEventStruct,
  UserDeletedEventStruct,
]);

/**
 * Common user event. Union type of all user events.
 * @event
 * @category User
 */
export type UserEvent = Infer<typeof UserEventStruct>;

export function isUserEvent(obj: unknown): obj is UserEvent {
  return isStruct(obj, UserEventStruct, "UserEvent");
}
