import {
  assign,
  Infer,
  nullable,
  object,
  omit,
  optional,
  string,
  type,
} from "superstruct";
import { ProviderStruct } from "../../types/Provider";
import { nullableObject } from "../../utils/nullableObject";

export const UpdateProviderRequestStruct = object({
  providerId: string(),
  provider: assign(
    nullableObject(
      omit(ProviderStruct, [
        "id",
        "organizationId",
        "organizationName",
        "organizationMembersCount",
        "hasOrganizationMembers",
        "status",
        "createdAt",
        "updatedAt",
        "isDeleted",
        "pricingModels", // deprecated
        "revenueRangeCurrency", // deprecated
        "revenueRangeYearly", // deprecated
      ]),
    ),
    type({
      organizationName: optional(nullable(string())),
    }),
  ),
});

export type UpdateProviderRequest = Infer<typeof UpdateProviderRequestStruct>;
