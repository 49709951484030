import { string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";
import { CreateTenderRequestStruct } from "../types/CreateTenderRequest";

const CreateTenderResponseStruct = type({
  tenderId: string(),
});

export const createTenderDefinition = getFirebaseFunctionDefinition({
  path: "/tenders/create",
  reqType: CreateTenderRequestStruct,
  resType: CreateTenderResponseStruct,
});
