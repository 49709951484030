import { Infer, record, string, Struct, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { MemberRole } from "./MemberRole";
import { UserRole } from "./UserRole";

const UserClaimsStruct = type({
  userRole: string() as Struct<UserRole>,
  memberRoles: record(string(), string() as Struct<MemberRole>),
});

/**
 * @interface
 * @category User
 */
export type UserClaims = Infer<typeof UserClaimsStruct>;

export function isUserClaims(obj: unknown): obj is UserClaims {
  return isStruct(obj, UserClaimsStruct, "UserClaims");
}
