import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TenderEventTypeStruct = enums([
  "TENDER_CREATED",
  "TENDER_UPDATED",
  "TENDER_DELETED",
]);

export type TenderEventType = Infer<typeof TenderEventTypeStruct>;

export function isTenderEventType(obj: unknown): obj is TenderEventType {
  return isStruct(obj, TenderEventTypeStruct, "TenderEventType");
}
