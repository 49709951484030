import { Infer, object, string, type } from "superstruct";
import { CallScorecardStruct } from "../../types/CallScorecard";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const CreateCallScorecardRequestStruct = type({
  callId: string(),
  callScorecard: object({
    items: CallScorecardStruct.schema.items,
  }),
});

export type CreateCallScorecardRequest = Infer<
  typeof CreateCallScorecardRequestStruct
>;

export const createCallScorecardDefinition = getFirebaseFunctionDefinition({
  path: "/calls/scorecards/create",
  reqType: CreateCallScorecardRequestStruct,
});
