import { ErrorCode } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ErrorCodeRecordValue {
  message: TranslationKey;
}

export const ERROR_CODE_RECORD: Record<ErrorCode, ErrorCodeRecordValue> = {
  "auth/permission-denied": {
    message: "ErrorCodeRecord_Message_PermissionDenied",
  },
  "auth/invalid-email": {
    message: "ErrorCodeRecord_Message_InvalidEmail",
  },
  "organization/exists": {
    message: "ErrorCodeRecord_Message_OrganizationExists",
  },
};
