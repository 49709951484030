import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TECHNOLOGIES = [
  "SKILL_BASED_ROUTING",
  "CUSTOMER_INTERACTION_ANALYTICS",
  "AUTOMATIC_CALL_DISTRIBUTOR",
  "VOICE_AND_SCREEN_RECORDING",
  "VOICE_BIOMETRICS",
  "REPORTING_TOOLS",
  "DIALER",
  "WORKFORCE_MANAGEMENT_SOFTWARE",
  "CUSTOMER_RELATIONSHIP_MANAGEMENT",
] as const;

export const SELF_SERVICES = [
  "CUSTOMER_FEEDBACK_TOOLS",
  "INTERACTIVE_VOICE_RESPONSE",
  "INTERACTIVE_VIDEO",
  "CALL-BACK",
  "INTELLIGENT_QUEUING",
] as const;

export const ADDITIONAL_SERVICES = [
  "DYNAMIC_FAQ",
  "VOICE_BOTS",
  "CHAT_BOTS",
] as const;

export const PROVIDER_TECHNOLOGIES = [
  ...TECHNOLOGIES,
  ...SELF_SERVICES,
  ...ADDITIONAL_SERVICES,
];

export const ProviderTechnologyStruct = enums(PROVIDER_TECHNOLOGIES);

export type ProviderTechnology = Infer<typeof ProviderTechnologyStruct>;

export function isProviderTechnology(obj: unknown): obj is ProviderTechnology {
  return isStruct(obj, ProviderTechnologyStruct, "ProviderTechnology");
}
