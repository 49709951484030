import {
  boolean,
  Infer,
  integer,
  nullable,
  optional,
  string,
  type,
} from "superstruct";
import { ProjectStruct } from "../../types/Project";
import { ProjectTierStruct } from "../../types/ProjectTier";
import { deprecatedWithLog } from "../../utils/deprecatedWithLog";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateProjectAllRequestStruct = type({
  projectId: string(),
  project: type({
    name: optional(string()),
    clientOrganizationId: nullable(string()),
    projectManagerUserId: nullable(string()),
    tier: optional(ProjectTierStruct),
    /**
     * @deprecated UpdateProjectAllRequest:questions is deprecated, use updateProjectQuestions instead.
     */
    questions: deprecatedWithLog(
      nullable(ProjectStruct.schema.questions),
      "UpdateProjectAllRequest:questions is deprecated, use updateProjectQuestions instead.",
    ),
    /**
     * @deprecated UpdateProjectAllRequest:scorecardQuestions is deprecated, use updateProjectScorecardQuestions instead.
     */
    scorecardQuestions: deprecatedWithLog(
      nullable(ProjectStruct.schema.scorecardQuestions),
      "UpdateProjectAllRequest:scorecardQuestions is deprecated, use updateProjectScorecardQuestions instead.",
    ),
    /**
     * @deprecated UpdateProjectAllRequest:callCategories is deprecated, use updateProjectCallCategories instead.
     */
    callCategories: deprecatedWithLog(
      nullable(ProjectStruct.schema.callCategories),
      "UpdateProjectAllRequest:callCategories is deprecated, use updateProjectCallCategories instead.",
    ),
    /**
     * @deprecated UpdateProjectAllRequest:issueCategories is deprecated, use updateProjectIssueCategories instead.
     */
    issueCategories: deprecatedWithLog(
      nullable(ProjectStruct.schema.issueCategories),
      "UpdateProjectAllRequest:issueCategories is deprecated, use updateProjectIssueCategories instead.",
    ),
    /**
     * @deprecated UpdateProjectAllRequest:callFlaggingConfigs is deprecated, use updateProjectCallFlaggingConfigs instead.
     */
    callFlaggingConfigs: deprecatedWithLog(
      nullable(ProjectStruct.schema.callFlaggingConfigs),
      "UpdateProjectAllRequest:callFlaggingConfigs is deprecated, use updateProjectCallFlaggingConfigs instead.",
    ),
    isLookerAnalyticsEnabled: boolean(),
    voicefileLanguage: nullable(ProjectStruct.schema.voicefileLanguage),
    responseLanguage: nullable(ProjectStruct.schema.responseLanguage),
    callSamplingRate: optional(integer()),
  }),
});

export type UpdateProjectAllRequest = Infer<
  typeof UpdateProjectAllRequestStruct
>;

export const updateProjectAllDefinition = getFirebaseFunctionDefinition({
  path: "/projects/update-all",
  reqType: UpdateProjectAllRequestStruct,
});
