import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TIMETABLE_TYPES = ["CONTINUOUS", "FINITE"] as const;

export const TimetableTypeStruct = enums(TIMETABLE_TYPES);

export type TimetableType = Infer<typeof TimetableTypeStruct>;

export function isTimetableType(obj: unknown): obj is TimetableType {
  return isStruct(obj, TimetableTypeStruct, "TimetableType");
}
