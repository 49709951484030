import { Infer, string, type, union } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateUserAuthRequestStruct = union([
  type({
    userId: string(),
    password: string(),
  }),
  type({
    userId: string(),
    email: string(),
  }),
]);

export type UpdateUserAuthRequest = Infer<typeof UpdateUserAuthRequestStruct>;

export const updateUserAuthDefinition = getFirebaseFunctionDefinition({
  path: "/users/update-auth",
  reqType: UpdateUserAuthRequestStruct,
});
