import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const USER_TYPES = ["ADMIN", "CLIENT", "PROVIDER", "QM"] as const;

export const UserTypeStruct = enums(USER_TYPES);

export type UserType = Infer<typeof UserTypeStruct>;

export function isUserType(obj: unknown): obj is UserType {
  return isStruct(obj, UserTypeStruct, "UserType");
}
