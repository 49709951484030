import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const INBOUND_PRICING_MODELS = [
  "PER_CALL",
  "PER_MINUTE",
  "PER_HOUR",
  "PER_MONTH",
  "PER_TRANSACTION",
  "PER_EMAIL",
  "PER_CHAT",
] as const;

export const OUTBOUND_PRICING_MODELS = [
  "PER_CALL",
  "PER_MINUTE",
  "PER_HOUR",
  "PER_MONTH",
  "PER_TRANSACTION",
  "PER_EMAIL",
  "PER_LEAD",
  "PER_ORDER",
  "PER_PROJECT",
  "PER_NET_CONTACT",
] as const;

export const BACK_OFFICE_PRICING_MODELS = [
  "PER_MINUTE",
  "PER_HOUR",
  "PER_MONTH",
  "PER_MAIL",
  "PER_FAX",
] as const;

export const PROVIDER_PRICING_MODELS = [
  ...new Set([
    ...INBOUND_PRICING_MODELS,
    ...OUTBOUND_PRICING_MODELS,
    ...BACK_OFFICE_PRICING_MODELS,
  ]),
] as const;

export const ProviderPricingModelStruct = enums(PROVIDER_PRICING_MODELS);

export type ProviderPricingModel = Infer<typeof ProviderPricingModelStruct>;

export function isProviderPricingModel(
  obj: unknown,
): obj is ProviderPricingModel {
  return isStruct(obj, ProviderPricingModelStruct, "ProviderPricingModel");
}
