import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CONNECTOR_PLATFORMS = [
  "DIALFIRE",
  "INOPLA",
  "VCC_LIVE",
  "SFTP",
  "GENERIC_WEBHOOK",
] as const;

export const ConnectorPlatformStruct = enums(CONNECTOR_PLATFORMS);

export type ConnectorPlatform = Infer<typeof ConnectorPlatformStruct>;

export function isConnectorPlatform(obj: unknown): obj is ConnectorPlatform {
  return isStruct(obj, ConnectorPlatformStruct, "ConnectorPlatform");
}
