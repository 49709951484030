import {
  assign,
  Infer,
  literal,
  number,
  optional,
  string,
  type,
  union,
} from "superstruct";
import {
  EmailTemplateType,
  EmailTemplateTypeStruct,
} from "./EmailTemplateType";

export const EmailRecipientStruct = type({
  email: string(),
  name: optional(string()),
});

/**
 * @interface
 * @category Email
 */
export type EmailRecipient = Infer<typeof EmailRecipientStruct>;

export const EmailTemplateBaseStruct = type({
  templateType: EmailTemplateTypeStruct,
  recipient: EmailRecipientStruct,
  subject: string(),
  templateId: string(),
});

export const InvitationEmailTemplateStruct = assign(
  EmailTemplateBaseStruct,
  type({
    templateType: literal("INVITATION" satisfies EmailTemplateType),
    templateProperties: type({
      organizationName: string(),
    }),
  }),
);

/**
 * @interface
 * @category Email
 */
export type InvitationEmailTemplate = Infer<
  typeof InvitationEmailTemplateStruct
>;

export const NotificationEmailTemplateStruct = assign(
  EmailTemplateBaseStruct,
  type({
    templateType: literal("NOTIFICATION" satisfies EmailTemplateType),
    templateProperties: type({
      title: string(),
      subTitle: string(),
      bodyText: string(),
      buttonUrl: string(),
      buttonText: string(),
    }),
  }),
);

/**
 * @interface
 * @category Email
 */
export type NotificationEmailTemplate = Infer<
  typeof NotificationEmailTemplateStruct
>;

export const TasksNotificationEmailTemplateStruct = assign(
  EmailTemplateBaseStruct,
  type({
    templateType: literal("NOTIFICATION_SUMMARY" satisfies EmailTemplateType),
    templateProperties: type({
      tasksCount: number(),
      userName: string(),
      timePeriod: string(),
      dateRange: string(),
    }),
  }),
);

/**
 * @interface
 * @category Email
 */
export type TasksNotificationEmailTemplate = Infer<
  typeof TasksNotificationEmailTemplateStruct
>;

export const EmailTemplateStruct = union([
  InvitationEmailTemplateStruct,
  NotificationEmailTemplateStruct,
  TasksNotificationEmailTemplateStruct,
]);

/**
 * @category Email
 */
export type EmailTemplate = Infer<typeof EmailTemplateStruct>;
