import { assign, Infer, literal, object, union } from "superstruct";
import { CloudEventStruct } from "../types/CloudEvent";
import { isStruct } from "../utils/isStruct";
import {
  ProjectDeletedEventProjectStruct,
  ProjectEventProjectStruct,
} from "./ProjectEventProject";
import { ProjectEventType } from "./ProjectEventType";

export const PROJECT_EVENT_VERSION = 1;

export const ProjectCreatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("PROJECT_CREATED" satisfies ProjectEventType),
    project: ProjectEventProjectStruct,
    version: literal(PROJECT_EVENT_VERSION),
  }),
);

/**
 * Project created event.
 * @event
 * @interface
 * @category Project
 */
export type ProjectCreatedEvent = Infer<typeof ProjectCreatedEventStruct>;

export const ProjectUpdatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("PROJECT_UPDATED" satisfies ProjectEventType),
    project: ProjectEventProjectStruct,
    version: literal(PROJECT_EVENT_VERSION),
  }),
);

/**
 * Project updated event.
 * @event
 * @interface
 * @category Project
 */
export type ProjectUpdatedEvent = Infer<typeof ProjectUpdatedEventStruct>;

export const ProjectDeletedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("PROJECT_DELETED" satisfies ProjectEventType),
    project: ProjectDeletedEventProjectStruct,
    version: literal(PROJECT_EVENT_VERSION),
  }),
);

/**
 * Project deleted event.
 * @event
 * @interface
 * @category Project
 */
export type ProjectDeletedEvent = Infer<typeof ProjectDeletedEventStruct>;

export const ProjectEventStruct = union([
  ProjectCreatedEventStruct,
  ProjectUpdatedEventStruct,
  ProjectDeletedEventStruct,
]);

/**
 * Common project event. Union type of all project events.
 * @event
 * @category Project
 */
export type ProjectEvent = Infer<typeof ProjectEventStruct>;

export function isProjectEvent(obj: unknown): obj is ProjectEvent {
  return isStruct(obj, ProjectEventStruct, "ProjectEvent");
}
