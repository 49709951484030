import {
  assign,
  boolean,
  Infer,
  optional,
  pick,
  string,
  type,
} from "superstruct";
import { MemberStruct } from "../types/Member";

const MemberEventMemberBaseStruct = type({
  organizationId: string(),
  isHubspotEnabled: optional(boolean()),
});

const MemberEventMemberProps = pick(MemberStruct, [
  "id",
  "hubspotContactId",
  "role",
  "firstName",
  "lastName",
  "email",
]);

export const MemberEventMemberStruct = assign(
  MemberEventMemberBaseStruct,
  MemberEventMemberProps,
);

/**
 * Member object for create & update events.
 * @eventProperty
 * @interface
 * @category Member
 */
export type MemberEventMember = Infer<typeof MemberEventMemberStruct>;

const MemberDeletedEventMemberProps = pick(MemberStruct, [
  "id",
  "hubspotContactId",
]);

export const MemberDeletedEventMemberStruct = assign(
  MemberEventMemberBaseStruct,
  MemberDeletedEventMemberProps,
);

/**
 * Member object for delete events.
 * @eventProperty
 * @interface
 * @category Member
 */
export type MemberDeletedEventMember = Infer<
  typeof MemberDeletedEventMemberStruct
>;
