import { Infer, number, optional, string, type } from "superstruct";
import { deprecatedWithLog } from "../utils/deprecatedWithLog";
import { isStruct } from "../utils/isStruct";
import { ConnectorPlatformStruct } from "./ConnectorPlatform";
import { ConnectorStatusLogActionStruct } from "./ConnectorStatusLogAction";
import { ConnectorStatusLogStatusStruct } from "./ConnectorStatusLogStatus";
import { TimestampStruct } from "./Timestamp";

export const ConnectorStatusLogStruct = type({
  connectorConfigId: string(),
  /**
   * @deprecated ConnectorStatusLog.projectId is deprecated.
   */
  projectId: deprecatedWithLog(
    optional(string()),
    "ConnectorStatusLog.projectId is deprecated.",
  ),
  organizationId: string(),
  /**
   * @deprecated ConnectorStatusLog.callId is deprecated.
   */
  callId: deprecatedWithLog(
    optional(string()),
    "ConnectorStatusLog.callId is deprecated.",
  ),
  action: ConnectorStatusLogActionStruct,
  platform: ConnectorPlatformStruct,
  status: ConnectorStatusLogStatusStruct,
  /**
   * @deprecated ConnectorStatusLog.statusCode is deprecated.
   */
  statusCode: deprecatedWithLog(
    optional(number()),
    "ConnectorStatusLog.statusCode is deprecated.",
  ),
  statusMessage: string(),
  createdAt: TimestampStruct,
  /**
   * @deprecated ConnectorStatusLog.durationInMillis is deprecated.
   */
  durationInMillis: deprecatedWithLog(
    optional(number()),
    "ConnectorStatusLog.durationInMillis is deprecated.",
  ),
});

/**
 * @interface
 * @category Project
 */
export type ConnectorStatusLog = Infer<typeof ConnectorStatusLogStruct>;

export function isConnectorStatusLog(obj: unknown): obj is ConnectorStatusLog {
  return isStruct(obj, ConnectorStatusLogStruct, "ConnectorStatusLog");
}
