import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const SubmitTenderRequestStruct = type({
  tenderId: string(),
});

export type SubmitTenderRequest = Infer<typeof SubmitTenderRequestStruct>;

export const submitTenderDefinition = getFirebaseFunctionDefinition({
  path: "/tenders/submit",
  reqType: SubmitTenderRequestStruct,
});
