import { Infer, number, type } from "superstruct";

/**
 * @deprecated
 * @ignore
 */
export const ConnectorConfigEventBackfillConfigStruct = type({
  daysToBackfill: number(),
});

/**
 * @deprecated
 * @eventProperty
 * @interface
 * @category ConnectorConfig
 */
export type ConnectorConfigEventBackfillConfig = Infer<
  typeof ConnectorConfigEventBackfillConfigStruct
>;
