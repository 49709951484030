import { Infer, object, omit, string } from "superstruct";
import { ProviderStruct } from "../../types/Provider";

export const CreateProviderRequestStruct = object({
  organizationId: string(),
  provider: omit(ProviderStruct, [
    "id",
    "organizationId",
    "organizationName",
    "organizationMembersCount",
    "hasOrganizationMembers",
    "status",
    "isReviewed",
    "createdAt",
    "updatedAt",
    "isDeleted",
    "pricingModels", // deprecated
  ]),
});

export type CreateProviderRequest = Infer<typeof CreateProviderRequestStruct>;
