import { Infer, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const ProjectCallCategoryStruct = type({
  id: string(),
  name: string(),
});

/**
 * @interface
 * @category Project
 */
export type ProjectCallCategory = Infer<typeof ProjectCallCategoryStruct>;

export function isProjectCallCategory(
  obj: unknown,
): obj is ProjectCallCategory {
  return isStruct(obj, ProjectCallCategoryStruct, "ProjectCallCategory");
}
