import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const USER_ROLES = ["USER_REGULAR", "USER_ADMIN"] as const;

export const UserRoleStruct = enums(USER_ROLES);

export type UserRole = Infer<typeof UserRoleStruct>;

export function isUserRole(obj: unknown): obj is UserRole {
  return isStruct(obj, UserRoleStruct, "UserRole");
}
