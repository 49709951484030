import { enums, Infer } from "superstruct";

export const CONNECTOR_STATUS_LOG_ACTIONS = [
  "TEST_CONNECTION",
  "FETCH_CDRS",
  "START_TRANSCRIPT_VOICEFILE_AWS",
  "COMPLETE_TRANSCRIPT_VOICEFILE_AWS",
  "COMPLETE_DOWNLOAD_VOICEFILE",
] as const;

export const ConnectorStatusLogActionStruct = enums(
  CONNECTOR_STATUS_LOG_ACTIONS,
);

export type ConnectorStatusLogAction = Infer<
  typeof ConnectorStatusLogActionStruct
>;
