import { Infer, object, string } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteOrganizationRequestStruct = object({
  organizationId: string(),
});

export type DeleteOrganizationRequest = Infer<
  typeof DeleteOrganizationRequestStruct
>;

export const deleteOrganizationDefinition = getFirebaseFunctionDefinition({
  path: "/organizations/delete",
  reqType: DeleteOrganizationRequestStruct,
});
