import { assign, Infer, literal, object, union } from "superstruct";
import { CloudEventStruct } from "../types/CloudEvent";
import { isStruct } from "../utils/isStruct";
import {
  OrganizationDeletedEventOrganizationStruct,
  OrganizationEventOrganizationStruct,
} from "./OrganizationEventOrganization";
import { OrganizationEventType } from "./OrganizationEventType";

export const ORGANIZATION_EVENT_VERSION = 1;

export const OrganizationCreatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("ORGANIZATION_CREATED" satisfies OrganizationEventType),
    organization: OrganizationEventOrganizationStruct,
    version: literal(ORGANIZATION_EVENT_VERSION),
  }),
);

/**
 * Organization created event.
 * @event
 * @interface
 * @category Organization
 */
export type OrganizationCreatedEvent = Infer<
  typeof OrganizationCreatedEventStruct
>;

export const OrganizationUpdatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("ORGANIZATION_UPDATED" satisfies OrganizationEventType),
    organization: OrganizationEventOrganizationStruct,
    version: literal(ORGANIZATION_EVENT_VERSION),
  }),
);

/**
 * Organization updated event.
 * @event
 * @interface
 * @category Organization
 */
export type OrganizationUpdatedEvent = Infer<
  typeof OrganizationUpdatedEventStruct
>;

export const OrganizationDeletedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal("ORGANIZATION_DELETED" satisfies OrganizationEventType),
    organization: OrganizationDeletedEventOrganizationStruct,
    version: literal(ORGANIZATION_EVENT_VERSION),
  }),
);

/**
 * Organization deleted event.
 * @event
 * @interface
 * @category Organization
 */
export type OrganizationDeletedEvent = Infer<
  typeof OrganizationDeletedEventStruct
>;

export const OrganizationEventStruct = union([
  OrganizationCreatedEventStruct,
  OrganizationUpdatedEventStruct,
  OrganizationDeletedEventStruct,
]);

/**
 * Common organization event. Union type of all organization events.
 * @event
 * @category Organization
 */
export type OrganizationEvent = Infer<typeof OrganizationEventStruct>;

export function isOrganizationEvent(obj: unknown): obj is OrganizationEvent {
  return isStruct(obj, OrganizationEventStruct, "OrganizationEvent");
}
