import { Infer, nullable, object, string } from "superstruct";
import { ProjectStruct } from "../../types/Project";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateProjectCallCategoriesRequestStruct = object({
  projectId: string(),
  callCategories: nullable(ProjectStruct.schema.callCategories),
});

export type UpdateProjectCallCategoriesRequest = Infer<
  typeof UpdateProjectCallCategoriesRequestStruct
>;

export const updateProjectCallCategoriesDefinition =
  getFirebaseFunctionDefinition({
    path: "/projects/call-categories/update",
    reqType: UpdateProjectCallCategoriesRequestStruct,
  });
