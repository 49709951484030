import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CALL_FLAGGING_PARAMETER_OPERATORS = [
  "EQUALS",
  "NOT_EQUALS",
  "GREATER_THAN",
  "LESS_THAN",
  "CONTAINS",
  "EXISTS",
] as const;

export const CallFlaggingConfigParameterOperatorStruct = enums(
  CALL_FLAGGING_PARAMETER_OPERATORS,
);

export type CallFlaggingConfigParameterOperator = Infer<
  typeof CallFlaggingConfigParameterOperatorStruct
>;

export function isCallFlaggingConfigParameterOperator(
  obj: unknown,
): obj is CallFlaggingConfigParameterOperator {
  return isStruct(
    obj,
    CallFlaggingConfigParameterOperatorStruct,
    "CallFlaggingConfigParameterOperator",
  );
}
