import { assign, enums, Infer, object, Struct, type, union } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { Call } from "./Call";
import { ConnectorConfig } from "./ConnectorConfig";
import { Invitation } from "./Invitation";
import { LogUpdate } from "./LogUpdate";
import { Member } from "./Member";
import { Notification } from "./Notification";
import { Organization } from "./Organization";
import { Project } from "./Project";
import { Provider } from "./Provider";
import { Tender } from "./Tender";
import { TimestampStruct } from "./Timestamp";
import { UpdatedByStruct } from "./UpdatedBy";
import { User } from "./User";

// Base
export const LogBaseStruct = type({
  updatedBy: UpdatedByStruct,
  createdAt: TimestampStruct,
});

// Structs
export const CallLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums(["projects/{id}/calls"]),
    update: object() as Struct<LogUpdate<Call>>,
  }),
);

export const ConnectorConfigLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums(["organizations/{id}/connectorConfigs"]),
    update: object() as Struct<LogUpdate<ConnectorConfig>>,
  }),
);

export const InvitationLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums(["invitations"]),
    update: object() as Struct<LogUpdate<Invitation>>,
  }),
);

export const MemberLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums(["organizations/{id}/members"]),
    update: object() as Struct<LogUpdate<Member>>,
  }),
);

export const NotificationLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums(["organizations/{id}/notifications"]),
    update: object() as Struct<LogUpdate<Notification>>,
  }),
);

export const OrganizationLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums(["organizations"]),
    update: object() as Struct<LogUpdate<Organization>>,
  }),
);

export const ProjectLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums(["projects"]),
    update: object() as Struct<LogUpdate<Project>>,
  }),
);

export const ProjectReportFrameLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums([
      "projects/{id}/reportFramesByDay",
      "projects/{id}/reportFramesByWeek",
      "projects/{id}/reportFramesByMonth",
    ]),
    update: object() as Struct<LogUpdate<Project>>,
  }),
);

export const ProviderLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums(["providers"]),
    update: object() as Struct<LogUpdate<Provider>>,
  }),
);

export const TenderLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums(["tenders"]),
    update: object() as Struct<LogUpdate<Tender>>,
  }),
);

export const UserLogStruct = assign(
  LogBaseStruct,
  type({
    collectionName: enums(["users"]),
    update: object() as Struct<LogUpdate<User>>,
  }),
);

// Types
export type CallLog = Infer<typeof CallLogStruct>;
export type ConnectorConfigLog = Infer<typeof ConnectorConfigLogStruct>;
export type InvitationLog = Infer<typeof InvitationLogStruct>;
export type MemberLog = Infer<typeof MemberLogStruct>;
export type NotificationLog = Infer<typeof NotificationLogStruct>;
export type OrganizationLog = Infer<typeof OrganizationLogStruct>;
export type ProjectLog = Infer<typeof ProjectLogStruct>;
export type ProjectReportFrameLog = Infer<typeof ProjectReportFrameLogStruct>;
export type ProviderLog = Infer<typeof ProviderLogStruct>;
export type TenderLog = Infer<typeof TenderLogStruct>;
export type UserLog = Infer<typeof UserLogStruct>;

// isT
export function isCallLog(obj: unknown): obj is CallLog {
  return isStruct(obj, CallLogStruct, "CallLog");
}

export function isConnectorConfigLog(obj: unknown): obj is ConnectorConfigLog {
  return isStruct(obj, ConnectorConfigLogStruct, "ConnectorConfigLog");
}

export function isInvitationLog(obj: unknown): obj is InvitationLog {
  return isStruct(obj, InvitationLogStruct, "InvitationLog");
}

export function isNotificationLog(obj: unknown): obj is NotificationLog {
  return isStruct(obj, NotificationLogStruct, "NotificationLog");
}

export function isMemberLog(obj: unknown): obj is MemberLog {
  return isStruct(obj, MemberLogStruct, "MemberLog");
}

export function isOrganizationLog(obj: unknown): obj is OrganizationLog {
  return isStruct(obj, OrganizationLogStruct, "OrganizationLog");
}

export function isProjectLog(obj: unknown): obj is ProjectLog {
  return isStruct(obj, ProjectLogStruct, "ProjectLog");
}

export function isProjectReportFrameLog(
  obj: unknown,
): obj is ProjectReportFrameLog {
  return isStruct(obj, ProjectReportFrameLogStruct, "ProjectReportFrameLog");
}

export function isProviderLog(obj: unknown): obj is ProviderLog {
  return isStruct(obj, ProviderLogStruct, "ProviderLog");
}

export function isTenderLog(obj: unknown): obj is TenderLog {
  return isStruct(obj, TenderLogStruct, "TenderLog");
}

export function isUserLog(obj: unknown): obj is UserLog {
  return isStruct(obj, UserLogStruct, "UserLog");
}

// Log
const LogStruct = union([
  CallLogStruct,
  ConnectorConfigLogStruct,
  InvitationLogStruct,
  MemberLogStruct,
  NotificationLogStruct,
  OrganizationLogStruct,
  ProjectLogStruct,
  ProjectReportFrameLogStruct,
  ProviderLogStruct,
  TenderLogStruct,
  UserLogStruct,
]);

export type Log = Infer<typeof LogStruct>;

export function isLog(obj: unknown): obj is Log {
  return isStruct(obj, LogStruct, "Log");
}
