import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const EMAIL_TEMPLATE_TYPES = [
  "INVITATION",
  "NOTIFICATION",
  "NOTIFICATION_SUMMARY",
] as const;

export const EmailTemplateTypeStruct = enums(EMAIL_TEMPLATE_TYPES);

export type EmailTemplateType = Infer<typeof EmailTemplateTypeStruct>;

export function isEmailTemplate(obj: unknown): obj is EmailTemplateType {
  return isStruct(obj, EmailTemplateTypeStruct, "EmailTemplateType");
}
