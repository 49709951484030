import { string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";
import { CreateProviderRequestStruct } from "../types/CreateProviderRequest";

const CreateProviderResponseStruct = type({
  providerId: string(),
});

export const createProviderDefinition = getFirebaseFunctionDefinition({
  path: "/providers/create",
  reqType: CreateProviderRequestStruct,
  resType: CreateProviderResponseStruct,
});
