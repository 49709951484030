import { Infer, is, union } from "superstruct";
import { AuthErrorStruct } from "./AuthError";
import { OrganizationExistsErrorStruct } from "./OrganizationExistsError";

export const AppErrorStruct = union([
  AuthErrorStruct,
  OrganizationExistsErrorStruct,
]);

export type AppError = Infer<typeof AppErrorStruct>;

export function isAppError(obj: unknown): obj is AppError {
  return is(obj, AppErrorStruct);
}
