import { string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteTenderApplicationDocumentRequestStruct = type({
  tenderId: string(),
  providerId: string(),
  fileHash: string(),
});

export const deleteTenderApplicationDocumentDefinition =
  getFirebaseFunctionDefinition({
    path: "/tenders/delete-application-document",
    reqType: DeleteTenderApplicationDocumentRequestStruct,
  });
