import { array, assign, Infer, object, optional, type } from "superstruct";
import {
  ConnectorConfigBaseStruct,
  InoplaConnectorConfigParamsStruct,
} from "../../types/ConnectorConfig";
import { ExternalProjectStruct } from "../../types/ExternalProject";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const TestConnectorConfigInoplaRequestStruct = assign(
  object({
    organizationId: ConnectorConfigBaseStruct.schema.organizationId,
    connectorConfigId: optional(ConnectorConfigBaseStruct.schema.id),
  }),
  InoplaConnectorConfigParamsStruct,
);

const TestConnectorConfigResponseInoplaStruct = type({
  externalProjects: array(ExternalProjectStruct),
});

export type TestConnectorConfigInoplaRequest = Infer<
  typeof TestConnectorConfigInoplaRequestStruct
>;

export type TestConnectorConfigResponse = Infer<
  typeof TestConnectorConfigResponseInoplaStruct
>;

export const testConnectorConfigInoplaDefinition =
  getFirebaseFunctionDefinition({
    path: "/connector-configs/test-inopla",
    reqType: TestConnectorConfigInoplaRequestStruct,
    resType: TestConnectorConfigResponseInoplaStruct,
  });
