import { Infer, object, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const RestoreProjectRequestStruct = object({
  projectId: string(),
});

export type RestoreProjectRequest = Infer<typeof RestoreProjectRequestStruct>;

const RestoreProjectResponseStruct = type({
  id: string(),
});

export const restoreProjectDefinition = getFirebaseFunctionDefinition({
  path: "/projects/restore",
  reqType: RestoreProjectRequestStruct,
  resType: RestoreProjectResponseStruct,
});
