import {
  assign,
  Infer,
  nullable,
  object,
  omit,
  optional,
  string,
  type,
} from "superstruct";
import { TenderStruct } from "../../types/Tender";
import { nullableObject } from "../../utils/nullableObject";
import { UpdateTenderProviderApplicationsRequestStruct } from "./UpdateTenderProviderApplicationsRequest";

export const UpdateTenderRequestStruct = object({
  tenderId: string(),
  tender: assign(
    nullableObject(
      omit(TenderStruct, [
        "id",
        "organizationId",
        "organizationName",
        "status",
        "supportDocuments",
        "createdAt",
        "updatedAt",
        "submittedAt",
        "publishedAt",
        "endedAt",
        "isDeleted",
        "deleteAt",
      ]),
    ),
    type({
      providerApplications: optional(
        nullable(UpdateTenderProviderApplicationsRequestStruct),
      ),
    }),
  ),
});

export type UpdateTenderRequest = Infer<typeof UpdateTenderRequestStruct>;
