import {
  Infer,
  integer,
  nullable,
  object,
  optional,
  string,
  type,
} from "superstruct";
import { ProjectStruct } from "../../types/Project";
import { deprecatedWithLog } from "../../utils/deprecatedWithLog";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateProjectLimitedRequestStruct = object({
  projectId: string(),
  project: type({
    name: optional(ProjectStruct.schema.name),
    /**
     * @deprecated UpdateProjectLimitedRequest:questions is deprecated, use updateProjectQuestions instead.
     */
    questions: deprecatedWithLog(
      nullable(ProjectStruct.schema.questions),
      "UpdateProjectLimitedRequest:questions is deprecated, use updateProjectQuestions instead.",
    ),
    /**
     * @deprecated UpdateProjectLimitedRequest:scorecardQuestions is deprecated, use updateProjectScorecardQuestions instead.
     */
    scorecardQuestions: deprecatedWithLog(
      nullable(ProjectStruct.schema.scorecardQuestions),
      "UpdateProjectLimitedRequest:scorecardQuestions is deprecated, use updateProjectScorecardQuestions instead.",
    ),
    /**
     * @deprecated UpdateProjectLimitedRequest:callCategories is deprecated, use updateProjectCallCategories instead.
     */
    callCategories: deprecatedWithLog(
      nullable(ProjectStruct.schema.callCategories),
      "UpdateProjectLimitedRequest:callCategories is deprecated, use updateProjectCallCategories instead.",
    ),
    /**
     * @deprecated UpdateProjectLimitedRequest:issueCategories is deprecated, use updateProjectIssueCategories instead.
     */
    issueCategories: deprecatedWithLog(
      nullable(ProjectStruct.schema.issueCategories),
      "UpdateProjectLimitedRequest:issueCategories is deprecated, use updateProjectIssueCategories instead.",
    ),
    /**
     * @deprecated UpdateProjectLimitedRequest:callFlaggingConfigs is deprecated, use updateProjectCallFlaggingConfigs instead.
     */
    callFlaggingConfigs: deprecatedWithLog(
      nullable(ProjectStruct.schema.callFlaggingConfigs),
      "UpdateProjectLimitedRequest:callFlaggingConfigs is deprecated, use updateProjectCallFlaggingConfigs instead.",
    ),
    voicefileLanguage: nullable(ProjectStruct.schema.voicefileLanguage),
    responseLanguage: nullable(ProjectStruct.schema.responseLanguage),
    callSamplingRate: optional(integer()),
  }),
});

export type UpdateProjectLimitedRequest = Infer<
  typeof UpdateProjectLimitedRequestStruct
>;

export const updateProjectLimitedDefinition = getFirebaseFunctionDefinition({
  path: "/projects/update-limited",
  reqType: UpdateProjectLimitedRequestStruct,
});
