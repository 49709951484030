import {
  boolean,
  enums,
  nullable,
  number,
  optional,
  string,
  type,
} from "superstruct";
import { CallProcessRequestKind } from "../../types/CallProcessRequestKind";
import { LanguageStruct } from "../../types/Language";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const RequestReprocessCallsRequestStruct = type({
  projectId: string(),
  fromDate: nullable(number()),
  toDate: nullable(number()),
  kind: enums([
    "VOICEFILE",
    "TRANSCRIPTS",
    "SUMMARY",
    "CATEGORY",
    "QUESTION_ANSWERS",
  ] satisfies ReadonlyArray<Exclude<CallProcessRequestKind, "FLAGGING">>),
  language: optional(LanguageStruct),
  includeSuccessfulCalls: boolean(),
});

const RequestReprocessCallsResponseStruct = type({
  affectedCallsCount: number(),
});

export const requestReprocessCallsDefinition = getFirebaseFunctionDefinition({
  path: "/calls/request-reprocess",
  reqType: RequestReprocessCallsRequestStruct,
  resType: RequestReprocessCallsResponseStruct,
});
