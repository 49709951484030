import {
  array,
  assign,
  Infer,
  object,
  optional,
  string,
  type,
  union,
} from "superstruct";
import {
  ConnectorConfigBaseStruct,
  DialfireConnectorConfigParamsStruct,
  GenericWebhookConnectorConfigParamsStruct,
  InoplaConnectorConfigParamsStruct,
  SftpConnectorConfigParamsStruct,
  VccLiveConnectorConfigParamsStruct,
} from "../../types/ConnectorConfig";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

export const CreateConnectorConfigBaseStruct = object({
  name: ConnectorConfigBaseStruct.schema.name,
  organizationId: ConnectorConfigBaseStruct.schema.organizationId,
  projectMappings: array(
    type({
      projectId: string(),
      projectName: optional(string()), // This is only to create a new project.
      externalProjectId: string(),
    }),
  ),
});

export const CreateConnectorConfigRequestStruct = union([
  assign(CreateConnectorConfigBaseStruct, DialfireConnectorConfigParamsStruct),
  assign(CreateConnectorConfigBaseStruct, InoplaConnectorConfigParamsStruct),
  assign(CreateConnectorConfigBaseStruct, VccLiveConnectorConfigParamsStruct),
  assign(CreateConnectorConfigBaseStruct, SftpConnectorConfigParamsStruct),
  assign(
    CreateConnectorConfigBaseStruct,
    GenericWebhookConnectorConfigParamsStruct,
  ),
]);

export type CreateConnectionRequest = Infer<
  typeof CreateConnectorConfigRequestStruct
>;

const CreateConnectorConfigResponseStruct = type({
  id: string(),
});

export const createConnectorConfigDefinition = getFirebaseFunctionDefinition({
  path: "/connector-configs/create",
  reqType: CreateConnectorConfigRequestStruct,
  resType: CreateConnectorConfigResponseStruct,
});
