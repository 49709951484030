import { mask, Struct } from "superstruct";

export interface FirebaseFunctionDefinition<RequestArgs, ResponseArgs = void> {
  path: string;
  reqType: Struct<RequestArgs>;
  resType?: Struct<ResponseArgs>;
  /**
   * Validate, coerce, and mask an input object based on the request type.
   * @param obj Request args object (may contain extra properties).
   * @returns Validated and masked request type object (extra properties removed).
   */
  getReq: <R extends RequestArgs>(obj: R) => RequestArgs;
  /**
   * Validate, coerce, and mask the response payload based on the response type.
   * @param data Response data.
   * @returns Validated and masked response payload.
   */
  getRes?: (data: unknown) => ResponseArgs;
}

type FirebaseFunctionDefinitionInput<RequestArgs, ResponseArgs = void> = Omit<
  FirebaseFunctionDefinition<RequestArgs, ResponseArgs>,
  "getReq"
>;

/**
 * Create a definition object for a Cloud Function with a path, input and output type.
 */
export function getFirebaseFunctionDefinition<RequestArgs, ResponseArgs = void>(
  definition: FirebaseFunctionDefinitionInput<RequestArgs, ResponseArgs>,
): FirebaseFunctionDefinition<RequestArgs, ResponseArgs> {
  const { reqType, resType } = definition;

  return {
    ...definition,
    getReq: (obj) => mask(obj, reqType),
    getRes: resType ? (data) => mask(data, resType) : undefined,
  };
}
