import {
  assign,
  boolean,
  Infer,
  literal,
  nullable,
  optional,
  string,
  type,
  union,
} from "superstruct";
import { isStruct } from "../utils/isStruct";
import { NotificationEmailSentStatusStruct } from "./NotificationEmailSentStatus";
import {
  NotificationType,
  TenderNotificationTypeStruct,
} from "./NotificationType";
import { TaskStatusStruct } from "./TaskStatus";
import { TimestampStruct } from "./Timestamp";

const NotificationBaseStruct = type({
  id: string(),
  /**
   * If set to a user ID, the notification is only visible to that user.
   * It's nullable to query all unassigned notifications.
   */
  userId: nullable(string()),
  userName: optional(string()),
  emailSentStatus: optional(NotificationEmailSentStatusStruct),
  /**
   * If the user ID is not set, the notification is visible to all users of this organization.
   */
  organizationId: string(),
  hasBeenRead: boolean(),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
});

// Tender notifications --------------------------------------------------------
export const TenderNotificationStruct = assign(
  NotificationBaseStruct,
  type({
    type: TenderNotificationTypeStruct,
    tenderId: string(),
  }),
);

/**
 * Received by the admins when a new tender was submitted for review.
 */
export const TenderSubmittedNotificationStruct = assign(
  TenderNotificationStruct,
  type({
    type: literal("TENDER_SUBMITTED" satisfies NotificationType),
  }),
);

/**
 * Received by the client when their tender was published.
 */
export const TenderPublishedNotificationStruct = assign(
  TenderNotificationStruct,
  type({
    type: literal("TENDER_PUBLISHED" satisfies NotificationType),
  }),
);

/**
 * Received by the provider when their profile was matched to a tender.
 */
export const TenderMatchedNotificationStruct = assign(
  TenderNotificationStruct,
  type({
    type: literal("TENDER_MATCHED" satisfies NotificationType),
  }),
);

/**
 * Received by the provider when the matched tender has ended.
 */
export const TenderEndedNotificationStruct = assign(
  TenderNotificationStruct,
  type({
    type: literal("TENDER_ENDED" satisfies NotificationType),
  }),
);

/**
 * Received by the admin when the tender has ended.
 */
export const TenderEndedAdminNotificationStruct = assign(
  TenderNotificationStruct,
  type({
    type: literal("TENDER_ENDED_ADMIN" satisfies NotificationType),
  }),
);

/**
 * Received by the admin when all the tender providers applications are completed (either accepted or rejected).
 */
export const TenderApplicationsCompletedAdminNotificationStruct = assign(
  TenderNotificationStruct,
  type({
    type: literal(
      "TENDER_APPLICATIONS_COMPLETED_ADMIN" satisfies NotificationType,
    ),
  }),
);

export const TenderApplicationReceivedNotificationStruct = assign(
  TenderNotificationStruct,
  type({
    type: literal("TENDER_APPLICATION_RECEIVED" satisfies NotificationType),
  }),
);

export const TenderApplicationAcceptedNotificationStruct = assign(
  TenderNotificationStruct,
  type({
    type: literal("TENDER_APPLICATION_ACCEPTED" satisfies NotificationType),
  }),
);

export const TenderApplicationRejectedNotificationStruct = assign(
  TenderNotificationStruct,
  type({
    type: literal("TENDER_APPLICATION_REJECTED" satisfies NotificationType),
  }),
);

// Tasks -----------------------------------------------------------------------
const TaskBaseStruct = assign(
  NotificationBaseStruct,
  type({
    isTask: literal(true),
    status: TaskStatusStruct,
    createdByUserId: optional(string()),
    createdByUserName: optional(string()),
    doneAt: optional(TimestampStruct),
  }),
);

const CallReviewTaskStruct = assign(
  TaskBaseStruct,
  type({
    type: literal("CALL_REVIEW" satisfies NotificationType),
    callId: string(),
    projectId: string(),
    projectName: string(),
  }),
);

/**
 * @interface
 * @category Notification
 */
export type CallReviewTask = Infer<typeof CallReviewTaskStruct>;

// Tasks -----------------------------------------------------------------------
export const TaskStruct = union([CallReviewTaskStruct]);

export type Task = Infer<typeof TaskStruct>;

// Notifications ---------------------------------------------------------------
const NotificationStruct = union([
  TenderSubmittedNotificationStruct,
  TenderPublishedNotificationStruct,
  TenderMatchedNotificationStruct,
  TenderEndedNotificationStruct,
  TenderEndedAdminNotificationStruct,
  TenderApplicationReceivedNotificationStruct,
  TenderApplicationAcceptedNotificationStruct,
  TenderApplicationRejectedNotificationStruct,
  TenderApplicationsCompletedAdminNotificationStruct,
  CallReviewTaskStruct,
]);

export function isNotification(obj: unknown): obj is Notification {
  return isStruct(obj, NotificationStruct, "Notification");
}

/**
 * @interface
 * @category Notification
 */
export type Notification = Infer<typeof NotificationStruct>;
