import {
  assign,
  deprecated,
  Infer,
  literal,
  object,
  optional,
  union,
} from "superstruct";
import { CloudEventStruct } from "../types/CloudEvent";
import { ConnectorConfigEventBackfillConfigStruct } from "./ConnectorConfigEventBackfillConfig";
import {
  ConnectorConfigDeletedEventConnectorConfigStruct,
  ConnectorConfigEventConnectorConfigStruct,
} from "./ConnectorConfigEventConnectorConfig";
import { ConnectorConfigEventType } from "./ConnectorConfigEventType";

export const CONNECTOR_CONFIG_EVENT_VERSION = 2;

export const ConnectorConfigCreatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal(
      "CONNECTOR_CONFIG_CREATED" satisfies ConnectorConfigEventType,
    ),
    /**
     * @see {@link ConnectorConfigEventConnectorConfig}
     */
    connectorConfig: ConnectorConfigEventConnectorConfigStruct,
    /**
     * @deprecated Use connectorConfig.backfillDays
     */
    backfillConfig: deprecated(
      optional(ConnectorConfigEventBackfillConfigStruct),
      () => {
        console.warn(
          "ConnectorConfigCreatedEvent.backfillConfig is deprecated. Use ConnectorConfigEventConnectorConfig.backfillDays.",
        );
      },
    ),
    version: literal(CONNECTOR_CONFIG_EVENT_VERSION),
  }),
);

/**
 * Connector config created event.
 * @event
 * @interface
 * @category ConnectorConfig
 */
export type ConnectorConfigCreatedEvent = Infer<
  typeof ConnectorConfigCreatedEventStruct
>;

export const ConnectorConfigUpdatedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal(
      "CONNECTOR_CONFIG_UPDATED" satisfies ConnectorConfigEventType,
    ),
    /**
     * @see {@link ConnectorConfigEventConnectorConfig}
     */
    connectorConfig: ConnectorConfigEventConnectorConfigStruct,
    /**
     * @deprecated Use connectorConfig.backfillDays
     */
    backfillConfig: deprecated(
      optional(ConnectorConfigEventBackfillConfigStruct),
      () => {
        console.warn(
          "ConnectorConfigUpdatedEvent.backfillConfig is deprecated. Use ConnectorConfigEventConnectorConfig.backfillDays.",
        );
      },
    ),
    version: literal(CONNECTOR_CONFIG_EVENT_VERSION),
  }),
);

/**
 * Connector config updated event.
 * @event
 * @interface
 * @category ConnectorConfig
 */
export type ConnectorConfigUpdatedEvent = Infer<
  typeof ConnectorConfigUpdatedEventStruct
>;

export const ConnectorConfigDeletedEventStruct = assign(
  CloudEventStruct,
  object({
    type: literal(
      "CONNECTOR_CONFIG_DELETED" satisfies ConnectorConfigEventType,
    ),
    /**
     * @see {@link ConnectorConfigEventConnectorConfig}
     */
    connectorConfig: ConnectorConfigDeletedEventConnectorConfigStruct,
    version: literal(CONNECTOR_CONFIG_EVENT_VERSION),
  }),
);

/**
 * Connector config deleted event.
 * @event
 * @interface
 * @category ConnectorConfig
 */
export type ConnectorConfigDeletedEvent = Infer<
  typeof ConnectorConfigDeletedEventStruct
>;

export const ConnectorConfigEventStruct = union([
  ConnectorConfigCreatedEventStruct,
  ConnectorConfigUpdatedEventStruct,
  ConnectorConfigDeletedEventStruct,
]);

/**
 * Common connector config event. Union type of all connector config events.
 * @event
 * @category ConnectorConfig
 * @see {@link ConnectorConfig}
 */
export type ConnectorConfigEvent = Infer<typeof ConnectorConfigEventStruct>;
