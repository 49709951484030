export const TENDER_APPLICATION_PROVIDER_REJECTION_REASONS = [
  "OTHER",
  "PROJECT_TIMELINE",
  "PROJECT_SIZE",
  "PROJECT_SERIOUSNESS",
  "MISSING_INDUSTRY_EXPERIENCE",
  "PROJECT_PRICING_MODEL",
  "PROJECT_COMPLEXITY",
  "NON_COMPETITION_CLAUSE",
] as const;
