import {
  assign,
  enums,
  Infer,
  is,
  literal,
  string,
  type,
  union,
} from "superstruct";
import { ErrorCode } from "./ErrorCode";
import { Permission, PermissionStruct } from "./Permission";

export const AuthErrorCodeStruct = enums([
  "auth/permission-denied",
  "auth/invalid-email",
] satisfies ErrorCode[]);

export type AuthErrorCode = Infer<typeof AuthErrorCodeStruct>;

const AuthErrorBaseStruct = type({
  name: literal("AuthError"),
  message: string(),
  code: AuthErrorCodeStruct,
});

type AuthErrorBase = Infer<typeof AuthErrorBaseStruct>;

export const AuthErrorPermissionDeniedStruct = assign(
  AuthErrorBaseStruct,
  type({
    code: literal("auth/permission-denied" satisfies AuthErrorCode),
    permission: PermissionStruct,
  }),
);

export type AuthErrorPermissionDenied = Infer<
  typeof AuthErrorPermissionDeniedStruct
>;

export const AuthErrorInvalidEmailStruct = assign(
  AuthErrorBaseStruct,
  type({
    code: literal("auth/invalid-email" satisfies AuthErrorCode),
    email: string(),
  }),
);

export type AuthErrorInvalidEmail = Infer<typeof AuthErrorInvalidEmailStruct>;

export const AuthErrorStruct = union([
  AuthErrorPermissionDeniedStruct,
  AuthErrorInvalidEmailStruct,
]);

export type AuthError = Infer<typeof AuthErrorStruct>;

export function isAuthError(obj: unknown): obj is AuthError {
  return is(obj, AuthErrorStruct);
}

abstract class AuthErrorBaseImpl extends Error implements AuthErrorBase {
  public readonly name = "AuthError";

  constructor(
    public readonly code: AuthErrorCode,
    public readonly message: string,
    public readonly extra: Record<string, string | number | boolean> = {},
  ) {
    super(message);
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      code: this.code,
      ...this.extra,
    } satisfies AuthErrorBase;
  }
}

export class AuthPermissionDeniedErrorImpl extends AuthErrorBaseImpl {
  constructor(extra: { permission: Permission }) {
    super(
      "auth/permission-denied",
      `User does not have permission ${extra.permission} to perform this action`,
      extra,
    );
  }
}

export class AuthInvalidEmailErrorImpl extends AuthErrorBaseImpl {
  constructor(extra: { email: string }) {
    super("auth/invalid-email", `Email ${extra.email} is invalid`, extra);
  }
}
