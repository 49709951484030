import { coerce, nullable, optional, Struct } from "superstruct";

/**
 * Same as {@link optional} + {@link nullable} but `undefined` and `""` values are coerced to null values
 *
 * @deprecated Explicitly set values that should be deleted to `null`.
 */
export function deletable<T, S>(
  struct: Struct<T, S>,
): Struct<T | null | undefined, S> {
  return coerce(optional(nullable(struct)), optional(struct), (value) =>
    value === undefined || value === "" ? null : value,
  );
}
