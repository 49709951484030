import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CALL_ISSUE_STATUSES = ["OPEN", "VALID", "INVALID"] as const;

export const CallIssueStatusStruct = enums(CALL_ISSUE_STATUSES);

export type CallIssueStatus = Infer<typeof CallIssueStatusStruct>;

export function isCallIssueStatus(obj: unknown): obj is CallIssueStatus {
  return isStruct(obj, CallIssueStatusStruct, "CallIssueStatus");
}
