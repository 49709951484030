import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const BUDGET_DURATION_TYPES = ["TOTAL", "MONTHLY", "YEARLY"] as const;

export const BudgetDurationTypeStruct = enums(BUDGET_DURATION_TYPES);

/**
 * @interface
 * @category Tender
 */
export type BudgetDurationType = Infer<typeof BudgetDurationTypeStruct>;

export function isBudgetDurationType(obj: unknown): obj is BudgetDurationType {
  return isStruct(obj, BudgetDurationTypeStruct, "BudgetDurationType");
}
