import { Infer, string, Struct, type } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const UpdatedByStruct = type({
  type: string() as Struct<"USER" | "SYSTEM">,
  id: string(),
  name: string(),
});

export type UpdatedBy = Infer<typeof UpdatedByStruct>;

export function isUpdatedBy(obj: unknown): obj is UpdatedBy {
  return isStruct(obj, UpdatedByStruct, "UpdatedBy");
}
