import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CALL_QUESTION_ANSWER_TYPES = [
  "BOOLEAN",
  "TEXT",
  "NUMBER",
] as const;

export const CallQuestionAnswerTypeStruct = enums(CALL_QUESTION_ANSWER_TYPES);

export type CallQuestionAnswerType = Infer<typeof CallQuestionAnswerTypeStruct>;

export function isCallQuestionAnswerType(
  obj: unknown,
): obj is CallQuestionAnswerType {
  return isStruct(obj, CallQuestionAnswerTypeStruct, "CallQuestionAnswerType");
}
