import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const PROVIDER_PROFESSIONAL_EXPERIENCES = [
  "PUBLIC_SECTOR",
  "SMALL_MEDIUM_SIZED_COMPANIES",
  "NON_PROFIT_ORGANIZATIONS",
  "FORTUNE_500",
  "GOVERNMENT_BODIES",
  "STARTUPS",
  "SUBCONTRACTS",
  "INTERNATIONAL_COMPANIES",
] as const;

export const ProviderProfessionalExperienceStruct = enums(
  PROVIDER_PROFESSIONAL_EXPERIENCES,
);

export type ProviderProfessionalExperience = Infer<
  typeof ProviderProfessionalExperienceStruct
>;

export function isProviderProfessionalExperience(
  obj: unknown,
): obj is ProviderProfessionalExperience {
  return isStruct(
    obj,
    ProviderProfessionalExperienceStruct,
    "ProviderProfessionalExperience",
  );
}
