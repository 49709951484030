import { Infer, number, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { TimestampStruct } from "./Timestamp";

export const StorageFileStruct = type({
  fileName: string(),
  filePath: string(),
  fileHash: string(),
  fileSizeBytes: number(),
  fileType: optional(string()),
  userId: string(),
  organizationId: optional(string()),
  createdAt: TimestampStruct,
});

/**
 * @interface
 * @category StorageFile
 */
export type StorageFile = Infer<typeof StorageFileStruct>;

export function isStorageFile(obj: unknown): obj is StorageFile {
  return isStruct(obj, StorageFileStruct, "StorageFile");
}
