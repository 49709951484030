import { enums, Infer } from "superstruct";

export const FirebaseWhereFilterOpStruct = enums([
  "<",
  "<=",
  "==",
  "!=",
  ">=",
  ">",
  "array-contains",
  "in",
  "array-contains-any",
  "not-in",
  "search", // custom search operator, must be translated into >= and <=
]);

export type FirebaseWhereFilterOp = Infer<typeof FirebaseWhereFilterOpStruct>;
