import { Infer, object, string } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const SubmitProviderRequestStruct = object({
  providerId: string(),
});

export type SubmitProviderRequest = Infer<typeof SubmitProviderRequestStruct>;

export const submitProviderDefinition = getFirebaseFunctionDefinition({
  path: "/providers/submit",
  reqType: SubmitProviderRequestStruct,
});
