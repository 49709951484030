import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CALL_FLAGGING_COMMON_PARAMETER_TYPES = [
  "DURATION_IN_SECONDS",
  "DIRECTION",
  "TRANSCRIPT",
  "WAITING_TIME_IN_SECONDS",
  "HOLDING_TIME_IN_SECONDS",
  "PRE_CALL_WORK_TIME_IN_SECONDS",
  "POST_CALL_WORK_TIME_IN_SECONDS",
  "TOTAL_TIME_IN_SECONDS",
  "VOICE_FILE",
  "CATEGORY",
] as const;

export const CALL_FLAGGING_PARAMETER_TYPES = [
  ...CALL_FLAGGING_COMMON_PARAMETER_TYPES,
  "QUESTION_ANSWER",
] as const;

export const CallFlaggingConfigCommonParameterTypeStruct = enums(
  CALL_FLAGGING_COMMON_PARAMETER_TYPES,
);

export type CallFlaggingConfigCommonParameterType = Infer<
  typeof CallFlaggingConfigCommonParameterTypeStruct
>;

export const CallFlaggingConfigParameterTypeStruct = enums(
  CALL_FLAGGING_PARAMETER_TYPES,
);

export type CallFlaggingConfigParameterType = Infer<
  typeof CallFlaggingConfigParameterTypeStruct
>;

export function isCallFlaggingConfigParameterType(
  obj: unknown,
): obj is CallFlaggingConfigParameterType {
  return isStruct(
    obj,
    CallFlaggingConfigParameterTypeStruct,
    "CallFlaggingConfigParameterType",
  );
}
