import {
  boolean,
  Infer,
  object,
  optional,
  string,
  Struct,
  type,
} from "superstruct";
import { LocaleStruct } from "../../types/Locale";
import { UserRole } from "../../types/UserRole";
import { UserTypeStruct } from "../../types/UserType";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateUserRequestStruct = type({
  userId: string(),
  user: object({
    firstName: optional(string()),
    lastName: optional(string()),
    role: optional(string() as Struct<UserRole>),
    enabledEmails: optional(
      type({
        taskAssigned: boolean(),
      }),
    ),
    isHubspotEnabled: optional(boolean()),
    userType: optional(UserTypeStruct),
    selectedOrganizationId: optional(string()),
    selectedLocale: optional(LocaleStruct),
  }),
});

export type UpdateUserRequest = Infer<typeof UpdateUserRequestStruct>;

export const updateUserDefinition = getFirebaseFunctionDefinition({
  path: "/users/update",
  reqType: UpdateUserRequestStruct,
});
