import i18n from "i18next";
import toast from "react-hot-toast";
import { ERROR_CODE_RECORD } from "../../consts/ERROR_CODE_RECORD";
import { getAppError } from "./getAppError";

/**
 * Triggers a hot {@link toast} with the given error message.
 */
export function toastError(error: Error) {
  const appError = getAppError(error);
  if (appError) {
    const { message } = ERROR_CODE_RECORD[appError.code];
    toast.error(i18n.t(message, appError));
  } else {
    toast.error(error.message);
  }
}
