import { Infer, object, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const RestoreTenderRequestStruct = object({
  tenderId: string(),
});

export type RestoreTenderRequest = Infer<typeof RestoreTenderRequestStruct>;

const RestoreTenderResponseStruct = type({
  id: string(),
});

export const restoreTenderDefinition = getFirebaseFunctionDefinition({
  path: "/tenders/restore",
  reqType: RestoreTenderRequestStruct,
  resType: RestoreTenderResponseStruct,
});
