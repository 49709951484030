import { Infer, string, type } from "superstruct";
import { ConnectorPlatformStruct } from "../../types/ConnectorPlatform";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const GenerateConnectorConfigWebhookUrlRequestStruct = type({
  connectorConfigId: string(),
  connectorPlatform: ConnectorPlatformStruct,
});

export type GenerateConnectorConfigWebhookUrlRequest = Infer<
  typeof GenerateConnectorConfigWebhookUrlRequestStruct
>;

export const generateConnectorConfigWebhookUrlDefinition =
  getFirebaseFunctionDefinition({
    path: "/connector-configs/generate-webhook-url",
    reqType: GenerateConnectorConfigWebhookUrlRequestStruct,
  });
