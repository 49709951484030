import { array, Describe, object, optional, Struct, type } from "superstruct";
import { ObjectSchema, ObjectType } from "superstruct/dist/utils";
import { Timestamp, TimestampStruct } from "../types/Timestamp";
import { DeepReplace } from "./DeepReplace";
import { isOptionalStruct } from "./isOptionalStruct";
import { TimestampToStringStruct } from "./TimestampToStringStruct";
import { AnyStruct, ArrayStruct, ObjectStruct } from "./types";

/**
 * Deeply replace Timestamp structs with String structs.
 *
 * @param struct Struct describing an object.
 * @returns Struct with all the Timestamp fields replace by String fields.
 */
export function deepReplaceTimestampStructWithStringStruct(
  struct: Describe<Timestamp>,
): Struct<string, null>;
export function deepReplaceTimestampStructWithStringStruct(
  struct: Describe<Timestamp | undefined>,
): Struct<string | undefined, null>;
export function deepReplaceTimestampStructWithStringStruct<
  S extends ObjectSchema,
>(
  struct: Struct<ObjectType<S>, S>,
): Struct<
  DeepReplace<ObjectType<S>, Timestamp, string>,
  DeepReplace<S, Describe<Timestamp>, Struct<string, null>>
>;
export function deepReplaceTimestampStructWithStringStruct(
  struct: AnyStruct,
): AnyStruct;
export function deepReplaceTimestampStructWithStringStruct(
  struct: AnyStruct,
): AnyStruct {
  if (struct === TimestampStruct) {
    return TimestampToStringStruct;
  }
  if (struct.schema === TimestampStruct.schema && isOptionalStruct(struct)) {
    return optional(TimestampToStringStruct);
  }

  // type === 'record' also needs to be transformed but it has no schema :(
  if (struct.type === "type" || struct.type === "object") {
    const newObjectSchema: ObjectSchema = {};
    for (const [name, type] of Object.entries(
      (struct as ObjectStruct).schema,
    )) {
      newObjectSchema[name] = deepReplaceTimestampStructWithStringStruct(type);
    }
    let newStruct;
    if (struct.type === "type") {
      newStruct = type(newObjectSchema);
    } else {
      newStruct = object(newObjectSchema);
    }
    if (isOptionalStruct(struct)) {
      return optional(newStruct);
    }
    return newStruct;
  }

  if (struct.type === "array") {
    const arrayStruct = array(
      deepReplaceTimestampStructWithStringStruct(
        (struct as ArrayStruct).schema,
      ),
    );
    if (isOptionalStruct(struct)) {
      return optional(arrayStruct);
    }
    return arrayStruct;
  }

  return struct;
}
