import {
  assign,
  boolean,
  enums,
  Infer,
  literal,
  number,
  string,
  type,
  union,
} from "superstruct";
import { isStruct } from "../utils/isStruct";
import { CallFlaggingConfigParameterOperatorStruct } from "./CallFlaggingConfigParameterOperator";
import {
  CALL_FLAGGING_COMMON_PARAMETER_TYPES,
  CallFlaggingConfigParameterType,
  CallFlaggingConfigParameterTypeStruct,
} from "./CallFlaggingConfigParameterType";

export const CallFlaggingConfigParameterValueStruct = union([
  string(),
  number(),
  boolean(),
]);

/**
 * @interface
 * @category Project
 */
export type CallFlaggingConfigParameterValue = Infer<
  typeof CallFlaggingConfigParameterValueStruct
>;

const CallFlaggingConfigParameterBaseStruct = type({
  id: string(),
  type: CallFlaggingConfigParameterTypeStruct,
  operator: CallFlaggingConfigParameterOperatorStruct,
  value: union([string(), number(), boolean()]),
});

const CallFlaggingConfigCommonParameterStruct = assign(
  CallFlaggingConfigParameterBaseStruct,
  type({
    type: enums(CALL_FLAGGING_COMMON_PARAMETER_TYPES),
  }),
);

/**
 * @interface
 * @category Project
 */
export type CallFlaggingConfigCommonParameter = Infer<
  typeof CallFlaggingConfigCommonParameterStruct
>;

export const CallFlaggingConfigQuestionAnswerParameterStruct = assign(
  CallFlaggingConfigParameterBaseStruct,
  type({
    type: literal("QUESTION_ANSWER" satisfies CallFlaggingConfigParameterType),
    questionId: string(),
    questionRef: string(),
  }),
);

export type CallFlaggingConfigQuestionAnswerParameter = Infer<
  typeof CallFlaggingConfigQuestionAnswerParameterStruct
>;

export const CallFlaggingConfigParameterStruct = union([
  CallFlaggingConfigCommonParameterStruct,
  CallFlaggingConfigQuestionAnswerParameterStruct,
]);

/**
 * @category Project
 */
export type CallFlaggingConfigParameter = Infer<
  typeof CallFlaggingConfigParameterStruct
>;

export function isCallFlaggingConfigParameter(
  obj: unknown,
): obj is CallFlaggingConfigParameter {
  return isStruct(
    obj,
    CallFlaggingConfigParameterStruct,
    "CallFlaggingConfigParameter",
  );
}
