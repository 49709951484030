import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const MEMBER_ROLES = [
  "MEMBER_OWNER",
  "MEMBER_MANAGER",
  "MEMBER_REGULAR",
] as const;

export const MemberRoleStruct = enums(MEMBER_ROLES);

export type MemberRole = Infer<typeof MemberRoleStruct>;

export function isMemberRole(obj: unknown): obj is MemberRole {
  return isStruct(obj, MemberRoleStruct, "MemberRole");
}
