import { Infer, pick } from "superstruct";
import { TenderStruct } from "../types/Tender";
import { deepReplaceTimestampStructWithStringStruct } from "../utils/deepReplaceTimestampStructWithStringStruct";

export const TenderEventTenderStruct =
  deepReplaceTimestampStructWithStringStruct(TenderStruct);

/**
 * Tender object for create & update events.
 * @eventProperty
 * @interface
 * @category Tender
 */
export type TenderEventTender = Infer<typeof TenderEventTenderStruct>;

export const TenderDeletedEventTenderStruct = pick(TenderStruct, ["id"]);

/**
 * Tender object for delete events.
 * @eventProperty
 * @interface
 * @category Tender
 */
export type TenderDeletedEventTender = Infer<
  typeof TenderDeletedEventTenderStruct
>;
