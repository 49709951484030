import {
  assign,
  boolean,
  Infer,
  literal,
  number,
  optional,
  string,
  type,
  union,
} from "superstruct";
import { isStruct } from "../utils/isStruct";
import { CallQuestionAnswerType } from "./CallQuestionAnswerType";

const CallQuestionAnswerBaseStruct = type({
  id: string(),
  /**
   * TODO: questionId should be required
   */
  questionId: optional(string()),
  text: string(),
});

const BooleanCallQuestionAnswerStruct = assign(
  CallQuestionAnswerBaseStruct,
  type({
    type: literal("BOOLEAN" satisfies CallQuestionAnswerType),
    booleanValue: boolean(),
  }),
);

/**
 * @interface
 * @category Call
 */
export type BooleanCallQuestionAnswer = Infer<
  typeof BooleanCallQuestionAnswerStruct
>;

const NumberCallQuestionAnswerStruct = assign(
  CallQuestionAnswerBaseStruct,
  type({
    type: literal("NUMBER" satisfies CallQuestionAnswerType),
    numberValue: number(),
  }),
);

/**
 * @interface
 * @category Call
 */
export type NumberCallQuestionAnswer = Infer<
  typeof NumberCallQuestionAnswerStruct
>;

const TextCallQuestionAnswerStruct = assign(
  CallQuestionAnswerBaseStruct,
  type({
    type: literal("TEXT" satisfies CallQuestionAnswerType),
    textValue: string(),
  }),
);

/**
 * @interface
 * @category Call
 */
export type TextCallQuestionAnswer = Infer<typeof TextCallQuestionAnswerStruct>;

export const CallQuestionAnswerStruct = union([
  BooleanCallQuestionAnswerStruct,
  NumberCallQuestionAnswerStruct,
  TextCallQuestionAnswerStruct,
]);

/**
 * @category Call
 */
export type CallQuestionAnswer = Infer<typeof CallQuestionAnswerStruct>;

export function isCallQuestionAnswer(obj: unknown): obj is CallQuestionAnswer {
  return isStruct(obj, CallQuestionAnswerStruct, "CallQuestionAnswer");
}
