import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const INBOUND_SERVICE_TYPES = [
  "TELEPHONE_ANSWERING",
  "APPOINTMENT_MANAGEMENT",
  "OVERFLOW_AFTER_HOURS_SUPPORT",
  "CUSTOMER_SERVICE",
  "DISASTER_RESPONSE",
  "TECHNICAL_SUPPORT",
  "EMERGENCY_HOTLINE",
  "HELP_DESK",
  "HOTLINE",
  "LIVE_CHAT",
  "LOYALTY_PROGRAMS",
  "ORDER_PROCESSING",
  "RECEPTIONIST",
  "WHISTLEBLOWER_HOTLINE",
  "VIDEO_IDENT",
  "SERVICE_DESK",
  "FIRST_LEVEL_SUPPORT",
  "VIDEOCHAT_SERVICE",
  "COMPLAINT_MANAGEMENT",
  "DONATION_HOTLINE",
] as const;

export const OUTBOUND_SERVICE_TYPES = [
  "LEAD_GENERATION",
  "APPOINTMENT_SETTING",
  "MARKET_RESEARCH",
  "SUBSCRIPTION_MARKETING",
  "COLLECTION_REMINDERS",
  "INSURANCE_SALES",
  "MYSTERY_CALLS",
  "EVENT_REGISTRATION",
  "TELESALES",
  "TELEMARKETING",
  "SERVICE_CALLS",
  "TELEPHONE_SURVEYS",
] as const;

export const BACK_OFFICE_SERVICE_TYPES = [
  "DATA_MAINTENANCE",
  "DATA_ANALYTICS",
  "EMAIL_SERVICE",
  "PAYROLL_ACCOUNTING",
  "FULFILLMENT",
  "SECRETARIAL_SERVICES",
  "CUSTOMER_FEEDBACK_HANDLING",
  "DEBT_COLLECTION_AND_DUNNING",
  "MAIL_PROCESSING",
] as const;

export const SERVICE_TYPES = [
  ...INBOUND_SERVICE_TYPES,
  ...OUTBOUND_SERVICE_TYPES,
  ...BACK_OFFICE_SERVICE_TYPES,
];

export const ServiceTypeStruct = enums(SERVICE_TYPES);

export type ServiceType = Infer<typeof ServiceTypeStruct>;

export function isServiceType(obj: unknown): obj is ServiceType {
  return isStruct(obj, ServiceTypeStruct, "ServiceType");
}
