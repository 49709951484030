import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const INDUSTRIES = [
  "ADMINISTRATION_AND_OFFICE_WORK",
  "ARCHITECTURE_AND_PLANNING",
  "AUTOMOTIVE",
  "BANKING_AND_FINANCIAL_SERVICES",
  "CONSULTING",
  "CHEMISTRY",
  "SERVICES_INDUSTRY",
  "E_COMMERCE",
  "ELECTRICAL_ENGINEERING",
  "ENERGY",
  "EDUCATION_AND_SCIENCE",
  "CRAFT_INDUSTRY",
  "HEALTHCARE",
  "REAL_ESTATE_AND_FACILITY_MANAGEMENT",
  "INDUSTRY_AND_MECHANICAL_ENGINEERING",
  "INTERNET_AND_MULTIMEDIA",
  "IT_SERVICES",
  "CONSUMER_GOODS_AND_RETAIL",
  "ART_CULTURE_AND_ENTERTAINMENT",
  "AGRICULTURE",
  "MARKETING_ADVERTISING_AND_PR",
  "MARKET_RESEARCH",
  "MEDIA_AND_PUBLISHING",
  "MEDICAL_TECHNOLOGY",
  "FOOD_INDUSTRY",
  "PUBLIC_ADMINISTRATION",
  "HR_SERVICES",
  "PHARMA",
  "SOCIAL_SERVICES",
  "SPORT_FITNESS_AND_BEAUTY",
  "TELECOMMUNICATION",
  "TEXTILE_INDUSTRY",
  "TOURISM_HOTEL_AND_GASTRONOMY",
  "TRANSPORT_AND_LOGISTICS",
  "ASSOCIATIONS_ORGANIZATIONS_AND_INSTITUTIONS",
  "INSURANCES",
  "AUDITING_TAXES_AND_LEGAL_ADVISE",
  "OTHER",
] as const;

export const DefaultIndustryStruct = enums(INDUSTRIES);

export type DefaultIndustry = Infer<typeof DefaultIndustryStruct>;

/**
 * @deprecated Only for backwards compatibility with Organization.
 */
export const LEGACY_INDUSTRIES = [
  "Administration",
  "Architecture",
  "Automotive",
  "Banking",
  "Consulting",
] as const;

/**
 * @deprecated Only for backwards compatibility with Organization.
 */
export const LegacyIndustryStruct = enums(LEGACY_INDUSTRIES);

/**
 * @deprecated Only for backwards compatibility with Organization.
 */
export type LegacyIndustry = Infer<typeof LegacyIndustryStruct>;

/**
 * @deprecated Only for backwards compatibility with Organization.
 */
export function isLegacyIndustry(obj: unknown): obj is LegacyIndustry {
  return isStruct(obj, LegacyIndustryStruct, "LegacyIndustry");
}

export const IndustryStruct = enums([...INDUSTRIES, ...LEGACY_INDUSTRIES]);

export type Industry = Infer<typeof IndustryStruct>;

export function isIndustry(obj: unknown): obj is Industry {
  return isStruct(obj, IndustryStruct, "Industry");
}
