import {
  boolean,
  Infer,
  nullable,
  object,
  optional,
  string,
} from "superstruct";
import { OrganizationStruct } from "../../types/Organization";
import { deletable } from "../../utils/deletable";

export const UpdateOrganizationRequestStruct = object({
  organizationId: string(),
  organization: object({
    name: optional(OrganizationStruct.schema.name),
    email: deletable(OrganizationStruct.schema.email),
    types: deletable(OrganizationStruct.schema.types),
    phone: deletable(OrganizationStruct.schema.phone),
    street: deletable(OrganizationStruct.schema.street),
    city: deletable(OrganizationStruct.schema.city),
    zipCode: deletable(OrganizationStruct.schema.zipCode),
    country: deletable(OrganizationStruct.schema.country),
    description: deletable(OrganizationStruct.schema.description),
    numberOfEmployees: deletable(OrganizationStruct.schema.numberOfEmployees),
    foundedInYear: deletable(OrganizationStruct.schema.foundedInYear),
    revenuePerYear: deletable(OrganizationStruct.schema.revenuePerYear),
    industry: deletable(OrganizationStruct.schema.industry),
    clientFocus: deletable(OrganizationStruct.schema.clientFocus),
    website: deletable(OrganizationStruct.schema.website),
    domain: deletable(OrganizationStruct.schema.domain),
    socialMediaFacebook: deletable(
      OrganizationStruct.schema.socialMediaFacebook,
    ),
    socialMediaTwitter: deletable(OrganizationStruct.schema.socialMediaTwitter),
    socialMediaLinkedIn: deletable(
      OrganizationStruct.schema.socialMediaLinkedIn,
    ),
    socialMediaInstagram: deletable(
      OrganizationStruct.schema.socialMediaInstagram,
    ),
    socialMediaYoutube: deletable(OrganizationStruct.schema.socialMediaYoutube),
    hubspotCompanyId: optional(
      nullable(OrganizationStruct.schema.hubspotCompanyId),
    ),
    isHubspotEnabled: optional(OrganizationStruct.schema.isHubspotEnabled),
    [`layout.useLogo`]: optional(boolean()),
    [`layout.primaryColor`]: optional(string()),
  }),
});

export type UpdateOrganizationRequest = Infer<
  typeof UpdateOrganizationRequestStruct
>;
