import { Infer, pick } from "superstruct";
import { UserStruct } from "../types/User";
import { deepReplaceTimestampStructWithStringStruct } from "../utils/deepReplaceTimestampStructWithStringStruct";

export const UserEventUserStruct =
  deepReplaceTimestampStructWithStringStruct(UserStruct);

/**
 * User object for create & update events.
 * @eventProperty
 * @interface
 * @category User
 */
export type UserEventUser = Infer<typeof UserEventUserStruct>;

export const UserDeletedEventUserStruct = pick(UserStruct, [
  "id",
  "hubspotContactId",
  "isHubspotEnabled",
]);

/**
 * User object for delete events.
 * @eventProperty
 * @interface
 * @category User
 */
export type UserDeletedEventUser = Infer<typeof UserDeletedEventUserStruct>;
