import { Infer, object, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteInvitationRequestStruct = object({
  invitationId: string(),
});

export type DeleteInvitationRequest = Infer<
  typeof DeleteInvitationRequestStruct
>;

const DeleteInvitationResponseStruct = type({
  id: string(),
});

export const deleteInvitationDefinition = getFirebaseFunctionDefinition({
  path: "/invitations/delete",
  reqType: DeleteInvitationRequestStruct,
  resType: DeleteInvitationResponseStruct,
});
