import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TENDER_AGENT_TRAINING_PRICE_MODELS = [
  "FLATRATE",
  "PER_HOUR",
] as const;

export const TenderAgentTrainingPriceModelStruct = enums(
  TENDER_AGENT_TRAINING_PRICE_MODELS,
);

export type TenderAgentTrainingPriceModel = Infer<
  typeof TenderAgentTrainingPriceModelStruct
>;

export function isTenderAgentTrainingPriceModel(
  obj: unknown,
): obj is TenderAgentTrainingPriceModel {
  return isStruct(
    obj,
    TenderAgentTrainingPriceModelStruct,
    "TenderAgentTrainingPriceModel",
  );
}
