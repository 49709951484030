import { boolean, Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateNotificationHasBeenReadRequestStruct = type({
  notificationId: string(),
  hasBeenRead: boolean(),
});

export type UpdateNotificationHasBeenReadRequest = Infer<
  typeof UpdateNotificationHasBeenReadRequestStruct
>;

export const updateNotificationHasBeenReadDefinition =
  getFirebaseFunctionDefinition({
    path: "/notification/has-been-read/update",
    reqType: UpdateNotificationHasBeenReadRequestStruct,
  });
