import { Describe, func, number, Struct, type } from "superstruct";
import { StructSchema } from "superstruct/dist/utils";
import { isStruct } from "../utils/isStruct";

/**
 * @interface
 */
export interface Timestamp {
  readonly seconds: number;
  readonly nanoseconds: number;
  isEqual(other: Timestamp): boolean;
  toDate(): Date;
  toMillis: () => number;
}

export class TimestampMock implements Timestamp {
  readonly seconds: number;
  readonly nanoseconds: number;

  constructor(seconds: number, nanoseconds: number);
  constructor(date: Date);
  constructor(arg0: number | Date, arg1?: number) {
    if (typeof arg0 === "number" && typeof arg1 === "number") {
      this.seconds = arg0;
      this.nanoseconds = arg1;
    } else if (arg0 instanceof Date) {
      this.seconds = arg0.getTime() / 1000;
      this.nanoseconds = 0;
    } else {
      throw new Error("Invalid arguments");
    }
  }

  isEqual(other: Timestamp) {
    return (
      other.seconds === this.seconds && other.nanoseconds === this.nanoseconds
    );
  }

  toDate() {
    return new Date(this.toMillis());
  }

  toMillis() {
    return this.seconds * 1000 + this.nanoseconds / 1e6;
  }
}

export const TimestampStruct: Describe<Timestamp> = new Struct<
  Timestamp,
  StructSchema<Timestamp>
>({
  ...type({
    nanoseconds: number(),
    seconds: number(),
    isEqual: func() as unknown as Struct<Timestamp["isEqual"], null>,
    toDate: func() as unknown as Struct<Timestamp["toDate"], null>,
    toMillis: func() as unknown as Struct<Timestamp["toMillis"], null>,
  }),
  coercer: (value) => {
    // HACK: During coercion, superstruct sets all properties on the object,
    // however nanoseconds and seconds are readonly properties in `Timestamp`.
    // This Proxy makes those properties writeable.
    if (isFirebaseTimestamp(value)) {
      return new Proxy(value, {
        set(target, prop, value) {
          if (prop === "nanoseconds" && typeof value === "number") {
            target._nanoseconds = value;
            return true;
          }
          if (prop === "seconds" && typeof value === "number") {
            target._seconds = value;
            return true;
          }
          // eslint-disable-next-line
          (target as any)[prop] = value;
          return true;
        },
      });
    }
    return value;
  },
});

interface FirebaseTimestamp extends Pick<Timestamp, "nanoseconds" | "seconds"> {
  _nanoseconds: number;
  _seconds: number;
}

function isFirebaseTimestamp(value: unknown): value is FirebaseTimestamp {
  return (
    (typeof value === "object" || typeof value === "function") &&
    !!value &&
    "nanoseconds" in value &&
    "seconds" in value &&
    "_nanoseconds" in value &&
    "_seconds" in value
  );
}

export function isTimestamp(obj: unknown): obj is Timestamp {
  return isStruct(obj, TimestampStruct, "Timestamp");
}
