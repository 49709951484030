import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TENDER_AGENT_TYPES = ["REGULAR", "TEAM_LEAD"] as const;

export const TenderAgentTypeStruct = enums(TENDER_AGENT_TYPES);

export type TenderAgentType = Infer<typeof TenderAgentTypeStruct>;

export function isTenderAgentType(obj: unknown): obj is TenderAgentType {
  return isStruct(obj, TenderAgentTypeStruct, "TenderAgentType");
}
