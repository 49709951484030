import { boolean, Infer, optional, string, type, union } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateNotificationHasBeenReadAllWithOrganizationIdRequestStruct = type({
  organizationId: string(),
  isAssigned: boolean(),
  isTask: optional(boolean()),
});

const UpdateNotificationHasBeenReadAllWithUserIdRequestStruct = type({
  userId: string(),
});

const UpdateNotificationHasBeenReadAllRequestStruct = union([
  UpdateNotificationHasBeenReadAllWithOrganizationIdRequestStruct,
  UpdateNotificationHasBeenReadAllWithUserIdRequestStruct,
]);

export type UpdateNotificationHasBeenReadAllRequest = Infer<
  typeof UpdateNotificationHasBeenReadAllRequestStruct
>;

export const updateNotificationHasBeenReadAllDefinition =
  getFirebaseFunctionDefinition({
    path: "/notifications/has-been-read-all/update",
    reqType: UpdateNotificationHasBeenReadAllRequestStruct,
  });
