import { Infer, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { ProcessStatusStruct } from "./ProcessStatus";
import { TimestampStruct } from "./Timestamp";

export const ProcessStruct = type({
  status: ProcessStatusStruct,
  errorMessage: optional(string()),
  startedAt: optional(TimestampStruct),
  endedAt: optional(TimestampStruct),
  requestedAt: optional(TimestampStruct),
});

/**
 * @interface
 * @category Call
 */
export type Process = Infer<typeof ProcessStruct>;

export function isProcess(obj: unknown): obj is Process {
  return isStruct(obj, ProcessStruct, "Process");
}
