import { array, assign, Infer, object, optional, type } from "superstruct";
import {
  ConnectorConfigBaseStruct,
  VccLiveConnectorConfigParamsStruct,
} from "../../types/ConnectorConfig";
import { ExternalProjectStruct } from "../../types/ExternalProject";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const TestConnectorConfigVccLiveRequestStruct = assign(
  object({
    organizationId: ConnectorConfigBaseStruct.schema.organizationId,
    connectorConfigId: optional(ConnectorConfigBaseStruct.schema.id),
  }),
  VccLiveConnectorConfigParamsStruct,
);

const TestConnectorConfigResponseVccLiveStruct = type({
  externalProjects: array(ExternalProjectStruct),
});

export type TestConnectorConfigVccLiveRequest = Infer<
  typeof TestConnectorConfigVccLiveRequestStruct
>;

export type TestConnectorConfigVccLiveResponse = Infer<
  typeof TestConnectorConfigResponseVccLiveStruct
>;

export const testConnectorConfigVccLiveDefinition =
  getFirebaseFunctionDefinition({
    path: "/connector-configs/test-vcc-live",
    reqType: TestConnectorConfigVccLiveRequestStruct,
    resType: TestConnectorConfigResponseVccLiveStruct,
  });
