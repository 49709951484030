import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CALL_FLAGGING_CONDITIONS = ["ALL", "ANY"] as const;

export const CallFlaggingConfigConditionStruct = enums(
  CALL_FLAGGING_CONDITIONS,
);

export type CallFlaggingConfigCondition = Infer<
  typeof CallFlaggingConfigConditionStruct
>;

export function isCallFlaggingConfigCondition(
  obj: unknown,
): obj is CallFlaggingConfigCondition {
  return isStruct(
    obj,
    CallFlaggingConfigConditionStruct,
    "CallFlaggingConfigCondition",
  );
}
