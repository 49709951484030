import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteConnectorConfigRequestStruct = type({
  organizationId: string(),
  connectorConfigId: string(),
});

export type DeleteConnectorConfig = Infer<
  typeof DeleteConnectorConfigRequestStruct
>;

const DeleteConnectorConfigResponseStruct = type({
  organizationId: string(),
  connectorConfigId: string(),
});

export const DeleteConnectorConfigDefinition = getFirebaseFunctionDefinition({
  path: "/connector-configs/delete",
  reqType: DeleteConnectorConfigRequestStruct,
  resType: DeleteConnectorConfigResponseStruct,
});
