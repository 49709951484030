import { Infer, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { MemberRoleStruct } from "./MemberRole";
import { MemberTasksStruct } from "./MemberTask";
import { TimestampStruct } from "./Timestamp";

export const MemberStruct = type({
  id: string(),
  organizationId: optional(string()), // TODO: make organizationId required
  hubspotContactId: optional(string()),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
  firstName: optional(string()),
  lastName: optional(string()),
  email: optional(string()),
  role: MemberRoleStruct,
  tasks: optional(MemberTasksStruct),
});

/**
 * @interface
 * @category Organization
 */
export type Member = Infer<typeof MemberStruct>;

export function isMember(obj: unknown): obj is Member {
  return isStruct(obj, MemberStruct, "Member");
}
