import { Newable } from "i18next";
import { PatternMatcher } from "../../types/PatternMatcher";
import { ErrorContext, logError } from "./logError";
import { toastError } from "./toastError";

type ErrorHandler<T> = (error: T) => void;

/**
 * Used to create a pattern matcher for errors inside `catch` clauses.
 *
 * @example
 * ```ts
 * catch (error) {
 *   handleError(error)
 *     .when(Error, (err) => {
 *       toast.error(err.message);
 *     })
 *     .end();
 * }
 * ```
 */
class ErrorMatcher implements PatternMatcher {
  private readonly matchers = new Map<
    Function | AnyType,
    ErrorHandler<unknown>
  >();

  constructor(private readonly error: unknown) {}

  /**
   * Adds a handler for a given type.
   *
   * @param type Error class to match.
   * @param then Handler function.
   */
  when<T>(type: Newable<T>, then: ErrorHandler<T>): this {
    if (this.matchers.has(type)) {
      throw new Error(`Match for ${type.name} already defined.`);
    }
    this.matchers.set(type, then as ErrorHandler<unknown>);
    return this;
  }

  /**
   * Adds a handler for any error.
   *
   * @param then Handler function.
   */
  any(then: ErrorHandler<unknown>): this {
    if (this.matchers.has(Any)) {
      throw new Error(`Match for Any already defined.`);
    }
    this.matchers.set(Any, then);
    return this;
  }

  /**
   * Handle the error with the first matching handler.
   * If no handler matches, the original error is re-thrown.
   */
  end(): void {
    for (const [type, handler] of this.matchers.entries()) {
      if (type !== Any && this.error instanceof type) {
        handler(this.error);
        return;
      }
    }
    const anyHandler = this.matchers.get(Any);
    if (anyHandler) {
      anyHandler(this.error);
    } else {
      throw this.error;
    }
  }

  /**
   * Shorthand for `.when(Error, toastError).end()`.
   *
   * @see {@link toastError}
   */
  toast(): void {
    this.when(Error, toastError).end();
  }

  /**
   * Shorthand for `.any(logError)`.
   */
  logAnd(context?: ErrorContext): this {
    this.any((error) => logError(error, context));
    return this;
  }

  /**
   * Shorthand for `.any(logError).end()`.
   */
  log(context?: ErrorContext): void {
    this.logAnd(context).end();
  }

  /**
   * Throws the error.
   *
   * @throws The error.
   */
  throw(): never {
    throw this.error;
  }
}

/**
 * Matches any error.
 */
const Any = Symbol("Any");

type AnyType = typeof Any;

/**
 * Create an error pattern matcher for the given error.
 */
export function handleError(error: unknown): ErrorMatcher {
  return new ErrorMatcher(error);
}
