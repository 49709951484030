import { boolean, Infer, optional, type } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const MemberTasksStruct = type({
  isTeamMemberInvited: optional(boolean()),
  isTenderCreated: optional(boolean()),
  isProviderProfileComplete: optional(boolean()),
  isPhoneSystemConnected: optional(boolean()),
});

export type MemberTasks = Infer<typeof MemberTasksStruct>;

export function isMemberTasks(obj: unknown): obj is MemberTasks {
  return isStruct(obj, MemberTasksStruct, "MemberTasks");
}
