import { boolean, Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateCallIsSummaryHelpfulRequestStruct = type({
  callId: string(),
  isSummaryHelpful: boolean(),
});

export type UpdateCallIsSummaryHelpfulRequest = Infer<
  typeof UpdateCallIsSummaryHelpfulRequestStruct
>;

export const updateCallIsSummaryHelpfulDefinition =
  getFirebaseFunctionDefinition({
    path: "/call/is-summary-helpful/update",
    reqType: UpdateCallIsSummaryHelpfulRequestStruct,
  });
