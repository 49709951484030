import { PERMISSION_ROLES } from "../consts/PERMISSION_ROLES";
import { AuthRole } from "../types/AuthRole";
import { MemberRole } from "../types/MemberRole";
import { Permission } from "../types/Permission";
import { Role } from "../types/Role";
import { UserRole } from "../types/UserRole";

export type MemberRoles = Record<string, MemberRole>;

/**
 * Attributes of the authenticated user.
 */
export interface PermissionAuthData {
  userId?: string;
  userRole?: UserRole;
  memberRoles?: MemberRoles;
}

/**
 * Attributes of the data that will be accessed.
 */
export interface PermissionDocData {
  userId?: string;
  organizationId?: string;
  organizationIds?: (string | undefined)[];
}

interface Args {
  permission: Permission;
  authData: PermissionAuthData;
  docData: PermissionDocData;
}

/**
 * It returns `true`, if one of the roles matches.
 */
export const hasPermission = (args: Args): boolean => {
  const { permission, authData, docData } = args;

  const roles = new Set<Role>([
    ...getAuthUserRoles({ authData, docData }),
    ...getUserRoles({ authData, docData }),
    ...getMemberRoles({ authData, docData }),
  ]);

  return Boolean(PERMISSION_ROLES[permission]?.some((role) => roles.has(role)));
};

interface GetRolesArgs {
  authData: PermissionAuthData;
  docData: PermissionDocData;
}

function getAuthUserRoles(args: GetRolesArgs): AuthRole[] {
  const { docData, authData } = args;
  if (
    docData?.userId &&
    authData?.userId &&
    authData.userId === docData.userId
  ) {
    return ["AUTH_USER"];
  }
  return [];
}

function getUserRoles(args: GetRolesArgs): UserRole[] {
  const { authData } = args;
  return authData.userRole ? [authData.userRole] : [];
}

function getMemberRoles(args: GetRolesArgs): Set<MemberRole> {
  const {
    docData,
    authData: { memberRoles = {} },
  } = args;

  const organizationIds = [
    docData.organizationId,
    ...(docData.organizationIds || []),
  ].filter((value): value is NonNullable<typeof value> => !!value);

  return new Set(
    organizationIds.flatMap((organizationId) => {
      return memberRoles[organizationId] || [];
    }),
  );
}
