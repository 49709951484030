import { Infer, pick } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { ProjectReportFrameStruct } from "./ProjectReportFrame";

export const ProjectReportFrameByDayStruct = pick(ProjectReportFrameStruct, [
  "id",
  "totalCallsCount",
  "totalCallsIncomingCount",
  "totalCallsOutgoingCount",
  "answeredCallsCount",
  "answeredCallsIncomingCount",
  "answeredCallsOutgoingCount",
  "reachabilityPercentage",
  "reachabilityIncomingPercentage",
  "reachabilityOutgoingPercentage",
  "averageHandlingTimeInSeconds",
  "averageHandlingTimeIncomingSeconds",
  "averageHandlingTimeOutgoingSeconds",
  "totalHandlingTimeInSeconds",
  "totalHandlingTimeIncomingSeconds",
  "totalHandlingTimeOutgoingSeconds",
  "serviceLevelPercentage",
]);

/**
 * @interface
 * @category Project
 */
export type ProjectReportFrameByDay = Infer<
  typeof ProjectReportFrameByDayStruct
>;

export function isProjectReportFrameByDay(
  obj: unknown,
): obj is ProjectReportFrameByDay {
  return isStruct(
    obj,
    ProjectReportFrameByDayStruct,
    "ProjectReportFrameByDay",
  );
}
