import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const ObservabilityEventTypeStruct = enums([
  "CALL_TRANSCRIPT_IS_HELPFUL_TRUE",
  "CALL_TRANSCRIPT_IS_HELPFUL_FALSE",
  "CALL_SUMMARY_IS_HELPFUL_TRUE",
  "CALL_SUMMARY_IS_HELPFUL_FALSE",
  "CALL_QUESTION_ANSWER_IS_HELPFUL_TRUE",
  "CALL_QUESTION_ANSWER_IS_HELPFUL_FALSE",
  "CALL_SCORECARD_SUBMITTED",
  "CALL_REVIEW_COMPLETED",
]);

export type ObservabilityEventType = Infer<typeof ObservabilityEventTypeStruct>;

export function isObservabilityEventType(
  obj: unknown,
): obj is ObservabilityEventType {
  return isStruct(obj, ObservabilityEventTypeStruct, "ObservabilityEventType");
}
