import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

/**
 * Currency - ISO 4217
 * @ignore
 */
export const CURRENCIES = ["CHF", "EUR", "GBP", "USD"] as const;

export const CurrencyStruct = enums(CURRENCIES);

export type Currency = Infer<typeof CurrencyStruct>;

export function isCurrency(obj: unknown): obj is Currency {
  return isStruct(obj, CurrencyStruct, "Currency");
}
