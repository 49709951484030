import { Infer, nullable, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { EmailStruct } from "./Email";
import { MemberRoleStruct } from "./MemberRole";
import { TimestampStruct } from "./Timestamp";
import { UserTypeStruct } from "./UserType";

const InvitationStruct = type({
  id: string(),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
  acceptedAt: nullable(TimestampStruct),
  email: EmailStruct,
  organizationId: string(),
  organizationName: string(),
  invitedByUserId: optional(string()),
  invitedByUserName: string(),
  userType: optional(UserTypeStruct),
  memberRole: optional(MemberRoleStruct),
});

/**
 * @interface
 * @category Invitation
 */
export type Invitation = Infer<typeof InvitationStruct>;

export function isInvitation(obj: unknown): obj is Invitation {
  return isStruct(obj, InvitationStruct, "Invitation");
}
