import { array, Infer, number, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { TimestampStruct } from "./Timestamp";

export const CallScorecardStruct = type({
  id: string(),
  projectId: string(),
  callId: string(),
  reviewerName: string(),
  reviewerUserId: string(),
  totalScore: number(),
  maxScore: number(),
  items: array(
    type({
      question: string(),
      score: number(),
      maxScore: number(),
      remark: optional(string()),
    }),
  ),
  createdAt: TimestampStruct,
});

/**
 * @interface
 * @category Call
 */
export type CallScorecard = Infer<typeof CallScorecardStruct>;

export function isCallScorecard(obj: unknown): obj is CallScorecard {
  return isStruct(obj, CallScorecardStruct, "CallScorecard");
}
