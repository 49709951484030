import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const TENDER_APPLICATION_STATUSES = [
  "PENDING",
  "DRAFT",
  "PUBLISHED",
  "ACCEPTED",
  "REJECTED",
  "REJECTED_FOR_RESUBMISSION",
  "REJECTED_WITHOUT_APPLICATION",
] as const;

export const TenderApplicationStatusStruct = enums(TENDER_APPLICATION_STATUSES);

export type TenderApplicationStatus = Infer<
  typeof TenderApplicationStatusStruct
>;

export function isTenderApplicationStatus(
  obj: unknown,
): obj is TenderApplicationStatus {
  return isStruct(
    obj,
    TenderApplicationStatusStruct,
    "TenderApplicationStatus",
  );
}
