import { Infer, nullable, object, string } from "superstruct";
import { ProjectStruct } from "../../types/Project";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const UpdateProjectScorecardQuestionsRequestStruct = object({
  projectId: string(),
  scorecardQuestions: nullable(ProjectStruct.schema.scorecardQuestions),
});

export type UpdateProjectScorecardQuestionsRequest = Infer<
  typeof UpdateProjectScorecardQuestionsRequestStruct
>;

export const updateProjectScorecardQuestionsDefinition =
  getFirebaseFunctionDefinition({
    path: "/projects/scorecard-questions/update",
    reqType: UpdateProjectScorecardQuestionsRequestStruct,
  });
