import { coerce, pattern, string } from "superstruct";
import { TimestampStruct } from "../types/Timestamp";

export const ISO_8601_PATTERN =
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

export const TimestampToStringStruct = coerce(
  pattern(string(), ISO_8601_PATTERN),
  TimestampStruct,
  (timestamp) => timestamp.toDate().toISOString(),
);
