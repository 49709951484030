import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const CallEventTypeStruct = enums([
  "CALL_UPDATED",
  "CALL_PROCESS_REQUESTED",
]);

export type CallEventType = Infer<typeof CallEventTypeStruct>;

export function isCallEventType(obj: unknown): obj is CallEventType {
  return isStruct(obj, CallEventTypeStruct, "CallEventType");
}
