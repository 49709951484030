import { enums, Infer, Struct } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const ClientFocusValues = ["B2B", "B2C"] as const;

export const OrganizationClientFocusStruct = enums(ClientFocusValues);

export type OrganizationClientFocusStruct = Struct<ClientFocus>;

/**
 * @interface
 * @category Organization
 */
export type ClientFocus = Infer<typeof OrganizationClientFocusStruct>;

export function isClientFocus(obj: unknown): obj is ClientFocus {
  return isStruct(obj, OrganizationClientFocusStruct, "ClientFocus");
}
