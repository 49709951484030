import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const ORGANIZATION_TYPES = ["CLIENT", "PROVIDER"] as const;

export const OrganizationTypeStruct = enums(ORGANIZATION_TYPES);

/**
 * @interface
 * @category Organization
 */
export type OrganizationType = Infer<typeof OrganizationTypeStruct>;

export function isOrganizationType(obj: unknown): obj is OrganizationType {
  return isStruct(obj, OrganizationTypeStruct, "OrganizationType");
}
