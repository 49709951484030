import { enums, Infer } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const PROVIDER_CLIENT_TYPES = [
  "SMALL_BUSINESS",
  "MEDIUM_BUSINESS",
  "LARGE_BUSINESS",
] as const;

export const ProviderClientTypeStruct = enums(PROVIDER_CLIENT_TYPES);

export type ProviderClientType = Infer<typeof ProviderClientTypeStruct>;

export function isProviderClientType(obj: unknown): obj is ProviderClientType {
  return isStruct(obj, ProviderClientTypeStruct, "ProviderClientType");
}
