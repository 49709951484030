import { boolean, Infer, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { StorageFileStruct } from "./StorageFile";

export const OrganizationLayoutStruct = type({
  logo: optional(StorageFileStruct),
  useLogo: optional(boolean()),
  primaryColor: optional(string()),
});

/**
 * @interface
 * @category OrganizationLayout
 */
export type OrganizationLayout = Infer<typeof OrganizationLayoutStruct>;

export function isOrganizationLayout(obj: unknown): obj is OrganizationLayout {
  return isStruct(obj, OrganizationLayoutStruct, "OrganizationLayout");
}
