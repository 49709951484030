import { Infer, object, string } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const ArchiveProjectRequestStruct = object({
  projectId: string(),
});

export type ArchiveProjectRequest = Infer<typeof ArchiveProjectRequestStruct>;

export const archiveProjectDefinition = getFirebaseFunctionDefinition({
  path: "/projects/archive",
  reqType: ArchiveProjectRequestStruct,
});
