import { array, assign, Infer, object, optional, type } from "superstruct";
import {
  ConnectorConfigBaseStruct,
  DialfireConnectorConfigParamsStruct,
} from "../../types/ConnectorConfig";
import { ExternalProjectStruct } from "../../types/ExternalProject";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const TestConnectorConfigDialfireRequestStruct = assign(
  object({
    organizationId: ConnectorConfigBaseStruct.schema.organizationId,
    connectorConfigId: optional(ConnectorConfigBaseStruct.schema.id),
  }),
  DialfireConnectorConfigParamsStruct,
);

const TestConnectorConfigDialfireResponseStruct = type({
  externalProjects: array(ExternalProjectStruct), // TODO: Ask dialfire for the correct response
});

export type TestConnectorConfigDialfireRequest = Infer<
  typeof TestConnectorConfigDialfireRequestStruct
>;

export type TestConnectorConfigDialfireResponse = Infer<
  typeof TestConnectorConfigDialfireResponseStruct
>;

export const testConnectorConfigDialfireDefinition =
  getFirebaseFunctionDefinition({
    path: "/connector-configs/test-Dialfire",
    reqType: TestConnectorConfigDialfireRequestStruct,
    resType: TestConnectorConfigDialfireResponseStruct,
  });
