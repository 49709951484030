import { Infer, number, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";

export const ProviderOperationCenterStruct = type({
  location: optional(
    type({
      city: optional(string()),
      country: optional(string()),
    }),
  ),
  employees: optional(number()),
  startingYear: optional(number()),
});

export type ProviderOperationCenter = Infer<
  typeof ProviderOperationCenterStruct
>;

export function isProviderOperationCenter(
  obj: unknown,
): obj is ProviderOperationCenter {
  return isStruct(
    obj,
    ProviderOperationCenterStruct,
    "ProviderOperationCenter",
  );
}
