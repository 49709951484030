import { Infer, string, type } from "superstruct";
import { getFirebaseFunctionDefinition } from "../../utils/getFirebaseFunctionDefinition";

const DeleteCallScorecardRequestStruct = type({
  callId: string(),
  scorecardId: string(),
});

export type DeleteCallScorecardRequest = Infer<
  typeof DeleteCallScorecardRequestStruct
>;

export const deleteCallScorecardDefinition = getFirebaseFunctionDefinition({
  path: "/calls/scorecard/delete",
  reqType: DeleteCallScorecardRequestStruct,
});
