import { Infer, number, optional, string, type } from "superstruct";
import { isStruct } from "../utils/isStruct";
import { TimestampStruct } from "./Timestamp";

export const ProjectReportFrameStruct = type({
  id: string(),
  date: TimestampStruct,
  projectId: string(),
  /**
   * Absolute number of all calls.
   */
  totalCallsCount: number(),
  /**
   * Number of all inbound calls.
   */
  totalCallsIncomingCount: optional(number()),
  /**
   * Number of all outbound calls.
   */
  totalCallsOutgoingCount: optional(number()),
  /**
   * Absolute number of calls with a duration > 0.
   */
  answeredCallsCount: number(),
  /**
   * Number of inbound calls with a duration > 0.
   */
  answeredCallsIncomingCount: optional(number()),
  /**
   * Number of outbound calls with a duration > 0.
   */
  answeredCallsOutgoingCount: optional(number()),
  /**
   * Absolute number of inbound calls answered within the service level threshold.
   */
  totalCallsInServiceLevelThresholdCount: optional(number()),
  /**
   * Absolute handling time of all calls.
   */
  totalHandlingTimeInSeconds: number(),
  /**
   * Total handling time of inbound calls.
   */
  totalHandlingTimeIncomingSeconds: optional(number()),
  /**
   * Total handling time of outbound calls.
   */
  totalHandlingTimeOutgoingSeconds: optional(number()),
  /**
   * Average handling time of all calls.
   */
  averageHandlingTimeInSeconds: number(),
  /**
   * Average handling time of inbound calls.
   */
  averageHandlingTimeIncomingSeconds: optional(number()),
  /**
   * Average handling time of outbound calls.
   */
  averageHandlingTimeOutgoingSeconds: optional(number()),
  /**
   * Maximum time a caller should wait before being answered.
   */
  serviceLevelThresholdInSeconds: number(),
  /**
   * % of inbound calls answered within the service level threshold.
   */
  serviceLevelPercentage: number(),
  /**
   * Absolute % of calls with a duration > 0.
   */
  reachabilityPercentage: number(),
  /**
   * % of answered inbound calls.
   */
  reachabilityIncomingPercentage: optional(number()),
  /**
   * % of answered outbound calls.
   */
  reachabilityOutgoingPercentage: optional(number()),
  /**
   * Optional id to control wether the frame should be replaced or not by a worker task.
   */
  dispatchId: optional(string()),
  createdAt: TimestampStruct,
  updatedAt: TimestampStruct,
});

/**
 * @interface
 * @category Project
 */
export type ProjectReportFrame = Infer<typeof ProjectReportFrameStruct>;

export function isProjectReportFrame(obj: unknown): obj is ProjectReportFrame {
  return isStruct(obj, ProjectReportFrameStruct, "ProjectReportFrame");
}
